import './mainMachineryInterventions.css'
import PersMachineryInterventions from './subElements/persMachineryInterventions'
import { useSelector } from 'react-redux'
import { getReportForMachineSelected } from '../../structures/root/slices/reportSlice'
import { getInterventionForMachinerySelected } from '../../structures/root/slices/interventionSlice'
import { getTicketForMachinerySelected } from '../../structures/root/slices/ticketSlice'
import { selectFaultFamilyForSelectedMachienry } from '../../structures/root/slices/faultSlice'
import { getDataFromSelectedMachinery } from '../../structures/root/slices/listMachinerySlice'

const MachineryInterventions = () => {
    const listReport = useSelector(getReportForMachineSelected)
    const listIntervention = useSelector(getInterventionForMachinerySelected)
    const listTicket = useSelector(getTicketForMachinerySelected)
    const dataFault = useSelector(selectFaultFamilyForSelectedMachienry)
    const assetSelezionato = useSelector(getDataFromSelectedMachinery)
    return (
        <PersMachineryInterventions
            listIntervention={listIntervention ? listIntervention : []}
            listReport={listReport}
            listTicket={listTicket}
            dataFault={dataFault}
            assetSelected={assetSelezionato}
        />
    )
}

export default MachineryInterventions