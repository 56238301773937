import { Outlet, useNavigate, useParams } from "react-router-dom";
import "./mainMacchineDetail.css";
import SynopticMachinery from "../../components/synopticMachinery/mainSynopticMachinery";
import LoadDonutChart from "../../components/multipleVariableDonut/mainMultiDonut";
import LinearGraph from "../../components/lineargraph/mainLinearGraph";
/*
import MachineFaultList from '../../components/machineFaultList/mainMachineFaultList';
import MachineryInteventions from '../../components/machineryInterventions/mainMachineryInterventions'
import MachineDocument from '../../components/machineDocument/mainMachineDocument'
*/
import { Link } from "react-router-dom";
import {
    pathExplorPlant,
    pathMachineryIntervention,
} from "../../configurations/setting";

import { titleColor } from "../../configurations/generalStye";
import SchedaMachinery from "../../components/schedeMachinery/mainSchedeMachinery";
import { getDatiTargaForMachineSelected } from "../root/slices/datiTargaMachinerySlice";
import { useSelector } from "react-redux";
import { getDataFromSelectedMachinery } from "../root/slices/listMachinerySlice";
import { getDatiFaultForMachineSelected } from "../root/slices/dataFaultMachinerySlice";
import { selectFaultFamilyForSelectedMachienry } from "../root/slices/faultSlice";
import {
    getDataSpecificMachineInterventio,
    getInterventionForMachinerySelected,
} from "../root/slices/interventionSlice";
import { getNamePlantForSelectedMachinery } from "../root/slices/plantSlice";
import React, { useEffect, useState, useRef } from "react";
import MachineDocument from "../../components/machineDocument/mainMachineDocument";
import { getDatiTargaAsset } from "../root/slices/datiTargaMachinerySlice";

import { useDispatch } from "react-redux";
import { accise, distanza_cumulativa, p_battery } from "../../configurations/strutturaDati/datiMacchine";






const MachineDetail = () => {
    const { id } = useParams();
    const navigator = useNavigate();
    const datiTarga = useSelector(getDatiTargaForMachineSelected);
    const nomePlant = useSelector(getNamePlantForSelectedMachinery);
    const infoMachine = useSelector(getDataFromSelectedMachinery);
    const dispatch = useDispatch();
    const [resolution, setResolution] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const resizeObserverRef = useRef(null);
    const handleResize = (entries) => {
        const { width, height } = entries[0].contentRect;

        setResolution({ width, height });
    };
    /**
     * bisogna portare anche i dati per:
     * fault data (dati calcolati dagli algoritmi che devono essere comparati all'elenco generico dei  fault)
     * fault for family
     *
     */
    const faultData = useSelector(getDatiFaultForMachineSelected);
    const faultForFamily = useSelector(selectFaultFamilyForSelectedMachienry);
    /**
     * elenco manutenzioni, ticket ed interventi schedulati ancora da terminare
     * doc dati targa
     * dati grafici e sensoristica
     */

    const intervention = useSelector(getInterventionForMachinerySelected);
    /**
     * da fare la struttura dati per ticket ed elenco manutenzioni
     */

    useEffect(() => {
        if (infoMachine) {
            dispatch(getDatiTargaAsset({ idAsset: infoMachine.id }));
            dispatch(getDataSpecificMachineInterventio({ idAsset: infoMachine.id }));
            if (infoMachine.hi) {
            }
        } else {
            navigator(`${pathExplorPlant}/${id}/${pathMachineryIntervention}`);
        }
    }, [infoMachine]);

    useEffect(() => {
        resizeObserverRef.current = new ResizeObserver(handleResize);
        // Aggiungo l'elemento da osservare (in questo caso, l'intera finestra)
        resizeObserverRef.current.observe(document.documentElement);
    }, []);

    const isMachineryPredictiveRigthSynoptic = (infoMachine) => {
        if (infoMachine) {
            if (infoMachine.hi || infoMachine.familyId === "rmh0p") {
                return (
                    <>
                        <div className="boxDoubleDonutDettaglioMacchinario">
                            <div className="singleMultipleDonut">
                                <h4 className="elementTitleSingleDonut"   style={{fontSize:"1vw"}} >Ore di lavoro</h4>
                                <LoadDonutChart

                                    val1={infoMachine.familyId === "rmh0p" ? 600 : infoMachine.hi}
                                    max1={5000}
                                    colorCircle1={['#99CBFF', '#FF00FF', '#EE82EE']}

                                    fontSizeValue={"1vw"}
                                />
                            </div>


                            <div className="singleMultipleDonut">
                                <h4 className="elementTitleSingleDonut"
                                    style={{fontSize:"1vw"}}
                                >Cicli di carica</h4>
                                <LoadDonutChart

                                    val1={infoMachine.familyId === "rmh0p" ? 10 : infoMachine.hi}
                                    max1={1000}
                                    colorCircle1={['#99CBFF', '#FF00FF', '#EE82EE']}

                                    fontSizeValue={"1vw"}
                                />
                            </div>
                        </div>
                        <div className="boxDoubleGraphDettaglioMacchinario">
                            {/*<div className="titleLinearGraph2">
                                <div>
                                    <h3
                                        style={{
                                            color: `${titleColor}`,
                                            //   margin: "0",
                                            //   marginLeft: "20px",
                                            //   marginTop: "10px",
                                        }}
                                    >
                                        {"Consumi (kWh)"}
                                    </h3>
                                </div>
                            </div>*/}
                            {/*<LinearGraph
                                percent={true}
                                title="Produttività"
                                width={resolution.width / 3.35}
                                height={resolution.height / 9.5}
                                data={[
                                    { x: 1, y: 1 },
                                    { x: 2, y: 2 },
                                ]}
                            />-*/}
                            <LinearGraph

                                fontFamily={"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu','Cantarell','Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif"}

                                height={"50%"}

                                title={{ "active": true, "text": "Carica batteria", "color": '#495672', fontSize: "1vw" }}

                                legend={false}

                                percent={false}
                                fontSize={"0.1w"}
                                asseX={accise}
                                series={[
                                    {
                                        data: p_battery,
                                        color: '#4287f5',
                                        name: 'Carica batteria'
                                    }
                                ]}
                            />

                            {/*<div className="titleLinearGraph2">
                                <div>
                                    <h3
                                        style={{
                                            color: `${titleColor}`,
                                            //   margin: "0",
                                            //   marginLeft: "20px",
                                            //   marginTop: "10px",
                                        }}
                                    >
                                        {"Produttività"}
                                    </h3>
                                </div>
                            </div>*/}
                            {/*<LinearGraph
                                title="Consumi (kWh)"
                                width={resolution.width / 3.35}
                                height={resolution.height / 9.5}
                                data={[
                                    { x: 1, y: 1 },
                                    { x: 2, y: 2 },
                                ]}
                            />*/}
                            <LinearGraph

                                fontFamily={"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu','Cantarell','Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif"}


                                height={"50%"}

                                title={{ "active": true, "text": "Distanza cumulativa", "color": '#495672', fontSize: "1vw" }}

                                legend={false}

                                percent={false}
                                fontSize={"0.1w"}
                                asseX={accise}
                                series={[
                                    {
                                        data: distanza_cumulativa,
                                        color: '#4287f5',
                                        name: 'Distanza cumulativa'
                                    }
                                ]}
                            />
                            {/*<div className="boxMacroDatiTrittico">
                        <div>
                            <h5>Ore di lavoro</h5>
                            <h3>{datiMacchinario.oreLavoro}</h3>
                        </div>
                        <div>
                            <h5>Pezzi prodotti</h5>
                            <h3>{datiMacchinario.pezziProdotti}</h3>
                        </div>
                        <div>
                            <h5>Efficienza</h5>
                            <h3>{datiMacchinario.efficenza}</h3>
                        </div>

                    </div>*/}
                        </div>
                    </>
                );
            } else {
                return <MachineDocument />;
            }
        } else {
            return <MachineDocument />;
        }
    };

    const isMachineryPredictiveSchede = (infoMachine) => {
        if (infoMachine) {
            if (infoMachine.hi || infoMachine.familyId === "rmh0p") {
                return (
                    <div style={{ flex: "1", width: "100%" }}>
                        <SchedaMachinery id={id} />
                        <Outlet />
                    </div>
                );
            } else {
                return (
                    <div style={{ width: "100%", marginTop: "1vw" }}>
                        <Link
                            to={`${pathExplorPlant}/${id}/${pathMachineryIntervention}`}
                            className={`elementSchedaMachinery elementActiveSchedaMachinery`}
                        >
                            Elenco manutenzioni
                        </Link>
                        <Outlet />
                    </div>
                );
            }
        } else {
            return (
                <div style={{ width: "100%" }}>
                    <Link
                        to={`${pathExplorPlant}/${id}/${pathMachineryIntervention}`}
                        className={`elementSchedaMachinery elementActiveSchedaMachinery`}
                    >
                        Elenco manutenzioni
                    </Link>
                    <Outlet />
                </div>
            );
        }
    };

    return (
        <div className="bodyDesktopDettaglioMacchinario">
            <div className="rowDettagliMacchinario">
                <h5 className="elementLayerMachine">{`${nomePlant ? nomePlant : ""} > ${infoMachine ? infoMachine.reparto : ""
                    } > ${infoMachine ? infoMachine.identityCode : ""}`}</h5>
            </div>

            <div className="rowDettagliMacchinario">
                <SynopticMachinery
                    // da studiare come gestire il salvataggio in locale dell'immagine e successivamente il suo reperimento senza doverla riscaricare ogni volta
                    linkImage={datiTarga ? datiTarga.imageURL : ""}
                    linkFile={""}
                />
                {isMachineryPredictiveRigthSynoptic(infoMachine)}

                {/** elemento schede macchinario alla quale passare la path /exploreplant/:id/ */}
                {/**
                 * fine condizione nella quale se un dettaglio machinario è per un macchinario
                 * senza preditiva allora deve comparire i dati di targa
                 */}
            </div>
            <div className="rowDettagliMacchinario">
                {isMachineryPredictiveSchede(infoMachine)}
            </div>
        </div>
    );
};

export default MachineDetail;
