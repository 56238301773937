import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { getMachineSelected } from './schedeMachineDetailSlice';
import {newDataTargaAssets, updateOneDataTargaAssets} from './datiTargaMachinerySlice';
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import {createAssets, deleteAssets, updateAssets} from '../../../graphql/mutations'
import { listAssets } from '../../../graphql/queries'
import { Amplify } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import * as subscriptions from '../../../graphql/subscriptions'

Amplify.configure(awsconfig);


const listMachineryAdapter = createEntityAdapter({
    selectId: (listMachinery) => listMachinery.id
})


// SUBSCRIPTION

export const subscribeCreateAsset = createAsyncThunk(
    'listMachinery/subscriptionCreateAsset',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onCreateAssets,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({ provider, value }) => {

                    dispatch(addOneMachinery({
                        id: value.data.onCreateAssets.id,
                        nome: value.data.onCreateAssets.name,
                        identityCode: value.data.onCreateAssets.idCode,
                        familyId: value.data.onCreateAssets.idFamily,
                        //reparto: value.data.onCreateAssets.reparto,
                        idPlant: value.data.onCreateAssets.idPlant,
                        idLinea: value.data.onCreateAssets.idLinea,
                        idDepartment: value.data.onCreateAssets.idDepartment,
                        createdAt: value.data.onCreateAssets.createdAt,
                        updatedAt: value.data.onCreateAssets.updatedAt
                    }))
                },
                error: (error) => console.warn(error)
            })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        // return subscription;
    }
);


export const subscribeModifyAsset = createAsyncThunk(
    'listMachinery/subscriptionModifyAsset',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onUpdateAssets,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({ provider, value }) => {

                    //VECCHIO UPDATE ONE MACHINERY
                    dispatch(
                        updateOneMachinery({
                            id:value.data.onUpdateAssets.id,
                            nome: value.data.onUpdateAssets.name,
                            identityCode: value.data.onUpdateAssets.idCode,
                            familyId: value.data.onUpdateAssets.idFamily,
                            idPlant: value.data.onUpdateAssets.idPlant,
                            idLinea: value.data.onUpdateAssets.idLinea,
                            idDepartment: value.data.onUpdateAssets.idDepartment,
                            createdAt: value.data.onUpdateAssets.createdAt,
                            updatedAt: value.data.onUpdateAssets.updatedAt
                        }))
                },
                error: (error) => console.warn(error)
            })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        // return subscription;
    }
);


export const subscribeDeleteAsset = createAsyncThunk(
    'listMachinery/subscriptionDeleteAsset',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onDeleteAssets,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({ provider, value }) => {

                    dispatch(deleteOneMachinery(value.data.onDeleteAssets.id))
                },
                error: (error) => console.warn(error)
            })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        // return subscription;
    }
);




// CHIAMATE ASINCRONE
export const newAsset = createAsyncThunk('listMachinery/putAsset', async ({ idCode, idFamily, idPlant,idLinea,idDepartment, name,  }) => {
        const response = await API.graphql({
            query: createAssets,
            variables: {
                input: {
                    idCode,
                    idFamily,
                    name,
                    idPlant,
                    idLinea,
                    idDepartment
                }
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        })

        //return response

        return{
            id: response.data.createAssets.id,
            nome: response.data.createAssets.name,
            identityCode: response.data.createAssets.idCode,
            familyId: response.data.createAssets.idFamily,
            idPlant: response.data.createAssets.idPlant,
            idLinea: response.data.createAssets.idLinea,
            idDepartment: response.data.createAssets.idDepartment,
            createdAt: response.data.createAssets.createdAt,
            updatedAt: response.data.createAssets.updatedAt
        }



    }
)
export const modifyAssets = createAsyncThunk('listMachinery/updateAsset', async ({ id,idCode, idFamily, idPlant,idLinea,idDepartment, name,  }) => {
        const response = await API.graphql({
            query: updateAssets,
            variables: {
                input: {
                    id,
                    idCode,
                    idFamily,
                    name,
                    idPlant,
                    idLinea,
                    idDepartment
                }
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        })

        //return response
        return{
            id: response.data.updateAssets.id,
            nome: response.data.updateAssets.name,
            identityCode: response.data.updateAssets.idCode,
            familyId: response.data.updateAssets.idFamily,
            idPlant: response.data.updateAssets.idPlant,
            idLinea: response.data.updateAssets.idLinea,
            idDepartment: response.data.updateAssets.idDepartment,
            createdAt: response.data.updateAssets.createdAt,
            updatedAt: response.data.updateAssets.updatedAt
        }



    }
)


export const getUpdateAssets = createAsyncThunk('listMachinery/getUpdateAssets', async (a, { getState }) => {
    /*
    const dataAttuale = new Date().toISOString()
    const dateLastElementString = Object.values(getState().listMachinery.entities).sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))[0].updatedAt

    const dateLastElement = new Date(Date.parse(dateLastElementString) + 1000).toISOString()
*/
    const response = await API.graphql({
            query: listAssets,
            /**
             * problemi nel download degli asset
             */
            variables: {

            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        }
    )

    return response.data.listAssets.items.map(el => {
        return {
            id: el.id,
            nome: el.name,
            identityCode: el.idCode,
            familyId: el.idFamily,
            reparto: el.reparto,
            idPlant: el.idPlant,
            idLinea: el.idLinea,
            idDepartment: el.idDepartment,
            createdAt: el.createdAt,
            updatedAt: el.updatedAt
        }
    })

})


export const deleteAsset = createAsyncThunk("plant/deleteAsset", async ({id}) => {

    const response = await API.graphql({
        query: deleteAssets,
        variables: {
            input: {id: id}
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })
    return response.data.deleteAssets.id
})









const listMachinerySlice = createSlice({
    name: "listMachinery",
    initialState: listMachineryAdapter.getInitialState(),
    reducers: {
        addMachinery: listMachineryAdapter.upsertMany,
        addOneMachinery: listMachineryAdapter.upsertOne,
        updateOneMachinery: listMachineryAdapter.upsertOne,
        deleteOneMachinery: listMachineryAdapter.removeOne
    },
    extraReducers: bulder => {
        bulder
            .addCase(newAsset.fulfilled, listMachineryAdapter.upsertOne)
            .addCase(newAsset.rejected, (state, action) => console.log(state, action))
            .addCase(modifyAssets.fulfilled, listMachineryAdapter.upsertOne)
            .addCase(modifyAssets.rejected, (state, action) => console.log(state, action))
            .addCase(deleteAsset.fulfilled, listMachineryAdapter.removeOne)
            .addCase(deleteAsset.rejected, (state, action) => console.log(state, action))
            .addCase(getUpdateAssets.fulfilled, listMachineryAdapter.upsertMany)
            .addCase(getUpdateAssets.rejected, (state, action) => console.log(state, action))
    }
})

export default listMachinerySlice.reducer

export const {
    addMachinery,
    addOneMachinery,
    updateOneMachinery,
    deleteOneMachinery
} = listMachinerySlice.actions

export const { selectAll: selectAllMachinery } = listMachineryAdapter.getSelectors(state => state.listMachinery)


export const getDataFromSelectedMachinery = createSelector(
    [selectAllMachinery, getMachineSelected],
    (state, select) => {
        if (select) {

            return state.filter(el => el.id === select.id)[0]
        }
    })


