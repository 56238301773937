import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit'


import { GIORNIMILLISECONDI, pathPlanninng } from '../../../configurations/setting'
import { pathExplorPlant } from '../../../configurations/setting'
import { StatusFilters } from './filterNotificationSlice'
import { getMachineSelected } from './schedeMachineDetailSlice'



import { getDataInterventions, listDataInterventions, listInterventions } from '../../../graphql/queries'
import {
    createDataInterventions,
    createInterventions,
    updateDataInterventions,
    updateInterventions
} from '../../../graphql/mutations'

import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import * as subscriptions from '../../../graphql/subscriptions'
import { updateOneDepartment } from "./listDepartmentSlice";
import { onUpdateDataInterventions } from "../../../graphql/subscriptions";
import { selectAllMachinery } from './listMachinerySlice'


const interventionAdapter = createEntityAdapter({
    selectId: (interventions) => interventions.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id)
})


//SUBSCRIPTION

//creazione nuovo elemento
export const subscribeCreateIntervention = createAsyncThunk(
    'intervention/subscriptionCreateIntervention',
    async (_, { dispatch }) => {
        const subscription = await API.graphql({
            query: subscriptions.onCreateInterventions,
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        }).subscribe({
            next: ({ provider, value }) => {

                dispatch(interventionAddOne({
                    idMachinery: value.data.onCreateInterventions.idAsset,
                    idPrevIntervention: value.data.onCreateInterventions.idPrevIntervention,
                    date: value.data.onCreateInterventions.data,
                    title: value.data.onCreateInterventions.idFault,
                    period: value.data.onCreateInterventions.period,
                    id: value.data.onCreateInterventions.id,
                    link: "/planning",
                    active: true,
                    ora: value.data.onCreateInterventions.ora,
                    createdAt: value.data.onCreateInterventions.createdAt,
                    updatedAt: value.data.onCreateInterventions.updatedAt,
                    backgroundColor: "orange",
                    borderColor: "orange",
                    isDeleted: value.data.onCreateInterventions.isDeleted

                }))

            },
            error: (error) => {
                console.error('Subscription error:', error);
            },
        })



       // return subscription;
    }
);

//modifica elemento
export const subscribeModifyIntervention = createAsyncThunk(
    'intervention/subscriptionModifyIntervention',
    async (_, { dispatch }) => {
        const subscription = await API.graphql({
            query: subscriptions.onUpdateInterventions,
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        }).subscribe({
            next: ({ provider, value }) => {
                dispatch(interventionModifyOne({
                    id: value.data.onUpdateInterventions.id,
                    //changes: {
                    idMachinery: value.data.onUpdateInterventions.idAsset,
                    idPrevIntervention: value.data.onUpdateInterventions.idPrevIntervention,
                    date: value.data.onUpdateInterventions.data,
                    period: value.data.onUpdateInterventions.period,
                    title: value.data.onUpdateInterventions.idFault,
                    isDeleted: value.data.onUpdateInterventions.isDeleted,
                    ora: value.data.onUpdateInterventions.ora,
                    createdAt: value.data.onUpdateInterventions.createdAt,
                    updatedAt: value.data.onUpdateInterventions.updatedAt,
                    //  }

                }))
            },
            error: (error) => {
                console.error('Subscription error:', error);
            },
        })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        //return subscription;
    }
);
export const subscribeCreateDataIntervention = createAsyncThunk(
    'intervention/subscriptionCreateDataIntervention',
    async (_, { dispatch }) => {
        const subscription = await API.graphql({
            query: subscriptions.onCreateDataInterventions,
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        }).subscribe({
            next: ({ provider, value }) => {

                dispatch(interventionModifyOne({
                    id: value.data.onCreateDataInterventions.id,
                    //   changes: {
                    //creatoreEvento: value.data.onUpdateDataInterventions.creatore,
                    durata: value.data.onCreateDataInterventions.durata,
                    idAsset: value.data.onCreateDataInterventions.idAsset,
                    idPlant: value.data.onCreateDataInterventions.idPlant,
                    note: value.data.onCreateDataInterventions.note,
                    attivita: JSON.parse(value.data.onCreateDataInterventions.activity[0]),
                    reparto: value.data.onCreateDataInterventions.reparto,
                    operatore: value.data.onCreateDataInterventions.operatore,
                    createdAt: value.data.onCreateDataInterventions.createdAt,
                    updatedAt: value.data.onCreateDataInterventions.updatedAt,
                    //  }

                }))

            },
            error: (error) => {
                console.error('Subscription error:', error);
            },
        })



        //return subscription;
    }
);
export const subscribeModifyDataIntervention = createAsyncThunk(
    'intervention/subscriptionDataIntervention',
    async (_, { dispatch }) => {
        const subscription = await API.graphql({
            query: subscriptions.onUpdateDataInterventions,
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        }).subscribe({
            next: ({ provider, value }) => {

                dispatch(interventionModifyOne({
                    id: value.data.onUpdateDataInterventions.id,
                    // changes: {
                    //creatoreEvento: value.data.onUpdateDataInterventions.creatore,
                    durata: value.data.onUpdateDataInterventions.durata,
                    idAsset: value.data.onUpdateDataInterventions.idAsset,
                    idPlant: value.data.onUpdateDataInterventions.idPlant,
                    note: value.data.onUpdateDataInterventions.note,
                    attivita: JSON.parse(value.data.onUpdateDataInterventions.activity[0]),
                    reparto: value.data.onUpdateDataInterventions.reparto,
                    operatore: value.data.onUpdateDataInterventions.operatore,
                    createdAt: value.data.onUpdateDataInterventions.createdAt,
                    updatedAt: value.data.onUpdateDataInterventions.updatedAt,
                    //  }

                }))
            },
            error: (error) => {
                console.error('Subscription error:', error);
            },
        })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
       // return subscription;
    }
);

//CHIAMATE ASINCRONE


export const getUpdateInterventions = createAsyncThunk('intervention/getUpdateinterventions', async (a, { getState }) => {
    /**
     * VA SISTEMATO L'AVVIO, UNA SOLUZIONE È L'IMPLMENTAZIONE DEL PARAMETRO DELETED DOVE NEL CASO SI POSTO A TRUE NON COMPARE E DOPO UN CERTO PERIODO DI TEMPO VA ELEMINATO DAI DATI IN LOCALE
     *
     * UN ULTERIORE PASSO È L'INSERIMENTO DI UN INTERVENTO DEI DEFAULT CHE GENERA UN PUNTO ZERO DELLE POSSIBILI RICHIESTE AL PRIMO ACCESSO
     * QUESTO VA SUCCESSIVAMENTE ELIMINATO SE CI SONO ALTRI INTERVENTI IN LISTA
     */
    let dateLastElementString = ""
    const dataAttuale = new Date().toISOString()
    if (Object.values(getState().intervention.entities).length !== 0) {
        dateLastElementString = Object.values(getState().intervention.entities).sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))[0].updatedAt
    } else {
        dateLastElementString = "2023-09-27T15:39:21.251Z"
    }

    let dateLastElement = new Date(Date.parse(dateLastElementString) - 7 * GIORNIMILLISECONDI).toISOString()
    //durante il primo caricameno dove non ci sono interventi salvati in locale do una data indicativa della messa in produzione della dashboard attuale
    const variables = {
        filter: {
            updatedAt: {
                between: [dateLastElement, dataAttuale],

            }
        }
    }


    const response = await API.graphql({
        query: listInterventions,
        variables: variables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    }
    )
    const listNewIntervention = response.data.listInterventions.items.map(el => {

        return {
            id: el.id,
            title: el.idFault,
            idMachinery: el.idAsset,//questo è l'id del backend dell'asset su questa base andrebbe recuoerato l'idMacchinario utilizzato dall'azienda e quindi lincato allo specifico intervento
            date: el.data,
            createdAt: el.createdAt,
            updatedAt: el.updatedAt,
            active: true,
            period: el.period,
            idPrevIntervention: el.idPrevIntervention,
            ora: el.ora,
            link: Object.values(getState().fault.entities).find((element) => el.idFault === element.id) ?
                `${pathExplorPlant}/${Object.values(getState().listMachinery.entities).find((element) => element.id === el.idAsset).identityCode}`
                :
                `${pathPlanninng}`,
            backgroundColor: "orange",
            borderColor: "orange",
            isDeleted: el.isDeleted

        }
    })
    return listNewIntervention

})





export const getUpdateDataInterventions = createAsyncThunk('intervention/getUpdateDataInterventions', async (a, { getState }) => {
    /**
     * VA SISTEMATO L'AVVIO, UNA SOLUZIONE È L'IMPLMENTAZIONE DEL PARAMETRO DELETED DOVE NEL CASO SI POSTO A TRUE NON COMPARE E DOPO UN CERTO PERIODO DI TEMPO VA ELEMINATO DAI DATI IN LOCALE
     *
     * UN ULTERIORE PASSO È L'INSERIMENTO DI UN INTERVENTO DEI DEFAULT CHE GENERA UN PUNTO ZERO DELLE POSSIBILI RICHIESTE AL PRIMO ACCESSO
     * QUESTO VA SUCCESSIVAMENTE ELIMINATO SE CI SONO ALTRI INTERVENTI IN LISTA
     */
    let dateLastElementString = ""
    const dataAttuale = new Date().toISOString()
    if (Object.values(getState().intervention.entities).length !== 0) {
        dateLastElementString = Object.values(getState().intervention.entities).sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))[0].updatedAt
    } else {
        dateLastElementString = "2023-09-27T15:39:21.251Z"
    }

    let dateLastElement = new Date(Date.parse(dateLastElementString) - 7 * GIORNIMILLISECONDI).toISOString()
    //durante il primo caricameno dove non ci sono interventi salvati in locale do una data indicativa della messa in produzione della dashboard attuale
    const variables = {
        filter: {
            updatedAt: {
                between: [dateLastElement, dataAttuale],

            }
        }
    }


    const response = await API.graphql({
        query: listDataInterventions,
        variables: variables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    }
    )
    const listNewIntervention = response.data.listDataInterventions.items.map(el => {
        return {
            idAsset: el.idAsset,
            id: el.id,
            creatoreEvento: el.creatore,
            durata: el.durata,
            idPlant: el.idPlant,
            note: el.note,
            attivita: JSON.parse(el.activity[0]),
            reparto: el.reparto,
            operatore: el.operatore,
            link: Object.values(getState().fault.entities).find((element) => el.idFault === element.id) ?
                `${pathExplorPlant}/${Object.values(getState().listMachinery.entities).find((element) => element.id === el.idAsset).identityCode}`
                :
                `${pathPlanninng}`,
            backgroundColor: "orange",
            borderColor: "orange",


        }
    })
    return listNewIntervention

})



export const newIntervention = createAsyncThunk('intervention/putNewIntervention', async ({ idAsset, data, idFault, ora, period, operatore, idPrevIntervention, isDeleted }) => {
    const response = await API.graphql({
        query: createInterventions,
        variables: {
            input: {
                idAsset, data, idFault, period, ora, operatore, idPrevIntervention, isDeleted
            }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })
    return {
        id: response.data.createInterventions.id,
        createdAt: response.data.createInterventions.createdAt,
        date: response.data.createInterventions.data,
        idMachinery: response.data.createInterventions.idAsset,
        title: response.data.createInterventions.idFault,
        idPrevIntervention: response.data.createInterventions.idPrevIntervention,
        isDeleted: response.data.createInterventions.isDeleted,
        ora: response.data.createInterventions.ora,
        period: response.data.createInterventions.period,
        updatedAt: response.data.createInterventions.updatedAt,
        link: "/planning",
        backgroundColor: "orange",
        borderColor: "orange",
        active: true
    }
})
export const modifyIntervention = createAsyncThunk('intervention/modifyIntervention', async ({ id, idAsset, data, idFault, ora, period, operatore }) => {

    const response = await API.graphql({
        query: updateInterventions,
        variables: {
            input: {
                id, idAsset, data, idFault, period, ora, operatore
            }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })

    return {
        id: response.data.updateInterventions.id,
        createdAt: response.data.updateInterventions.createdAt,
        date: response.data.updateInterventions.data,
        idMachinery: response.data.updateInterventions.idAsset,
        title: response.data.updateInterventions.idFault,
        idPrevIntervention: response.data.updateInterventions.idPrevIntervention,
        isDeleted: response.data.updateInterventions.isDeleted,
        ora: response.data.updateInterventions.ora,
        period: response.data.updateInterventions.period,
        updatedAt: response.data.updateInterventions.updatedAt
    }
})
export const deleteIntervention = createAsyncThunk('intervention/deleteIntervention', async ({ idAsset, id, isDeleted }) => {
    const response = await API.graphql({
        query: updateInterventions,
        variables: {
            input: {
                idAsset, id, isDeleted
            }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })

    return {
        id: response.data.updateInterventions.id,
        createdAt: response.data.updateInterventions.createdAt,
        date: response.data.updateInterventions.data,
        idMachinery: response.data.updateInterventions.idAsset,
        title: response.data.updateInterventions.idFault,
        idPrevIntervention: response.data.updateInterventions.idPrevIntervention,
        isDeleted: response.data.updateInterventions.isDeleted,
        ora: response.data.updateInterventions.ora,
        period: response.data.updateInterventions.period,
        updatedAt: response.data.updateInterventions.updatedAt
    }
}
)



export const createDataIntervention = createAsyncThunk('intervention/putDataIntervention', async ({
    idAsset, id, idPlant, durata, reparto, operatore, creatore, note, activity
}) => {
    const response = await API.graphql({
        query: createDataInterventions,
        variables: {
            input: {
                idAsset, id, idPlant, durata, reparto, operatore, creatore, note, activity
            }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })

    return {


        id: response.data.createDataInterventions.id,
        idPlant: response.data.createDataInterventions.idPlant,
        idMachinery: response.data.createDataInterventions.idAsset,
        durata: response.data.createDataInterventions.durata,
        createdAt: response.data.createDataInterventions.createdAt,
        updatedAt: response.data.createDataInterventions.updatedAt,
        operatore: response.data.createDataInterventions.operatore,
        creatore: response.data.createDataInterventions.creatore,
        note: response.data.createDataInterventions.note,
        attivita: JSON.parse(response.data.createDataInterventions.activity[0])

    }

})
export const modifyDataIntervention = createAsyncThunk('intervention/modifyDataIntervention', async ({
    idAsset, id, idPlant, durata, reparto, operatore, creatore, note, activity
}) => {
    const response = await API.graphql({
        query: updateDataInterventions,
        variables: {
            input: {
                idAsset, id, idPlant, durata, reparto, operatore, creatore, note, activity
            }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })
    return {


        id: response.data.updateDataInterventions.id,
        idPlant: response.data.updateDataInterventions.idPlant,
        idMachinery: response.data.updateDataInterventions.idAsset,
        durata: response.data.updateDataInterventions.durata,
        createdAt: response.data.updateDataInterventions.createdAt,
        updatedAt: response.data.updateDataInterventions.updatedAt,
        operatore: response.data.updateDataInterventions.operatore,
        creatore: response.data.updateDataInterventions.creatore,
        note: response.data.updateDataInterventions.note,
        attivita: JSON.parse(response.data.updateDataInterventions.activity[0])

    }

})

export const getDataSpecificMachineInterventio = createAsyncThunk('intervention/getDataSpecificMachineIntervention', async ({
    idAsset
}) => {
    const response = await API.graphql({
        query: listDataInterventions,
        variables: {
            idAsset: idAsset
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })

    const dati = response.data.listDataInterventions.items.map((el) => {
        return {
            id: el.id,
            creatoreEvento: el.creatore,
            durata: el.durata,
            idPlant: el.idPlant,
            note: el.note,
            attivita: JSON.parse(el.activity[0]),
            reparto: el.reparto,
            operatore: el.operatore

        }
    })


    return dati
})



export const getDataSpecificIntervention = createAsyncThunk("intervention/getDataSpecificIntervention", async ({ id, idAsset }) => {
    const response = await API.graphql({
        query: getDataInterventions,
        variables: {

            id: id,
            idAsset: idAsset
        }
        ,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })

    const dati = response.data.getDataInterventions

    //DA RIMUOVERE ACTIVITY DAI DATI

    return {
        ...dati,
        attivita: JSON.parse(dati.activity[0]),


    }
})

const interventionSlice = createSlice({
    name: "intervention",
    initialState: interventionAdapter.getInitialState(),
    reducers: {
        interventionAddOne: interventionAdapter.upsertOne,
        interventionModifyOne: interventionAdapter.upsertOne,
        interventionAddMany: interventionAdapter.upsertMany,
        //successivamente da sostituire con una promise ed effettuare un salvataggio in locale per minimizzare i dati da salvare su server
        deactiveInterventionForNotification(state, action) {
            const { id } = action.payload

            const value = state.entities[id.id]
            value.active = false

        },
        interventionLoad(state, action) {
            /**
             * funzione per l'aggiornamento dei componenti che sono stati scaricati alla quale vengono
             * aggiunti una serie di campi utili alla gestione del frontend
             *
             * successivamente questi campi devono essere verificati con i dati salvati localmente
             *
             * per la generazione del link si può pensare anche che una volta che viene cliccato il link
             * si apre la scheda planning con un pop up con i dettagli dell'intervento selezionato
             * dalla notifica
             */

            const loaded = action.payload.map(el => {
                el.link = `${pathPlanninng}`
                el.active = true
                return el
            })
            interventionAdapter.upsertMany(state, loaded)
        }

    },
    extraReducers: bulder =>
        bulder.addCase(getUpdateInterventions.fulfilled, interventionAdapter.upsertMany)
            .addCase(getDataSpecificMachineInterventio.fulfilled, interventionAdapter.upsertMany)
            .addCase(getDataSpecificIntervention.fulfilled, interventionAdapter.upsertOne)
            .addCase(getUpdateDataInterventions.fulfilled, interventionAdapter.upsertMany)
            .addCase(newIntervention.rejected, (state, action) => console.log(state, action))
            .addCase(newIntervention.fulfilled, interventionAdapter.upsertOne)
            .addCase(modifyIntervention.fulfilled, interventionAdapter.upsertOne)
            .addCase(deleteIntervention.fulfilled, interventionAdapter.upsertOne)
            .addCase(createDataIntervention.fulfilled, interventionAdapter.upsertOne)
            .addCase(modifyDataIntervention.fulfilled, interventionAdapter.upsertOne)
    /*
    .addCase(subscribeCreateIntervention.pending, (state, action) => {
        console.log("stato attivo", state, action)
    })
    .addCase(subscribeCreateIntervention.fulfilled, (state, action) => {
        console.log("disattivato", state, action)
    })
    .addCase(subscribeModifyIntervention.pending, (state, action) => {
        console.log("stato attivo", state, action)
    })
    .addCase(subscribeModifyIntervention.fulfilled, (state, action) => {
        console.log("disattivato", state, action)
    })
    */

})


export default interventionSlice.reducer

export const {
    interventionAddOne,
    interventionAddMany,
    deactiveInterventionForNotification,
    interventionLoad,
    interventionModifyOne


} = interventionSlice.actions


export const { selectAll: selectAllInter } = interventionAdapter.getSelectors(state => state.intervention)

export const selectAllIntervention = createSelector([selectAllInter, selectAllMachinery],
    (state, machine) => {
        //l'obiettivo è effettuare un filtraggio degli interventi che fanno riferimenti ad un macchianrio che non è nella lista dei macchinari
        let listIntervention = []
        state.forEach(el => {
            if (machine.find(a => a.id === el.idAsset)) {
                listIntervention.push(el)
            }
        })

        return listIntervention
    })


export const getInterventionFilter = createSelector(
    //passiamo lo stato delle notifiche 
    selectAllIntervention,
    //passiamo lo stato delle notifiche
    state => state.notificationFilter,
    //definiamo la funzione che restituisce gli alert in funzioen dello stato delle notifiche
    (state, filter) => {
        if (filter.status === StatusFilters.All) {
            return state
        }
        else {
            return state.filter(el => el.active === true)
        }
    })


export const getInterventionForMachinerySelected = createSelector(
    selectAllIntervention, getMachineSelected,
    (state, selected) => {
        if (selected) {
            return state.filter(el => el.idMachinery === selected.idMachinery)
        }
    }
)

export const listInterventionToday = createSelector(selectAllIntervention, state => state.report, (state, report) => {
    const dataOdierna = new Date().toISOString().slice(0, 10)
    const elencoInterventiOggi = state.filter(el => el.date === dataOdierna)
    let effettiviOggi = []

    elencoInterventiOggi.map(el => {
        if (!Object.values(report.entities).find(elemento => elemento.idIntervention === el.id)) {
            effettiviOggi.push(el)
        }
        return 0
    })



    return effettiviOggi
})

export const listInterventionNext = createSelector(selectAllIntervention, state => state.report, (state, report) => {

    const dataOdierna = new Date().toISOString().slice(0, 10)
    //mi prendo tutti gli interventi che non sono stati programmati per oggi
    let listaOrdinata = state.filter(el => el.date > dataOdierna)
    //ordino tutti gli interventi rimasti
    listaOrdinata = listaOrdinata.sort((a, b) => a.date > b.date)
    //filtri gli interventi con la data dell'intervento che in lista ha sicuramente la data del primo giorno con interventi

    let listaEffettiviProssimi = []
    listaOrdinata.map(el => {
        if (!Object.values(report.entities).find(elemento => elemento.idIntervention === el.id)) {
            listaEffettiviProssimi.push(el)
        }
        return 0
    })

    const listaProssimi = listaEffettiviProssimi.filter(el => el.date === listaEffettiviProssimi[0].date)
    return listaProssimi
})


export const listaInterventiDaySelected = createSelector(selectAllIntervention, state => state.report, state => state.dayCalendar, (state, report, dayCalendar) => {
    const lista = state.filter(el => el.date === dayCalendar.date)
    //.filter(el => !Object.values(report.entities).find(elemento => elemento.idIntervention === el.id))
    return lista
})


export const listaInterventiPeriodici = createSelector(selectAllIntervention,
    state => {
        return state.filter(el => el.period !== 0 && el.period !== "0" && el.period !== "")
    }
)