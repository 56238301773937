import React from "react";
import { titleColor } from "../../../configurations/generalStye";


const TableMachineDocument = ({ DocumentList, datiTarga, infoMachine, listFamily }) => {

    return (
        <div className="bodyDatiTarga">
            <div className="bodyTableMachineDocument">
                <h3
                    className="elementTitoloTableMachineDocument"
                    style={{ "color": titleColor }}
                >Dati Macchinario
                </h3>
                <ul className="elementDatiTargaList">
                    <li className="elementDatiTargaElementList">
                        <h6 className="elementDatiTargaElementListName">id :</h6>
                        <h6 className="elementDatiTargaElementListValue">{infoMachine ? infoMachine.identityCode : ""}</h6>
                    </li>
                    <li className="elementDatiTargaElementList">
                        <h6 className="elementDatiTargaElementListName">Famiglia macchinario :</h6>
                        <h6 className="elementDatiTargaElementListValue">{infoMachine ?
                            listFamily.find(el => el.id === infoMachine.familyId) ?
                                listFamily.find(el => el.id === infoMachine.familyId).famiglia
                                :
                                infoMachine.familyId
                            :
                            ""}</h6>
                    </li>
                    <li className="elementDatiTargaElementList">
                        <h6 className="elementDatiTargaElementListName">Produttore :</h6>
                        <h6 className="elementDatiTargaElementListValue">{datiTarga ? datiTarga.produttore : ""}</h6></li>
                    <li className="elementDatiTargaElementList">
                        <h6 className="elementDatiTargaElementListName">Anno produzione :</h6>
                        <h6 className="elementDatiTargaElementListValue">{datiTarga ? datiTarga.annoProd : ""} </h6>
                    </li>
                    <li className="elementDatiTargaElementList">
                        <h6 className="elementDatiTargaElementListName"> Anno installazione :</h6>
                        <h6 className="elementDatiTargaElementListValue">{datiTarga ? datiTarga.annoInst : ""}</h6>

                    </li>
                </ul>

            </div>
            <div className="bodyTableMachineDocument">
                <h3
                    className="elementTitoloTableMachineDocument"
                    style={{ "color": titleColor }}
                >Documentazione tecnica</h3>
                <div className="boxHeadTableMachineDocument">
                    <h5 className="elementStandardTableMachineDocument">Titolo</h5>
                    <h5 className="elementStandardTableMachineDocument">Descrizione</h5>
                    <h5 className="elementStandardTableMachineDocument">Link</h5>
                    <h5 className="elementLongTableMachineDocument">Note</h5>

                </div>
                <div >
                    {
                        (DocumentList && DocumentList.length !== 0) ?
                            DocumentList.map((el, index) => {
                                return (
                                    <div
                                        className={`boxRowTableMachineDocument ${index % 2 ? "backgroundWhiteTableMachineDocument" : "backgroundBlueTableMachineDocument"}`}
                                        key={index}
                                    >
                                        <h6 className="elementStandardTableMachineDocument elementTestoStandardTableMachineDocument" >{el.nome}</h6>
                                        <h6 className="elementStandardTableMachineDocument elementTestoStandardTableMachineDocument" >{el.desc}</h6>
                                        <a className="elementStandardTableMachineDocument "
                                            href={`${el.link}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <div>
                                                apri
                                            </div>
                                        </a>
                                        <h6 className="elementLongTableMachineDocument elementTestoStandardTableMachineDocument" >{el.note}</h6>
                                    </div>)
                            })
                            :
                            <div></div>

                    }
                </div>
            </div>
        </div>
    )

}

export default TableMachineDocument