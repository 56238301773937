import './navBar.css';
import { pathAlertView, pathExplorPlant, pathImpostazioni, pathMetriche, pathOverview, pathPlanninng } from '../../configurations/setting'
import Planningicona from '../../icon/planningicona.svg';
import ExplorePlantIcona from '../../icon/exploreplanticona.svg';
import alertIcona from '../../icon/alertViewicona.svg'
import LogoOverview from '../../icon/overviewicona.svg';
import LogoImpostazioni from '../../icon/settings.svg';

import IconaNavigazione from '../../components/navigationIcon/mainNavigationIcon';



const NavBar = ({ activePath }) => {

    return (
        <div
            className="boxMainBarraNavigazione"
        >
            <div
                className="boxLeftBarraNavigazione"
            >
                <IconaNavigazione
                    IconaNavigazione={LogoOverview}
                    IconaNavigazioneLight={LogoOverview}
                    titolo={"Overview"}
                    activePath={activePath}
                    link={pathOverview}

                />
                {/*
                <IconaNavigazione
                    IconaNavigazione={alertIcona}
                    IconaNavigazioneLight={alertIcona}
                    titolo={"Alert views"}
                    link={pathAlertView}
                    activePath={activePath}
                    
                />
                 */}

                <IconaNavigazione
                    IconaNavigazione={ExplorePlantIcona}
                    IconaNavigazioneLight={ExplorePlantIcona}
                    activePath={activePath}
                    titolo={"Explore Plant"}
                    link={pathExplorPlant}

                />
                <IconaNavigazione
                    IconaNavigazione={Planningicona}
                    IconaNavigazioneLight={Planningicona}
                    activePath={activePath}
                    titolo={"Planning"}
                    link={pathPlanninng}

                />
                <IconaNavigazione
                    IconaNavigazione={alertIcona}
                    IconaNavigazioneLight={alertIcona}
                    activePath={activePath}
                    titolo={"Metriche"}
                    link={pathMetriche}

                />
            </div>
            <div
                className="boxRightBarraNavigazione"
            >

                <IconaNavigazione
                    IconaNavigazione={LogoImpostazioni}
                    IconaNavigazioneLight={LogoImpostazioni}
                    link={pathImpostazioni}
                    activePath={activePath}

                />
            </div>
        </div>
    )
}

export default NavBar