import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { pathExplorPlant, pathMachineryFaultList } from '../../../configurations/setting';
import { titleColor } from '../../../configurations/generalStye';
import Modal from '../../DialogModal/mainDialogModal';
import AddNewAsset from '../../addNewAsset/mainAddNewAsset';
import AddNewStabilimento from '../../addNewStabilimento/mainAddNewStabilimento';
import AddNewReparto from "../../addNewReparto/mainAddNewReparto";
import AddNewLinea from "../../addNewLinea/mainAddNewLinea";
import VerticalMenu from "./verticalMenu";


const ButtonAddNewAsset = ({ plant, line, department, listPlant, listDepartment, listLine }) => {
    const [openNewAsset, setOpenNewAsset] = React.useState(false)
    return <span>
        <span
            className='elementAddNewAsset'
            onClick={() => setOpenNewAsset(!openNewAsset)}

        >+</span>
        {openNewAsset ?
            <Modal
                show={openNewAsset}
            >
                <AddNewAsset
                    mode="add"

                    plant={plant}
                    line={line}
                    department={department}

                    listPlant={listPlant}
                    listDepartment={listDepartment}
                    listLine={listLine}

                    close={() => setOpenNewAsset(!openNewAsset)}
                />
            </Modal>
            :
            null
        }
    </span>
}
const ButtonAddNewStabilimento = ({ }) => {
    const [openNewStabilimento, setOpenNewStabilimento] = React.useState(false)
    return <span>
        <span
            className='elementAddNewAsset'
            onClick={() => setOpenNewStabilimento(!openNewStabilimento)}

        >+</span>
        {
            openNewStabilimento ?
                <Modal
                    show={openNewStabilimento}
                >
                    <AddNewStabilimento
                        mode="add"
                        close={() => setOpenNewStabilimento(!openNewStabilimento)}
                    />
                </Modal>
                :
                null
        }
    </span>
}


const ButtonAddNewReparto = ({ listPlant, listBudgets, listDepartment, stabilimento }) => {
    const [openNewReparto, setOpenNewReparto] = React.useState(false)
    return <span>
        <span
            className='elementAddNewAsset'
            onClick={() => setOpenNewReparto(!openNewReparto)}

        >+</span>
        {
            openNewReparto ?
                <Modal
                    show={openNewReparto}
                >
                    <AddNewReparto
                        mode="add"
                        listPlant={listPlant}
                        listBudgets={listBudgets}
                        listDepartment={listDepartment}
                        stabilimento={stabilimento}
                        close={() => setOpenNewReparto(!openNewReparto)}
                    />
                </Modal>
                :
                null
        }
    </span>
}

const ButtonAddNewLinea = ({ listPlant,
    listDepartment,
    listLine,
    listMachinery,
    listBudgets,
    stabilimento,
    reparto
}) => {
    const [openNewLinea, setOpenNewLinea] = React.useState(false)

    return <span>
        <span
            className='elementAddNewAsset'
            onClick={() => setOpenNewLinea(!openNewLinea)}

        >+</span>
        {
            openNewLinea ?
                <Modal
                    show={openNewLinea}
                >
                    <AddNewLinea
                        mode="add"
                        listPlant={listPlant}
                        listDepartment={listDepartment}
                        listLine={listLine}
                        listMachinery={listMachinery}
                        listBudgets={listBudgets}
                        stabilimento={stabilimento}
                        reparto={reparto}
                        close={() => setOpenNewLinea(!openNewLinea)}
                    />
                </Modal>
                :
                null
        }
    </span>
}
function ListaLinee(props) {
    const { line, stabilimento, reparto } = props;
    const { dataPlant, listFamily } = props;
    const { listPlant, listDepartment, listLine, listMachinery, listDatiTarga } = props;
    const { listBudgets } = props;
    const [open, setOpen] = React.useState(false);
    const effectiveListMachineForPlant = listMachinery.filter(el => el.idPlant === stabilimento.id && el.idLinea === line.id && el.idDepartment === reparto.id)


    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        color='primary'
                        onClick={() => setOpen(!open)}

                    >
                        {open ? <KeyboardArrowUpIcon className="table-arrow-icon" /> : <KeyboardArrowDownIcon className="table-arrow-icon" />}
                    </IconButton>
                </TableCell >
                <TableCell className="table-primary-level-cell-reparto-linea" component="th" scope="row">
                    {line.name}
                </TableCell>
                <TableCell className="menu-button" component="th" scope="row">
                    <VerticalMenu type="line" object={line} listPlant={listPlant} listDepartment={listDepartment} listLine={listLine} listMachinery={listMachinery} listBudgets={listBudgets} />
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell className="table-box" colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box className="table-box-intern" >
                            <Typography className="table-box-intern-title" variant="h7" gutterBottom component="div">
                                <span className='elementTitleAggiungiLinea'>Aggiungi Macchinario</span>

                                <ButtonAddNewAsset

                                    plant={stabilimento}
                                    line={line}
                                    department={reparto}

                                    listPlant={listPlant}
                                    listDepartment={listDepartment}
                                    listLine={listLine}
                                />
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="table-header-cell-second-level">
                                            Nome
                                        </TableCell>
                                        <TableCell className="table-header-cell-second-level">
                                            ID
                                        </TableCell>
                                        <TableCell className="table-header-cell-second-level">
                                            Famiglia

                                        </TableCell>
                                        <TableCell className="table-header-cell-second-level">
                                            Linea

                                        </TableCell>
                                        <TableCell className="table-header-cell-second-level">
                                            HI
                                        </TableCell>
                                        <TableCell className="table-header-cell-reparto-linea">

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {

                                        effectiveListMachineForPlant.map((historyRow, index) => (
                                            <TableRow key={index}>
                                                <TableCell className="table-secondary-level-cell" component="th" scope="row">
                                                    {historyRow.nome}
                                                </TableCell>
                                                <TableCell className="table-secondary-level-cell">
                                                    <Link
                                                        to={`${pathExplorPlant}/${historyRow.id}/${pathMachineryFaultList}`}
                                                    >
                                                        {historyRow.identityCode}
                                                    </Link>

                                                </TableCell>
                                                <TableCell className="table-secondary-level-cell">{
                                                    listFamily.find(el => el.id === historyRow.familyId) ?
                                                        listFamily.find(el => el.id === historyRow.familyId).famiglia
                                                        :
                                                        historyRow.familyId
                                                }</TableCell>
                                                <TableCell className="table-secondary-level-cell">{historyRow.reparto}</TableCell>
                                                <TableCell className="table-secondary-level-cell">
                                                    {historyRow.hi}
                                                </TableCell>
                                                <TableCell className="menu-button" component="th" scope="row">
                                                    <VerticalMenu type="machinery" object={historyRow} listPlant={listPlant} listDepartment={listDepartment} listLine={listLine} listMachinery={listMachinery} listBudgets={listBudgets} listDatiTarga={listDatiTarga} />
                                                </TableCell>
                                            </TableRow>
                                        ))

                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function ListaReparti(props) {
    const { reparto, stabilimento } = props;
    const [open, setOpen] = React.useState(false);
    const { dataPlant, listFamily } = props;
    const { listPlant, listDepartment, listLine, listMachinery, listDatiTarga } = props;
    const { listBudgets } = props;

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        color='primary'
                        onClick={() => setOpen(!open)}

                    >
                        {open ? <KeyboardArrowUpIcon className="table-arrow-icon" /> : <KeyboardArrowDownIcon className="table-arrow-icon" />}
                    </IconButton>
                </TableCell >
                <TableCell className="table-primary-level-cell-reparto-linea" component="th" scope="row">
                    {reparto.name}
                </TableCell>
                <TableCell className="menu-button" component="th" scope="row">
                    <VerticalMenu type="department" object={reparto} listPlant={listPlant} listDepartment={listDepartment} listLine={listLine} listMachinery={listMachinery} listBudgets={listBudgets} />
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell className="table-box" colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box className="table-box-intern" >
                            <Typography className="table-box-intern-title-reparti-linee" variant="h1" gutterBottom component="div">
                            </Typography>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="table-header-cell-reparto-linea" />
                                        <TableCell className="table-header-cell-reparto-linea">
                                            <div className='boxAddLinea'>
                                                Linee
                                                <ButtonAddNewLinea
                                                    listPlant={listPlant}
                                                    listDepartment={listDepartment}
                                                    listLine={listLine}
                                                    listMachinery={listMachinery}
                                                    listBudgets={listBudgets}
                                                    stabilimento={stabilimento}
                                                    reparto={reparto}
                                                /></div>
                                        </TableCell>
                                        <TableCell className="table-header-cell-reparto-linea">

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        listLine.filter(line => line.idDepartment === reparto.id).map((line, index) => (
                                            <ListaLinee line={line} stabilimento={stabilimento} reparto={reparto} key={index} listPlant={listPlant} listDepartment={listDepartment} listLine={listLine} listMachinery={listMachinery} dataPlant={dataPlant} listFamily={listFamily} listBudgets={listBudgets} listDatiTarga={listDatiTarga} />
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function ListaStabilimenti(props) {
    const { stabilimento } = props;
    const { listPlant, listDepartment, listLine, listMachinery, listDatiTarga } = props;
    const { listBudgets } = props
    const { dataPlant, listFamily } = props;
    //const listPlant = [];
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        color='primary'
                        onClick={() => setOpen(!open)}

                    >
                        {open ? <KeyboardArrowUpIcon className="table-arrow-icon" /> : <KeyboardArrowDownIcon className="table-arrow-icon" />}
                    </IconButton>
                </TableCell >
                <TableCell className="table-primary-level-cell" component="th" scope="row">
                    {stabilimento.nome}
                </TableCell>
                <TableCell className="menu-button" component="th" scope="row">
                    <VerticalMenu type="plant" object={stabilimento} listPlant={listPlant} listDepartment={listDepartment} listLine={listLine} listMachinery={listMachinery} listBudgets={listBudgets} />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className="table-box" colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box className="table-box-intern" >
                            <Typography className="table-box-intern-title-reparti-linee" variant="h7" gutterBottom component="div">
                            </Typography>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="table-header-cell-reparto-linea" />
                                        <TableCell className="table-header-cell-reparto-linea">
                                            <div className='boxAddLinea'>
                                                Reparti
                                                <ButtonAddNewReparto
                                                    listPlant={listPlant}
                                                    listBudgets={listBudgets}
                                                    listDepartment={listDepartment}
                                                    stabilimento={stabilimento}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell className="table-header-cell-reparto-linea">

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        listDepartment.filter(department => department.idPlant === stabilimento.id).map((row, index) => (
                                            <ListaReparti reparto={row} stabilimento={stabilimento} key={index} listPlant={listPlant} listDepartment={listDepartment} listLine={listLine} listMachinery={listMachinery} listBudgets={listBudgets} listDatiTarga={listDatiTarga} dataPlant={dataPlant} listFamily={listFamily} />
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function CollapsibleTable({ listDatiTarga, listPlant, listDepartment, listLine, listMachinery, dataPlant, listFamily, listBudgets }) {

    return (
        <TableContainer component={Paper}>
            <Box className="" >
                <Typography className="table-box-intern-title-stabilitimenti" variant="h7" gutterBottom component="div">

                    
                </Typography>
                <Table aria-label="collapsible table">

                    <TableHead>
                        <TableRow>
                            <TableCell className="table-header-cell" />
                            <TableCell className="table-header-cell">
                               <div className='boxAddLinea'>
                               Stabilimenti
                               <ButtonAddNewStabilimento />
                                </div> 
                            </TableCell>
                            <TableCell className="table-header-cell">

                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            listPlant.map((row, index) => (
                                <ListaStabilimenti stabilimento={row} key={index} listPlant={listPlant} listDepartment={listDepartment} listLine={listLine} listMachinery={listMachinery} listBudgets={listBudgets} listDatiTarga={listDatiTarga} dataPlant={dataPlant} listFamily={listFamily} />
                            ))
                        }
                    </TableBody>
                </Table>
            </Box>
        </TableContainer>
    );
}