import "./mainOverview.css";
import TripleDonatChart from "../../components/tripleDonatChart/mainTripleDonatChart";
import Mappa from "../../components/map/mainMap";
import AlertListOverview from "../../components/alertListOverview/mainAlertListOverview";
import React, { useState, useEffect, useRef } from "react";
import LinearGraph from "../../components/lineargraph/mainLinearGraph";
import { titleColor } from "../../configurations/generalStye";
import { getSelectPlant } from "../root/slices/plantSlice";
import {
    getSelectedDataPlant,
    selectDataPlant,
} from "../root/slices/dataPlantSlice";

import { useDispatch, useSelector } from "react-redux";
import { getAlertAlgoritmForSelectedPlant } from "../root/slices/alertAlgoritmSlice";
import '../../components/lineargraph/mainLinearGraph.css'
const Overview = () => {
    //solo accesso all'impianto selezionato
    const [resolution, setResolution] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const selectedPlant = useSelector(getSelectPlant);
    const selectedDataPlant = useSelector(getSelectedDataPlant);
    const selectedAlertAlgoritm = useSelector(getAlertAlgoritmForSelectedPlant)
    const resizeObserverRef = useRef(null);
    const handleResize = (entries) => {
        const { width, height } = entries[0].contentRect;
        setResolution({ width, height });
    };
    /**
     * va aggiunto il controllo del id del macchinario e renderizare a video l'identity code inserito
     * all'atto di caricamento del macchinario e il nome del macchinario
     */

    const dispatch = useDispatch();
    const selectionDataPlant = (plantId, period) => {
        //imposto la selezione dei dati di impianto da visualizzare in funzione dell'impianto selezionato
        return dispatch(selectDataPlant({ plantId: plantId, period: period }));
    };
    //seleziono gli alert Algoritmo da visualizzare in funzione dell'impianto selezionato

    useEffect(() => {
        selectionDataPlant(selectedPlant?.id, 0);
    }, [selectedPlant]);



    /* Gestione usata per scalare il grafico Lineare*/
    // il divisore (2.86 per larghezza,3.8 per l'altezza) è calcolato a partire
    // dalla risoluzione e dalle proporzioni di figma.
    // Il calcolo è questo: Su figma la larghezza dell'intera finestra è 1440 px
    // mentre la larghezza della finestra relativa al grafico è 502px
    // 502px è contenuto 2.86 volte in 1440px.
    // Per mantenere queste proporzioni anche su altri schermi, vado a calcolare
    // dinamicamente larghezza e altezza della finestra con l'observer
    // e poi lo adatto divido per il divisore <<2.86>> trovato).
    useEffect(() => {
        resizeObserverRef.current = new ResizeObserver(handleResize);
        // Aggiungo l'elemento da osservare (in questo caso, l'intera finestra)
        resizeObserverRef.current.observe(document.documentElement);

    }, []);

    return (
        <div className="bodyOverview">
            <div style={{ display: "flex" }}>
                <div style={{ flex: "1" }}>
                    <TripleDonatChart
                        plant={selectedPlant}
                        title1={"Average Health Indicator"}
                        title2={"Availability rate"}
                        title3={"Saved Interventions"}
                        value1={
                            selectedDataPlant
                                ? selectedDataPlant.AverangeHealthIndicatorImpianto
                                : 0
                        }
                        value2={
                            selectedDataPlant
                                ? selectedDataPlant.tassoDiDisponibilitaImpianto
                                : 0
                        }
                        value3={selectedDataPlant ? selectedDataPlant.interventiEvitati : 0}
                        funzioneCambioStato={selectionDataPlant}
                    />
                </div>
                <div style={{ flex: "3" }}>
                    <Mappa></Mappa>
                </div>
            </div>
            <div style={{ display: "flex"}}>
                <div style={{ flex: "1" }}>
                    <AlertListOverview
                        listAlert={selectedAlertAlgoritm}
                        plant={selectedPlant?.nome}
                    //listFaultFamily
                    />
                </div>

                <div style={{ flex: "3" }} className="bodyGraficoLienare">
                    {/*NON C'E' BISOGNO PIU' DEL TITOLO CUSTOM*/}
                    {/*<div className="titleLinearGraph">
                        <div>
                            <h3
                                style={{
                                    color: `${titleColor}`,
                                    fontSize: "1.5vw",
                                    marginLeft: "0",
                                    marginTop: "1.3875vw",
                                    marginBottom: "3.2625vw",
                                }}
                            >
                                {"OEE"}
                            </h3>
                        </div>
                    </div>*/}
                    {/*GESTIONE PRECEDENTE*/}
                    {/*<LinearGraph
                        percent={true}
                        title="OEE"
                        //witdh e height calcolate e poi scalate in base alla risoluzione dello schermo
                        width={resolution.width / 2.86}
                        height={resolution.height / 4.1}
                        data={[
                            { x: "1/7", y: 0.54 },
                            { x: "2/7", y: 0 },
                            { x: "3/7", y: 0.47 },
                            { x: "4/7", y: 0.3 },
                            { x: "5/7", y: 0.15 },
                            { x: "6/7", y: 0.57 },
                            { x: "7/7", y: 0.5 },
                            { x: "8/7", y: 0.62 },
                            { x: "9/7", y: 0.65 },
                            { x: "10/7", y: 0.7 },
                        ]}
                    />*/}
                    <LinearGraph

                        fontFamily={"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu','Cantarell','Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif"}


                        title={{ "active": true, "text": "OEE", "color": '#495672', fontSize: "1.2vw" }}

                        legend={false}

                        lineWidth={1}

                        percent={true}

                        asseX={["1/7", "2/7", "3/7", "4/7", "5/7", "6/7", "7/7", "8/7", "9/7", "10/7"]}
                        series={[
                            {
                                data: [0.54, 0, 0.47, 0.3, 0.15, 0.57, 0.62, 0.62, 0.65, 0.7],
                                color: '#4287f5',
                                name: 'OEE'
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default Overview;
