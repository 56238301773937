import React, { useEffect, useState } from "react";

import { ordinaData, ricercaMeseNumero } from '../../../functions/dateTime';
import FormNewEvent from '../../formNewEvent/mainFormNewEvent'
import ListActivityTime from "../../listActivityTime/mainListActivityTime";
import ElementListToSchedule from "../../listActivityTime/subElements/elementListToSchedule";
import ElementListStandard from "../../listActivityTime/subElements/elementListStandard";
import { useDispatch, useSelector } from "react-redux";
import { getDataSpecificIntervention, listInterventionNext, listInterventionToday, listaInterventiDaySelected, listaInterventiPeriodici, selectAllIntervention } from "../../../structures/root/slices/interventionSlice";
import { getselectedDayCalendar } from "../../../structures/root/slices/dayCalendarSlice";
import { getReportCompleteForDaySelected, getReportIncompleteForDaySelected, selectAllReport } from "../../../structures/root/slices/reportSlice";
import ElementListReportIncomplete from "../../listActivityTime/subElements/elementListReportIncomplete";
import ElementListReportComplete from "../../listActivityTime/subElements/elementListReportComplete";
import { arricchimentoVirtuali, eventiPeriodiciRicadentiNelMeseSelezionato, ordinamentoInterventiArricchiti, ricercaInterventiFogliaEArricchimento } from "../../../functions/interventiPeriodici";


const PersListTimeEvent = ({ ticket }) => {

    const listAllReport = useSelector(selectAllReport)
    const listIntervention = useSelector(selectAllIntervention)
    const eventdaySelected = ordinamentoInterventiArricchiti(

        ricercaInterventiFogliaEArricchimento(
            useSelector(listaInterventiDaySelected).filter(el => el.period === 0 || el.period === "" | el.period === "0"),
            listIntervention,
            listAllReport,

        ))

    const [openCreateEvent, setOpenCreateEvent] = useState(false)
    const selectedDayCalendar = useSelector(getselectedDayCalendar)
    const ListPeriodicIntervention = useSelector(listaInterventiPeriodici)


    const [virtualEventPeriodic, setVirtualEventPeriodic] = useState([])

    const taggleOpenCreateEvent = () => {
        setOpenCreateEvent(!openCreateEvent)
    }

    useEffect(() => {
        //imposto gli eventi virtuali che vanno visualizzati nella giornata
        setVirtualEventPeriodic(
            arricchimentoVirtuali(
                //genero tutti gli eventi virtuali relativi ad interventi periodici
                eventiPeriodiciRicadentiNelMeseSelezionato(ListPeriodicIntervention, selectedDayCalendar, selectedDayCalendar)
                    //li filtro per la giornata in questione anche se la generazione già mi da solo quelli della giornata
                    .filter(el => el.date === selectedDayCalendar)
                    /**
                     * tolgo gli eventi virtuali che hanno già un intervento definito in questa giornata
                     * .filter(elemt => !eventdaySelected.find(ele => ele.idPrevIntervention === elemt.idPrevIntervention && ele.date === elemt.date))
                     */


                    /**
                     * tolgo gli interventi virtuali alla quale è stato già creato un intervento 
                     */

                    .filter(elemt => !listIntervention.find(el => el.idPrevIntervention === elemt.idPrevIntervention && el.date === elemt.date)
                    )
            )
        )

    }, [selectedDayCalendar, listIntervention])



    return (
        <div >
            <div
                className="boxDesktopListTimeEventButtom"
            >

                <button
                    className="buttonListTimeEvent"
                    onClick={() => taggleOpenCreateEvent()}
                >
                    CREA INTERVENTO
                </button>
                {
                    openCreateEvent ?
                        <FormNewEvent
                            closeScheda={taggleOpenCreateEvent}
                            openCreateEvent={openCreateEvent}
                            mode={"add"}
                        //invioDati={this.props.invioDati}
                        />
                        :
                        <span></span>
                }

            </div>
            <div className="bodyListTimeEvent">
                {
                    /*
    <ListActivityTime
    
                        nameList="Odierni"
                        isDate={`${dataOdierna.slice(8, 10)} ${mese}`}
                        elementList={ElementListStandard}
                    >
                        {eventToday.map((el, index) => <ElementListStandard
                            key={index}
                            elemento={el}
                        />)}
                    </ListActivityTime>
                    <ListActivityTime
    
                        nameList="Prossimi"
                        isDate={eventNext[0] ? `${eventNext[0].date.slice(8, 10)} ${ricercaMeseNumero(eventNext[0].date.slice(5, 7)).slice(0, 3)}`
                            :
                            ""}
    
                    >   {eventNext.map((el, index) => <ElementListStandard
                        key={index}
                        elemento={el}
                    />)}
    
                    </ListActivityTime>
    
    
                     */
                }

                {
                    /*
                    <ListActivityTime
                        list={ticket}
                        nameList="Ticket aperti"
                        elementList={ElementListToSchedule}
                    >
                        {ticket.map((el, index) => <ElementListToSchedule
                            key={index}
                            id={el.id}
                            backgroundColor={el.backgroundColor}
                            title={el.title}
                            idMacchinario={el.idMacchina}
                            nomeResponsabile={el.operatore}
                            note={el.note}
                            machineLocation={"america"}
                            MachineBusinessUnit={"laminazione"}
                        />)}
                    </ListActivityTime>
                    */
                }
                <ListActivityTime

                    nameList={`${selectedDayCalendar.slice(8, 10)} ${ricercaMeseNumero(selectedDayCalendar.slice(5, 7))}`}
                    elementList={ElementListToSchedule}
                >


                    {
                        virtualEventPeriodic.map((el, index) => <ElementListStandard
                            key={index}
                            elemento={el}
                        />)
                    }

                    {
                        eventdaySelected.map((el, index) => {


                            if (el.id.slice(0, 7) === "virtual") {
                                return <ElementListStandard
                                    key={index}
                                    elemento={el}
                                />
                            } else {
                                if (el.attivita) {
                                    if (el.attivita[0].id) {
                                        //ci troviamo nel caso in cui l'intervento ha delle attività e queste possono essere complete o meno
                                        //nel caso in cui è false possiamo avere due casi o non ha trovato un elementi 
                                        if (el.attivita.find(e => e.complated === false)) {

                                            //esiste almeno un attività falsa

                                            if (el.attivita.find(e => e.complated === true)) {
                                                return <ElementListReportIncomplete
                                                    key={index}
                                                    report={el}

                                                />
                                            } else {
                                                return <ElementListStandard
                                                    key={index}
                                                    elemento={el}
                                                />
                                            }

                                        } else {


                                            //tutte le attività sono complete allora è stato completato
                                            return <ElementListReportComplete
                                                key={index}
                                                report={el}
                                            />
                                        }
                                    } else {
                                        //un intervento che ha un unica attività senza la variabile id allora è un intervento senza attività che possiede un report e di conseguenza è un report completo

                                        if (!el.attivita[0].complated) {
                                            return <ElementListStandard
                                                key={index}
                                                elemento={el}
                                            />
                                        } else {
                                            return <ElementListReportComplete
                                                key={index}
                                                report={el}
                                            />
                                        }

                                    }
                                } else {
                                    //è un intervento che non possiede attività e che non ha un report collegato
                                    return <ElementListStandard
                                        key={index}
                                        elemento={el}
                                    />
                                }
                            }


                        }
                        )
                    }


                    {
                        //report ancora da concludere
                        /*
                        reportIncomplete.map((el, index) => <ElementListReportIncomplete
                            key={index}
                            report={el}

                        />)
                        */
                    }

                    {
                        //report conclusi
                        /*
                        reportComplete.map(
                            (el, index) => <ElementListReportComplete
                                key={index}
                                report={el}
                            />)
                            */
                    }


                </ListActivityTime>
            </div>
        </div>
    )
}


export default PersListTimeEvent