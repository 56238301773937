import { createBrowserRouter } from 'react-router-dom'
import { pathOverview, pathAlertView, pathExplorPlant, pathImpostazioni, pathNotification, pathPlanninng, pathMachineryFaultList, pathMachineryDocumentation, pathMachineryIntervention, pathMetriche } from '../../configurations/setting'
import ErrorPage from '../../structures/root/errorPage';
import Root from '../../structures/root/root';
import ResearchMachine from '../../components/researchMachine/mainResearchMachine';
import Overview from '../../structures/overview/mainOverview';
import AlertViews from '../../structures/alertView/mainAlertView';
import Planning from '../../structures/planning/mainPlanning';
import SchedeExplorePlant from '../../structures/schedeExplorePlant/mainSchedereExplorePlant';
import MachineDetail from '../../structures/machineDetail/mainMachineDetail';
import SynopticPlant from '../../components/synopticPlant/mainSynopticPlant';
import MachineFaultList from '../../components/machineFaultList/mainMachineFaultList';
import MachineDocument from '../../components/machineDocument/mainMachineDocument';
import MachineryInterventions from '../../components/machineryInterventions/mainMachineryInterventions';
import Login from '../../components/login/mainLogin';
import MainMultiDonut from '../../components/multipleVariableDonut/mainMultiDonut';
import ProvaResponsive from '../../components/multipleVariableDonut/provaResponsive';
import TestModificaDiUnDato from '../../componentiTest/bottoneModificaIntervento/bottoneModifica';
import Metriche from '../metriche/mainMetriche';

export const router = createBrowserRouter([

    {
        path: "/",
        element: <Root></Root>,
        errorElement: <ErrorPage></ErrorPage>,
        children: [

            {
                path: `${pathOverview}`,
                element: <Overview />
            },
            {
                path: `${pathAlertView}`,
                element: <AlertViews />
            },
            {
                path: `${pathExplorPlant}`,
                element: <SchedeExplorePlant />,
                children: [
                    {
                        path: `new`,
                        element: <ResearchMachine />
                    },
                    {
                        path: ``,
                        element: <ResearchMachine />
                    },
                    {
                        path: `:id`,
                        element: <MachineDetail />,
                        children: [
                            {
                                path: `${pathMachineryFaultList}`,
                                element: <MachineFaultList />
                            },
                            {
                                path: `${pathMachineryDocumentation}`,
                                element: <MachineDocument />
                            },
                            {
                                path: `${pathMachineryIntervention}`,
                                element: <MachineryInterventions />
                            }
                        ]
                    }
                ]
            },
            {
                path: `${pathPlanninng}`,
                element: <Planning />
            },
            {
                path: `${pathMetriche}`,
                element: <Metriche />
            },

            {
                path: `${pathNotification}`,
                element: <h1>Work in progress</h1>
            }
            ,

            {
                path: `${pathImpostazioni}`,
                element: <span></span>
            },

        ]
    }

])