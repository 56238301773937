import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {Amplify, API} from 'aws-amplify'
import {GRAPHQL_AUTH_MODE} from '@aws-amplify/api'
import awsconfig from '../../../aws-exports';
import {createBudgets, deleteBudgets, deleteLineas, updateBudgets} from '../../../graphql/mutations'
import {listBudgets} from '../../../graphql/queries'
import * as subscriptions from "../../../graphql/subscriptions";
import {updateOneMachinery} from "./listMachinerySlice";
import {updateOnePlant} from "./plantSlice";
import {deleteOneLine, newLine, updateLine} from "./listLineSlice";
import {deleteDepartment} from "./listDepartmentSlice";

Amplify.configure(awsconfig);


const listBudgetAdapter = createEntityAdapter({
    selectId: (listBudgets) => listBudgets.id
})
export const subscribeCreateBudget = createAsyncThunk(
    'listBudgets/subscriptionCreateBudget',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onCreateBudgets,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({

                next: ({provider, value}) => {
                    dispatch(addOneBudget({
                        id: value.data.onCreateBudgets.id,
                        idStructure: value.data.onCreateBudgets.idStructure,
                        year: value.data.onCreateBudgets.year,
                        value: value.data.onCreateBudgets.value,
                        createdAt: value.data.onCreateBudgets.createdAt,
                        updatedAt: value.data.onCreateBudgets.updatedAt,
                    }))
                },
                error: (error) => console.warn(error)
            })

        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        //return subscription;
    }
);
export const subscribeDeleteBudget = createAsyncThunk(
    'listBudgets/subscriptionDeleteBudget',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onDeleteBudgets,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({ provider, value }) => {

                    dispatch(deleteOneBudget(value.data.onDeleteBudgets.id))
                },
                error: (error) => console.warn(error)
            })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        //return subscription;
    }
);

export const subscribeModifyBudget = createAsyncThunk(
    'listBudgets/subscriptionCreateBudget',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onUpdateBudgets,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({ provider, value }) => {

                    dispatch(
                        updateOneBudget({
                            id:value.data.onUpdateBudgets.id,
                            idStructure: value.data.onUpdateBudgets.idStructure,
                            year: value.data.onUpdateBudgets.year,
                            value: value.data.onUpdateBudgets.value,
                            createdAt: value.data.onUpdateBudgets.createdAt,
                            updatedAt: value.data.onUpdateBudgets.updatedAt,

                        })
                    )
                },
                error: (error) => console.warn(error)
            })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
       // return subscription;
    }
);
export const newBudget = createAsyncThunk('listBudgets/putBudget', async ({idStructure, year, value}) => {
        const response = await API.graphql({
            query: createBudgets,
            variables: {
                input: {
                    idStructure,
                    year,
                    value
                }
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        })
        //return response
        return {
            id: response.data.createBudgets.id,
            idStructure: response.data.createBudgets.idStructure,
            year: response.data.createBudgets.year,
            value: response.data.createBudgets.value,
            createdAt: response.data.createBudgets.createdAt,
            updatedAt: response.data.createBudgets.updatedAt,
        }
    }
)
export const updateBudget = createAsyncThunk('listBudgets/updateBudget', async ({id, year, value,idStructure}) => {
        const response = await API.graphql({
            query: updateBudgets,
            variables: {
                input: {
                    id,
                    year,
                    value,
                    idStructure
                }
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        })
        //return response
        return {
            id: response.data.updateBudgets.id,
            idStructure: response.data.updateBudgets.idStructure,
            year: response.data.updateBudgets.year,
            value: response.data.updateBudgets.value,
            createdAt: response.data.updateBudgets.createdAt,
            updatedAt: response.data.updateBudgets.updatedAt,
        }
    }
)
export const deleteBudget = createAsyncThunk("listBudgets/deleteBudget", async ({id,idStructure}) => {

    const response = await API.graphql({
        query: deleteBudgets,
        variables: {
            input: {id: id,idStructure:idStructure}
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })
    return response.data.deleteBudgets.id
})
export const getUpdateBudget = createAsyncThunk('listBudgets/getUpdateBudget', async (a, {getState}) => {

    const response = await API.graphql({
            query: listBudgets,
            variables: {},
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        }
    )


    return response.data.listBudgets.items.map(el => {
        return {
            id: el.id,
            idStructure: el.idStructure,
            year: el.year,
            value: el.value,
            createdAt: el.createdAt,
            updatedAt: el.updatedAt
        }
    })

})


const listBudgetSlice = createSlice({
    name: "listBudgets",
    initialState: listBudgetAdapter.getInitialState(),
    reducers: {
        addBudget: listBudgetAdapter.upsertMany,
        addOneBudget: listBudgetAdapter.upsertOne,
        updateOneBudget: listBudgetAdapter.upsertOne,
        deleteOneBudget: listBudgetAdapter.removeOne
    },
    extraReducers: bulder => {
        bulder
            .addCase(newBudget.fulfilled, listBudgetAdapter.upsertOne)
            .addCase(newBudget.rejected, (state, action) => console.log(state, action))
            .addCase(updateBudget.fulfilled, listBudgetAdapter.upsertOne)
            .addCase(updateBudget.rejected, (state, action) => console.log(state, action))
            .addCase(deleteBudget.fulfilled, listBudgetAdapter.removeOne)
            .addCase(deleteBudget.rejected, (state, action) => console.log(state, action))
            .addCase(getUpdateBudget.fulfilled, listBudgetAdapter.upsertMany)
            .addCase(getUpdateBudget.rejected, (state, action) => console.log(state, action))
    }
})

export default listBudgetSlice.reducer

export const {
    addBudget,
    addOneBudget,
    updateOneBudget,
    deleteOneBudget
} = listBudgetSlice.actions

export const {selectAll: selectAllBudget} = listBudgetAdapter.getSelectors(state => state.listBudgets)



