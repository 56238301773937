import { createSlice, createEntityAdapter, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { getMachineSelected } from './schedeMachineDetailSlice';
import { newDataTargaAssets } from './datiTargaMachinerySlice';
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import {createAssets, deleteDepartments, deleteLineas, updateLineas} from '../../../graphql/mutations'
import {listAssets, listLineas} from '../../../graphql/queries'
import { Amplify } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import { createLineas } from '../../../graphql/mutations'
import * as subscriptions from "../../../graphql/subscriptions";
import {onDeleteLineas} from "../../../graphql/subscriptions";
import {
    addOneDepartment,
    deleteDepartment,
    newDepartment,
    updateDepartment,
    updateOneDepartment
} from "./listDepartmentSlice";
Amplify.configure(awsconfig);


const listLineAdapter = createEntityAdapter({
    selectId: (listLine) => listLine.id
})

export const subscribeCreateLine = createAsyncThunk(
    'listLine/subscriptionCreateLine',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onCreateLineas,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({provider, value}) => {

                    dispatch(addOneLine({
                        id: value.data.onCreateLineas.id,
                        name: value.data.onCreateLineas.name,
                        idDepartment: value.data.onCreateLineas.idDepartment,
                        createdAt: value.data.onCreateLineas.createdAt,
                        updatedAt: value.data.onCreateLineas.updatedAt,
                    }))
                },
                error: (error) => console.warn(error)
            })

        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        // return subscription;
    }
);
export const subscribeModifyLine = createAsyncThunk(
    'listLine/subscriptionModifyLine',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onUpdateLineas,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({ provider, value }) => {
                    //console.log("dati della subscription MODIFY PLANT", value, provider)

                    dispatch(
                        updateOneLine({
                            id:value.data.onUpdateLineas.id,
                            // changes:{
                            name: value.data.onUpdateLineas.name,
                            idDepartment: value.data.onUpdateLineas.idDepartment,
                            createdAt: value.data.onUpdateLineas.createdAt,
                            updatedAt: value.data.onUpdateLineas.updatedAt,
                            // }
                        })
                    )
                },
                error: (error) => console.warn(error)
            })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        // return subscription;
    }
);
export const subscribeDeleteLine = createAsyncThunk(
    'listLine/subscriptionDeleteLine',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onDeleteLineas,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({ provider, value }) => {

                    dispatch(deleteOneLine(value.data.onDeleteLineas.id))
                },
                error: (error) => console.warn(error)
            })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        // return subscription;
    }
);
export const newLine = createAsyncThunk('listLine/putLine', async ({ name, idDepartment }) => {
        const response = await API.graphql({
            query: createLineas,
            variables: {
                input: {
                    name,
                    idDepartment,
                }
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        })
        //return response
        return {
            id: response.data.createLineas.id,
            name: response.data.createLineas.name,
            idDepartment: response.data.createLineas.idDepartment,
            createdAt: response.data.createLineas.createdAt,
            updatedAt: response.data.createLineas.updatedAt,
        }
    }
)
export const updateLine = createAsyncThunk('listLine/updateLine', async ({ id,name, idDepartment }) => {
        const response = await API.graphql({
            query: updateLineas,
            variables: {
                input: {
                    id,
                    name,
                    idDepartment,
                }
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        })
        //return response
        return {
            id: response.data.updateLineas.id,
            name: response.data.updateLineas.name,
            idDepartment: response.data.updateLineas.idDepartment,
            createdAt: response.data.updateLineas.createdAt,
            updatedAt: response.data.updateLineas.updatedAt,
        }
    }
)
export const deleteLine = createAsyncThunk("listLine/deleteLine", async ({id}) => {

    const response = await API.graphql({
        query: deleteLineas,
        variables: {
            input: {id: id}
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })
    return response.data.deleteLineas.id
})
export const getUpdateLines = createAsyncThunk('listLine/getUpdateLines', async (a, { getState }) => {

    const response = await API.graphql({
            query: listLineas,
            variables: {
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        }
    )


    return response.data.listLineas.items.map(el => {
        return {
            id: el.id,
            name: el.name,
            idDepartment: el.idDepartment,
            createdAt: el.createdAt,
            updatedAt: el.updatedAt
        }
    })

})


const listLineSlice = createSlice({
    name: "listLine",
    initialState: listLineAdapter.getInitialState(),
    reducers: {
        addLine: listLineAdapter.upsertMany,
        addOneLine: listLineAdapter.upsertOne,
        deleteOneLine: listLineAdapter.removeOne,
        updateOneLine: listLineAdapter.upsertOne,
    },
    extraReducers: bulder => {
        bulder
            .addCase(newLine.fulfilled, listLineAdapter.upsertOne)
            .addCase(newLine.rejected, (state, action) => console.log(state, action))
            .addCase(updateLine.fulfilled, listLineAdapter.upsertOne)
            .addCase(updateLine.rejected, (state, action) => console.log(state, action))
            .addCase(deleteDepartment.fulfilled, listLineAdapter.removeOne)
            .addCase(deleteDepartment.rejected, (state, action) => console.log(state, action))
            .addCase(getUpdateLines.fulfilled, listLineAdapter.upsertMany)
            .addCase(getUpdateLines.rejected, (state, action) => console.log(state, action))
    }
})

export default listLineSlice.reducer

export const {
    addLine,
    addOneLine,
    deleteOneLine,
    updateOneLine
} = listLineSlice.actions

export const { selectAll: selectAllLine } = listLineAdapter.getSelectors(state => state.listLine)



