import React from "react";
import MainLoadDonutChart from "../../loadDonatChart/mainLoadDonutChart";
import { titleColor } from '../../../configurations/generalStye';
import { unicoAttivo, sonoUguali } from '../../../functions/controlloVettori'
import MainMultiDonut from "../../multipleVariableDonut/mainMultiDonut";

export default class DesktopTripleDonut extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            buttoActive: [true, false, false],
            value1: props.value1,
            value2: props.value2,
            value3: props.value3
        }
    }





    componentDidUpdate(prevProps, prevState) {

        if (!(sonoUguali(prevState.buttoActive, this.state.buttoActive))) {

            this.props.funzioneCambioStato(this.props.plant.id, this.state.buttoActive.findIndex(el => el === true))
        }
        if (prevProps.value1 !== this.props.value1 || prevProps.value2 !== this.props.value2 || prevProps.value3 !== this.props.value3) {
            this.setState({
                value1: this.props.value1,
                value2: this.props.value2,
                value3: this.props.value3
            })
        }

        if (prevProps.plant?.id !== this.props.plant?.id) {
            this.props.funzioneCambioStato(this.props.plant.id, this.state.buttoActive.findIndex(el => el === true))
        }
    }



    render() {
        return (
            <div className="bodyTripleDonut">
                <div className="titleTripleDonut">
                    <div>
                        <h3
                            className="elementTitleTripleDonut"
                            style={{ color: `${titleColor}` }}
                        >
                            Overview {this.props.plant?.nome}
                        </h3>
                    </div>
                    <div className="bodyButtonTripleDonut">
                        <button
                            className={`buttonTripleDonutLeft ${this.state.buttoActive[0] ? "activeButton" : ""}`}
                            onClick={() => this.setState({ buttoActive: unicoAttivo(0, 3) })}                        >
                            week
                        </button>
                        <button
                            className={`buttonTripleDonutCenter ${this.state.buttoActive[1] ? "activeButton" : ""}`}
                            onClick={() => this.setState({ buttoActive: unicoAttivo(1, 3) })}
                        >
                            month
                        </button>
                        <button
                            className={`buttonTripleDonutRight ${this.state.buttoActive[2] ? "activeButton" : ""}`}
                            onClick={() => this.setState({ buttoActive: unicoAttivo(2, 3) })}

                        >
                            year
                        </button>



                    </div>
                </div>
                <div className="singleDonut">


                    <div className="singleMultipleDonut">
                        <h4 className="elementTitleSingleDonut" >{this.props.title1}</h4>
                        <MainMultiDonut
                            val1={20}


                            threshold1={[21, 84]}

                            colorCircle1={['#99CBFF', '#FF00FF', '#EE82EE']}

                            fontSizeTitle={'0.8vw'}
                            fontSizeValue={'1vw'}

                        />
                    </div>
                    <div className="singleMultipleDonut">
                        <h4 className="elementTitleSingleDonut">{this.props.title2}</h4>

                        <MainMultiDonut
                            val1={this.state.value2}
                            //labelsCenter={[this.props.title2]}
                            fontSizeTitle={'0.8vw'}
                            fontSizeValue={'1vw'}
                        />
                    </div>

                    <div className="singleMultipleDonut">
                        <h4 className="elementTitleSingleDonut" >{this.props.title3}</h4>

                        <MainMultiDonut
                            val1={this.state.value3}

                            //labelsCenter={[this.props.title3, 'ciao']}
                            fontSizeTitle={'0.8vw'}
                            fontSizeValue={'1vw'}

                        />
                    </div>


                </div>
            </div>
        )
    }
}