export const getPeriodicInterventions = ( listIntervention ) => {
    return listIntervention.filter(el => el.period !== "" && el.period !== 0 && el.period !== "0" && !el.isDeleted)
}


export const getFirstLevelActuative = ( listIntervention =[], listPeriodic, dateStart = "2024-01-01", dateEnd = "2024-12-31" ) => {
    
    return listIntervention
        .filter(el =>
            listPeriodic
                .find(e =>
                    //filtro gli interventi che hanno come idPrev l'id del periodico
                    e.id === el.idPrevIntervention

                ))
        .filter(el =>
            //filtro gli interventi che sono stati definiti in un periodo specifico 
            el.date >= dateStart
            &&
            el.date <= dateEnd)
}


export const getDerivatesInterForAPeriod = ( listPeriodic, listFirstLevelActuative = [], listIntervention ) => {


    let isAddList = true
    let counterIteration = 0
    let completeList = [listPeriodic]
    //questa operazione estrai i primi interventi direti dai periodici che sono stati impostati nel periodiodo indicato



    completeList.push(listFirstLevelActuative)


    while (isAddList) {
        counterIteration = counterIteration + 1
        const listTemp = listIntervention.filter(el => completeList[completeList.length - 1].find(e => e.id === el.idPrevIntervention))

        if (listTemp.length === 0) {
            isAddList = false
        } else {
            completeList.push(listTemp)
        }

        //blocco di controllo per evitare  un loop
        if (counterIteration > 20) {
            isAddList = false
        }

    }

    let finalList = []
    return completeList.flat(3)
}
