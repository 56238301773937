export const OTDForPeriodicIntervention = (listPeriodicIntervention, listIntervention, listActuative, listReport) => {
    return listPeriodicIntervention
        .map(intPer => {
            //interventi che derivano dal periodico eseguiti nell'anno corrente
            const listInterventFromOnePeriodic = listActuative
                .filter(el => el.idPrevIntervention === intPer.id )

            const listIntDelay = listInterventFromOnePeriodic
                .map(att => {
                    //caso base report collegato rdirettamente all'intervento attuativo
                    const repDiretti = listReport.filter(rep => rep.idIntervention === att.id)

                    if (repDiretti.length !== 0) {
                        // esiste almeno un report collegato all'attuativo 
                        //ordiniamoli per dataIntervento in questo modo abbiamo che il primo report della lista è anche quello con la dataIntervento più vecchia
                        const repSort = repDiretti.sort((a, b) => a.dataIntervento.localeCompare(b.dataIntervento))

                        //calcolo del ritardo
                        const millInterv = new Date(att.date).getTime()
                        const millRep = new Date(repSort[0].dataIntervento).getTime()
                        const dayDelay = millRep - millInterv

                        if (dayDelay < 0) {
                            return {
                                idGen: intPer.id,
                                id: att.id,
                                time: 0
                            }
                        } else {
                            return {
                                idGen: intPer.id,
                                id: att.id,
                                time: Math.round(dayDelay / 86400000)
                            }
                        }
                    } else {
                        //essendo find se non esiste un intervento successivo allora ritorna undefined
                        let interSucc = listIntervention.find(el => el.idPrevIntervention === att.id)
                        while (interSucc) {
                            const repDirettiSucc = listReport.filter(rep => rep.idIntervention === interSucc.id)
                            if (repDirettiSucc.length !== 0) {
                                const repSort = repDirettiSucc.sort((a, b) => a.dataIntervento.localeCompare(b.dataIntervento))

                                //calcolo del ritardo
                                const millInterv = new Date(att.date).getTime()
                                const millRep = new Date(repSort[0].dataIntervento).getTime()
                                const dayDelay = millRep - millInterv
                                // se il ritardo è negativo, cioè l'intervento è stato eseguito prima della data allora il valore di ritardo è 0
                                if (dayDelay < 0) {
                                    return {
                                        idGen: intPer.id,
                                        id: att.id,
                                        time: 0
                                    }
                                } else {
                                    return {
                                        idGen: intPer.id,
                                        id: att.id,
                                        time: Math.round(dayDelay / 86400000)
                                    }
                                }
                            } else {
                                interSucc = listIntervention.find(el => el.idPrevIntervention === interSucc.id)
                            }

                        }
                        /*
                        SE ESCO DAL WHILE SENZA RITORNARE NULLA ALLORA VUOL DIRE CHE NON CI SONO REPORT
                        return{
                            info:"riportata fuori dal wile"
                        }
                        */
                    }
                })



            let nInterventi = 0
            let sommaRapportiRitardi = 0
            listIntDelay.forEach(rit => {
                // escludiamo gli elementi della lista undefined poiché sono interventi che non hanno un report e quindi non rientrano della lista dei calcoli
                if (rit !== undefined) {
                    //se il ritardo è minore del periodo la metrica genera un valore maggiore di zero e quindi inseriamo il valore nella sommatoria
                    if (rit.time < intPer.period) {
                        sommaRapportiRitardi = sommaRapportiRitardi + ((intPer.period - rit.time) / intPer.period)
                        nInterventi = nInterventi + 1
                    } else {
                        //altrimenti aggiungiamo l'intervento nella metrica ma con un valore uguale a zero che si traduce con l'aggiunta di un elemento di valore 0
                        nInterventi = nInterventi + 1
                    }
                }
            })

            return {
                id: intPer.id,
                rapporto: Math.round((sommaRapportiRitardi / nInterventi) * 100)
            }


        })
}