import './mainMenu.css'
import Logo from '../../components/logo/mainLogo'
import BarMachine from '../../components/barMachine/mainBarMachine';
import Notification from '../../components/notification/mainNotification';
import UserIcon from '../../components/userIcon/mainUserIcon';
import iconaUtente from '../../icon/UserLogo.png'
import { useDispatch } from 'react-redux';
import {
    getlistPlants,
    subscribeCreatePlant,
    subscribeDeletePlant,
    subscribeModifyPlant
} from '../root/slices/plantSlice';
import { useEffect } from 'react';
import {
    getUpdateDepartments,
    subscribeCreateDepartment,
    subscribeDeleteDepartment,
    subscribeModifyDepartment
} from "../root/slices/listDepartmentSlice";
import {
    getUpdateBudget,
    subscribeCreateBudget,
    subscribeDeleteBudget,
    subscribeModifyBudget
} from '../root/slices/budgetSlice'
import {
    subscribeCreateDataIntervention,
    subscribeCreateIntervention,
    subscribeModifyDataIntervention,
    subscribeModifyIntervention
} from '../root/slices/interventionSlice';
import {
    subscribeCreateAsset,
    subscribeDeleteAsset,
    subscribeModifyAsset
} from '../root/slices/listMachinerySlice';
import {
    subscribeCreateLine,
    subscribeDeleteLine,
    subscribeModifyLine
} from "../root/slices/listLineSlice";
import {
    getDatiTargaAsset,
    getlistDatiTargaAsse,
    getlistDatiTargaAssets,
    subscribeCreateDataTargaAssets,
    subscribeDeleteDataTargaAssets,
    subscribeUpdateDataTargaAssets
} from "../root/slices/datiTargaMachinerySlice";
import {
    subscribeCrteateMainteneanceReport,
    subscribeModifyMainteneanceReport
} from "../root/slices/reportSlice";


const Menu = () => {


    const dispatch = useDispatch()
    useEffect(() => {

        /**
         * CON L'AGGIORNAMENTO DEI NUOVI IMPIANTI BISOGNA SCARICARE ANCHE I DATI AGGIORNATI PER GLI IMPIANTI
         */
        dispatch(getlistPlants())
        dispatch(getUpdateDepartments())
        dispatch(getUpdateBudget())
        dispatch(getlistDatiTargaAssets())

        //INTERVENTI E REPORT SUBSCRIPTION
        dispatch(subscribeCreateIntervention())
        dispatch(subscribeModifyIntervention())
        dispatch(subscribeCreateDataIntervention())
        dispatch(subscribeModifyDataIntervention())

        dispatch(subscribeCrteateMainteneanceReport())
        dispatch(subscribeModifyMainteneanceReport())
        //PLANT DEPARTMENT LINE ASSET SUBSCRIPTION
        //CREATE SUBSCRIPTION
        dispatch(subscribeCreateBudget())
        dispatch(subscribeCreatePlant())
        dispatch(subscribeCreateDepartment())
        dispatch(subscribeCreateLine())
        dispatch(subscribeCreateAsset())
        dispatch(subscribeCreateDataTargaAssets())
        //DELETE SUBSCRIPTION
        dispatch(subscribeDeletePlant())
        dispatch(subscribeDeleteDepartment())
        dispatch(subscribeDeleteLine())
        dispatch(subscribeDeleteAsset())
        dispatch(subscribeDeleteBudget())
        dispatch(subscribeDeleteDataTargaAssets())
        //MODIFY SUBSCRIPTION
        dispatch(subscribeModifyBudget())
        dispatch(subscribeModifyPlant())
        dispatch(subscribeModifyDepartment())
        dispatch(subscribeModifyLine())
        dispatch(subscribeModifyAsset())
        dispatch(subscribeUpdateDataTargaAssets())

        //console.log("vediamo la sottoscrizione che ci dice",subscriptionCreateInterventionData.then(sub=>console.log(sub)))
        //subscriptionCreateInterventionData.then(sub=>console.log("dati della sottoscrizione",sub))
        // Clean up subscription on component unmount
        return () => {
            //subscriptionCreateInterventionData.then((sub) => sub.unsubscribe());
            //subscribeModifyInterventionData.then((sub) => sub.unsubscribe());
        };
    }, [dispatch])



    return (
        <div
            className='mainMenuStylGeneral'
        >
            <div
                className='menuPrimaParteSinistra'
            >
                <Logo />
                {/**
                 * <MainRicercaElemento
                 dimDesktop={this.props.dimDesktop ? this.props.dimDesktop : 1201}
                 logo={this.props.logo}
                 funzioneSetStateFromInput={(inp) => this.setState({
                 testoRicerca: inp
                 })}
                 dataList={[

                 {dato1:"dato1", dato2:"dato2", dato3:"dato3"},
                 {dato1:"dato1", dato2:"dato2", dato3:"dato3"}
                 ]}
                 />
                 */


                }

                <BarMachine

                    ValoreAttuale={2}
                    ValoreMassimo={7}
                />



            </div>
            <div
                className='menuPrimaParteDestra'
            >

                <Notification />

                <UserIcon

                    logo={iconaUtente}
                    nomeUtente="Fiorentino Giusi"
                    ruolo="Administrator"
                />

            </div>
        </div>
    )

}

export default Menu