
import { useDispatch, useSelector } from "react-redux"
import { selectPlant } from "../../../structures/root/slices/plantSlice"
import { selectAllMachinery } from "../../../structures/root/slices/listMachinerySlice"
import { useEffect, useState } from "react"
import { selectUser } from "../../../structures/root/slices/datiUtenteSlice"
import { newReport } from "../../../structures/root/slices/reportSlice"
import { newIntervention, createDataIntervention } from "../../../structures/root/slices/interventionSlice"
import Modal from "../../DialogModal/mainDialogModal"
import MainMainteneanceStory from "../../mainteneanceStory/mainManteneanceStory"
import { dataFormatoGMA } from "../../../functions/dateTime"
import { selectAllDepartment } from "../../../structures/root/slices/listDepartmentSlice"
import { selectAllLine } from "../../../structures/root/slices/listLineSlice"
import { pointToeconomicalValue } from "../../../functions/generiche"


const FormCreateMaintenance = ({ datiIntervento, closeScheda, isOpen }) => {


    const listaImpianti = useSelector(selectPlant)
    const listaMacchine = useSelector(selectAllMachinery)
    const utente = useSelector(selectUser)
    const dispatch = useDispatch()

    const dataOdierna = new Date()


    const [dataOperazione, setDataOperazione] = useState("")
    //operazione al momento non viene utilizzata come parametro
    const [operazione, setOperazione] = useState("")
    const [tempo, setTempo] = useState("")
    const [valoriLettura, setValoriLettura] = useState("")
    const [costo, setCosto] = useState("")
    const [note, setNote] = useState("")

    //DA VEDERE CON IL NUOVO AGGIORNAMENTO DOUTO ALLA SEPARAZIONE DEI REPORT
    const [attivitaRisolte, setAttivitaRisolte] = useState([])
    const [tutteAttivitaComplete, setTutteAttivitaComplete] = useState(false)



    const [isOpenStorico, setOpenStorico] = useState(false)
    const [isOpenRiprogramma, setOpenRiprogramma] = useState(false)

    const [dataRiprogrammazione, setDataRiprog] = useState("")

    const macchina = listaMacchine.find(el => el.id === datiIntervento?.idMachinery)
    const impianto = listaImpianti.find(el => el.id === datiIntervento?.idPlant)

    const [depart, setDepart] = useState({})
    const [linea, setLinea] = useState()
    const dataDepartment = useSelector(selectAllDepartment)
    const dataLinee = useSelector(selectAllLine)



    useEffect(() => {
        setLinea(dataLinee.find(el => el.id === macchina?.idLinea))

    }, [datiIntervento])


    useEffect(() => {
        if (linea) { setDepart(dataDepartment.find(el => el.id === linea.idDepartment)) }
    }, [linea])


    useEffect(() => {
        /**
         * verifica delle attività risolte e blocco dell'attività nel nuovo report
         */
        if (datiIntervento) {
            /**
             * verifico la presenza di attività
             */



            if (datiIntervento.attivita) {

                if (datiIntervento.attivita[0]?.id) {
                    /**
                     * funzione per la definizione di attività da completare e renderle bloccanti in front end
                     */

                    setAttivitaRisolte(datiIntervento.attivita.map(el => {

                        return {
                            ...el,
                            mod: !el.complated
                        }
                    }))


                }

            }

        }

    }, [datiIntervento])


    useEffect(() => {
        if (attivitaRisolte.find(el => {

            return el.complated === false
        })) {
            setTutteAttivitaComplete(false)
        } else {
            setTutteAttivitaComplete(true)

        }

        if (attivitaRisolte.length !== 0) {
            let timeAll = 0

            attivitaRisolte.forEach(el => {
                if (el.timeEff && el.complated === true && el.mod === true) {
                    timeAll = timeAll + el.timeEff
                }
            })
            if (timeAll !== 0) {
                setTempo(timeAll)
            } else {
                setTempo("")
            }

        }


    }, [attivitaRisolte])

    useEffect(() => {


    }, [tutteAttivitaComplete])

    //REFRESH AUTOMATICO QUANDO SI TROVANO SULLA STESSA MODIFICA





    if (isOpenStorico) {
        return <MainMainteneanceStory
            datiIntervento={datiIntervento}
            closeScheda={() => setOpenStorico(el => !el)}
            isOpen={!isOpenStorico}
        />
    } else {

        return <Modal
            show={!isOpen}
        >
            <div className="bodyMainReportMainteneanceForm">
                <div
                    className="boxTitleReportMainteneanceForm"
                >
                    <h4
                        className="elementTitleReportMainteneanceFrom"
                    >{isOpenRiprogramma ? "Riprogramma intervento" : "Report intervento"}</h4>
                    <h3
                        className="elementClosignSchedaReportMainteneanceForm"
                        onClick={() => closeScheda()}
                    >x</h3>
                </div>
                <div
                    className="boxTitleReportMainteneanceForm"
                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Intervento</h5>
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"

                    >{datiIntervento?.title}{/*datiIntervento.title.length>100?"...":""*/}</h5>
                </div>

                <div
                    className="boxTitleReportMainteneanceForm"
                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Impianto</h5>
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"

                    >{impianto ? impianto.nome : "non trovato"}</h5>
                </div>
                <div
                    className="boxTitleReportMainteneanceForm"
                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Reparto</h5>
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"

                    >{depart ? depart.name : "non trovato"}</h5>
                </div>
                <div
                    className="boxTitleReportMainteneanceForm"
                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Linea</h5>
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"

                    >{linea ? linea.name : "non trovato"}</h5>
                </div>
                <div
                    className="boxTitleReportMainteneanceForm"
                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Tipo macchina</h5>
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"

                    >{macchina ? macchina.familyId : "non trovato"}</h5>
                </div>
                <div
                    className="boxTitleReportMainteneanceForm"
                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Nome macchina</h5>
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"

                    >{macchina ? macchina.nome : "non trovato"}</h5>
                </div>
                <div
                    className="boxTitleReportMainteneanceForm"
                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Id macchina</h5>
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"

                    >{macchina ? macchina.identityCode : "non trovato"}</h5>
                </div>
                <div
                    className="boxTitleReportMainteneanceForm"
                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Data intervento</h5>
                    {
                        isOpenRiprogramma ?
                            <input
                                className="elementInputFormReport"
                                type="date"
                                required
                                onInput={(e) => setDataRiprog(e.target.value)}
                            />
                            :
                            datiIntervento ?
                                <h5
                                    className="elementSecondaryTitleReportMainteneanceForm"
                                >
                                    {dataFormatoGMA(datiIntervento.date)}
                                </h5>
                                :
                                ""

                    }
                </div>
                <div
                    className="boxTitleReportMainteneanceForm elementNoteReportMaintenanceForm"

                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Note</h5>
                    <span
                        className="elementNoteTextReportMainteneanceForm"
                    >
                        {datiIntervento?.note}
                    </span>
                </div>

                <div
                    className="boxTitleReportMainteneanceForm"
                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Data operazione</h5>
                    {
                        isOpenRiprogramma ?
                            <input
                                className="elementInputFormReport"
                                type="date"

                                disabled
                                required
                                onInput={(e) => setDataOperazione(e.target.value)}
                            /> :
                            <input
                                className="elementInputFormReport"
                                type="date"
                                max={`${dataOdierna.getFullYear()}-${dataOdierna.getMonth() + 1 < 10 ? `0${dataOdierna.getMonth() + 1}` : `${dataOdierna.getMonth() + 1}`}-${dataOdierna.getDate() < 10 ? `0${dataOdierna.getDate()}` : `${dataOdierna.getDate()}`}`}
                                required
                                onInput={(e) => setDataOperazione(e.target.value)}
                            />
                    }

                </div>
                {
                    /* 
                    <div
                    className="boxTitleReportMainteneanceForm"
                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Operazione effettuata</h5>
                    <input
                        className="elementInputFormReport"
                        type="text"
                        placeholder=""
                        onInput={(e) => setOperazione(e.target.value)}
                    />
                </div>
                    */
                }


                <div
                    className="boxTitleReportMainteneanceForm"
                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Valori di lettura</h5>
                    {
                        isOpenRiprogramma ?

                            <input
                                className="elementInputFormReport"
                                type="text"
                                disabled
                                placeholder=""
                                onInput={(e) => setValoriLettura(e.target.value)}
                            />
                            :
                            <input
                                className="elementInputFormReport"
                                type="text"
                                placeholder=""
                                onInput={(e) => setValoriLettura(e.target.value)}
                            />
                    }
                </div>
                <div
                    className="boxTitleReportMainteneanceForm"
                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Costi pezzi ricambio (EUR)</h5>
                    {isOpenRiprogramma ?
                        <input
                            className="elementInputFormReport"
                            type="text"
                            disabled
                            placeholder=""
                            value={pointToeconomicalValue(costo)}
                            onInput={(e) => setCosto(e.target.value
                                //rimuove i punti quando presenti
                                .replace(/[.]/g, "")
                                //rimuove qualsiasi cosa non sia un numero
                                .replace(/[^0-9]/g, ""))}
                        /> :
                        <input
                            className="elementInputFormReport"
                            type="text"
                            placeholder=""
                            value={pointToeconomicalValue(costo)}
                            onInput={(e) => setCosto(e.target.value
                                //rimuove i punti quando presenti
                                .replace(/[.]/g, "")
                                //rimuove qualsiasi cosa non sia un numero
                                .replace(/[^0-9]/g, ""))}
                        />
                    }
                </div>
                {
                    attivitaRisolte.length !== 0 ?

                        <div>
                            <div
                                className="boxActivityList"
                            >
                                <div
                                    className="boxActivityList"
                                >
                                    <input type="checkbox"
                                        disabled={isOpenRiprogramma}
                                        checked={tutteAttivitaComplete}
                                        onChange={() => setAttivitaRisolte(prev => prev.map(el => {
                                            return {
                                                ...el,
                                                complated: true
                                            }
                                        })
                                        )}
                                    ></input>
                                    <h5 className="elementSecondaryTitleReportMainteneanceForm">Attività</h5>
                                </div>
                                <h5 className="elementSecondaryTitleReportMainteneanceForm"></h5>

                                <h5 className="elementSecondaryTitleReportMainteneanceForm"> Tempo effettivo</h5>

                            </div>
                            <ul
                                className="elementListActivity"
                            >
                                {
                                    //attività risolte prende i dati dall'intervento arricchito qundi sono tutte le attività svolte per quell'intervento i precedenti e i successivi ad esso racchiusi li
                                    attivitaRisolte.map((el, index) => {
                                        //va implenentato il blocco di un attività che è stata già risolta in un report parziale precedente
                                        if (el.mod) {
                                            return (

                                                <li
                                                    className="elementActivity"
                                                    key={index}
                                                >
                                                    <input type="checkbox"
                                                        checked={el.complated}
                                                        disabled={isOpenRiprogramma}
                                                        onChange={() => setAttivitaRisolte(prev => prev.map((el, i) =>
                                                            index === i ?
                                                                {
                                                                    ...el,
                                                                    complated: !el.complated
                                                                }
                                                                :
                                                                {
                                                                    ...el
                                                                }
                                                        ))}

                                                    />
                                                    <h5 className="elementSecondaryTitleReportMainteneanceForm elementFlexDimensionThree">
                                                        {el.nomeAttivita + ` ${el.tempoStimato ? `(${el.tempoStimato} - min)` : ``} `}
                                                    </h5>
                                                    <input
                                                        disabled={isOpenRiprogramma}
                                                        type="number"
                                                        value={el.timeEff === 0 ? '' : el.timeEff}
                                                        placeholder="min"
                                                        className="elementInputFormReport elementInputMaxDimension "
                                                        onChange={(e) => setAttivitaRisolte(prev => prev.map((el, i) =>
                                                            index === i ?
                                                                {
                                                                    ...el,
                                                                    timeEff: parseFloat(e.target.value)
                                                                }
                                                                :
                                                                {
                                                                    ...el
                                                                }
                                                        ))
                                                        }
                                                    />
                                                </li>
                                            )
                                        } else {
                                            return <li
                                                className="elementActivity"
                                                key={index}
                                            >
                                                <input type="checkbox"
                                                    disabled
                                                    checked={el.complated}

                                                />
                                                <h5 className="elementSecondaryTitleReportMainteneanceForm elementFlexDimensionThree">
                                                    {el.nomeAttivita + ` ${el.tempoStimato ? `(${el.tempoStimato} - min)` : ``} `}
                                                </h5>
                                                <input
                                                    disabled
                                                    type="number"
                                                    value={el.timeEff === 0 ? '' : el.timeEff}
                                                    placeholder="min"
                                                    className="elementInputFormReport elementInputMaxDimension "

                                                />
                                            </li>
                                        }

                                    })
                                }
                            </ul>
                        </div>
                        :
                        <span></span>
                }
                <div
                    className="boxTitleReportMainteneanceForm"
                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Tempo effettivo</h5>
                    {
                        isOpenRiprogramma ?
                            <input
                                className="elementInputFormReport"
                                type="number"
                                disabled
                                placeholder="min"
                                onInput={(e) => setTempo(e.target.value)}
                                value={tempo}
                            />
                            :
                            <input
                                className="elementInputFormReport"
                                type="number"
                                placeholder="min"
                                onInput={(e) => setTempo(e.target.value)}
                                value={tempo}
                            />
                    }
                </div>
                <div
                    className="boxTitleReportMainteneanceForm"
                    style={{ flexDirection: "column", alignItems: "inherit" }}
                >
                    <h5
                        className="elementSecondaryTitleReportMainteneanceForm"
                    >Note</h5>
                    <textarea
                        className="elementInputFormReport correctionNoteElementInputFormReport"
                        onInput={(e) => setNote(e.target.value)}

                    />
                </div>
                {   /**
                 * attività da eseguire quando si riprogramma un intervento
                 */
                    isOpenRiprogramma ?
                        <div
                            className="boxPeriodicFormNewEvent"
                        >

                            <button
                                //pulsante per annullare la riprogrammazione e ritornare al modulo di scrittura report
                                className="elementButtonSubmitFormNewEvent"
                                onClick={() => setOpenRiprogramma(e => !e)}
                            >
                                Annulla
                            </button>
                            <button
                                //pulsante per rendere effettiva la riprogrammazione
                                className="elementButtonSubmitFormNewEvent"
                                onClick={(e) => {
                                    e.target.disabled = true
                                    //caso in cui l'intervento sia virtuale
                                    if (datiIntervento.id.slice(0, 7) === "virtual") {
                                        if (dataRiprogrammazione !== "") {
                                            e.target.disabled = true
                                            //creiamo l'intervento che ad ora è virtuale
                                            dispatch(newIntervention({
                                                idAsset: datiIntervento.idMachinery,
                                                data: datiIntervento.date,
                                                idFault: datiIntervento.title,
                                                period: "",
                                                //da verificare l'ora come viene salvata
                                                ora: datiIntervento.hourEvent ? datiIntervento.hourEvent : "",
                                                idPrevIntervention: datiIntervento.idPrevIntervention

                                            }))

                                                .then(ris => {

                                                    dispatch(createDataIntervention({
                                                        idAsset: ris.payload.idMachinery,
                                                        id: ris.payload.id,
                                                        activity: JSON.stringify(datiIntervento.attivita[0].id ?
                                                            datiIntervento.attivita.map((el) => {
                                                                return {
                                                                    nomeAttivita: el.nomeAttivita,
                                                                    id: el.id,
                                                                    tempoStimato: el.tempoStimato
                                                                }
                                                            })
                                                            :
                                                            []),
                                                        idPlant: datiIntervento.idPlant,
                                                        durata: datiIntervento.durata,

                                                        operatore: datiIntervento.operatore,
                                                        /**
                                                         * qui poi andranno i dati dell'account alla quale è stato assegnato il compito e chi ha creato l'evento
                                                         */
                                                        creatore: utente[0].email, //al momento mettiamo il nome ma in futuro va messo l'id utente
                                                        note
                                                    })).then(el => {
                                                        dispatch(newIntervention({
                                                            idAsset: datiIntervento.idMachinery,
                                                            data: dataRiprogrammazione,
                                                            idFault: datiIntervento.title,
                                                            period: "",
                                                            //da verificare l'ora come viene salvata
                                                            ora: datiIntervento.hourEvent ? datiIntervento.hourEvent : "",
                                                            idPrevIntervention: ris.payload.id

                                                        }))
                                                            .then(elem => {
                                                                dispatch(createDataIntervention({
                                                                    idAsset: elem.payload.idMachinery,
                                                                    id: elem.payload.id,
                                                                    //rimuoviamo i dati in eccesso degli interventi
                                                                    activity: JSON.stringify(datiIntervento.attivita[0].id ?
                                                                        datiIntervento.attivita.map((el) => {
                                                                            return {
                                                                                nomeAttivita: el.nomeAttivita,
                                                                                id: el.id,
                                                                                tempoStimato: el.tempoStimato
                                                                            }
                                                                        })
                                                                        :
                                                                        []),
                                                                    idPlant: datiIntervento.idPlant,
                                                                    durata: datiIntervento.durata,

                                                                    operatore: datiIntervento.operatore,
                                                                    /**
                                                                     * qui poi andranno i dati dell'account alla quale è stato assegnato il compito e chi ha creato l'evento
                                                                     */
                                                                    creatore: utente[0].email, //al momento mettiamo il nome ma in futuro va messo l'id utente
                                                                    note
                                                                })).then(elemento => {
                                                                    e.target.disabled = false
                                                                    closeScheda()
                                                                }).catch(err => {
                                                                    e.target.disabled = false
                                                                    alert("errore dati intervento non caricati, eliminare l'intervento", err)
                                                                    console.log("errore nella creazione intervento", err)
                                                                })

                                                            }).catch(err => {
                                                                e.target.disabled = false
                                                                alert("errore di connessione, ripetere l'operazione", err)
                                                                console.log("errore nella creazione intervento", err)
                                                            })

                                                    }).catch(err => {
                                                        e.target.disabled = false
                                                        alert("errore dati intervento non caricati, eliminare l'intervento", err)
                                                        console.log("errore nella creazione intervento", err)
                                                    })

                                                }).catch(err => {
                                                    e.target.disabled = false
                                                    alert("errore di connessione, ripetere l'operazione", err)
                                                    console.log("errore nella creazione intervento", err)
                                                })
                                        } else {
                                            alert("Inserire:\ndata riprogrammazione")
                                            e.target.disabled = false
                                        }
                                    }
                                    //caso in cui l'intervento è reale ed esiste in backend
                                    else {
                                        if (dataRiprogrammazione !== "") {
                                            e.target.disabled = true
                                            const risp = dispatch(newIntervention({
                                                idAsset: datiIntervento.idMachinery,
                                                data: dataRiprogrammazione,
                                                idFault: datiIntervento.title,
                                                period: "",
                                                //da verificare l'ora come viene salvata
                                                ora: datiIntervento.hourEvent ? datiIntervento.hourEvent : "",
                                                idPrevIntervention: datiIntervento.id

                                            }))
                                            Promise.all([risp])
                                                .then(ris => {


                                                    dispatch(createDataIntervention({
                                                        idAsset: ris[0].payload.idMachinery,
                                                        id: ris[0].payload.id,
                                                        //riporto le attività come nell'intervento originario 
                                                        activity: JSON.stringify(datiIntervento.attivita[0].id ?
                                                            datiIntervento.attivita.map((el) => {
                                                                return {
                                                                    nomeAttivita: el.nomeAttivita,
                                                                    id: el.id,
                                                                    tempoStimato: el.tempoStimato
                                                                }
                                                            })
                                                            :
                                                            []),
                                                        idPlant: datiIntervento.idPlant,
                                                        durata: datiIntervento.durata,

                                                        operatore: datiIntervento.operatore,
                                                        /**
                                                         * qui poi andranno i dati dell'account alla quale è stato assegnato il compito e chi ha creato l'evento
                                                         */
                                                        creatore: utente[0].email, //al momento mettiamo il nome ma in futuro va messo l'id utente
                                                        note
                                                    }))
                                                        .catch(err => {
                                                            e.target.disabled = false
                                                            alert("errore dati intervento non caricati, eliminare l'intervento", err)
                                                            console.log("errore nella creazione intervento", err)
                                                        })
                                                }).then(ris => {
                                                    e.target.disabled = false
                                                    closeScheda()
                                                })
                                                .catch(err => {
                                                    e.target.disabled = false
                                                    alert("errore di connessione, ripetere l'operazione", err)
                                                    console.log("errore nella creazione intervento", err)
                                                })
                                        } else {
                                            alert("Inserire:\ndata riprogrammazione")
                                            e.target.disabled = false
                                        }
                                    }
                                }
                                }
                            >Invio</button>
                        </div>
                        :
                        //caso in cui si sta compilando il report
                        <div
                            className="boxPeriodicFormNewEvent"
                        >

                            <button
                                // pulsante per inviare il report
                                className="elementButtonSubmitFormNewEvent"

                                onClick={(e) => {

                                    // caso di un intervento virtuale in questa sezione siamo virtualmente sicuri che stiamo considerando che per questo intervento non ci siano report collegati ed eventuali attività svolte
                                    if (datiIntervento.id.slice(0, 7) === "virtual") {
                                        //verifica della data operazione del report altrimenti non deve avviare il caricamento
                                        if (dataOperazione !== "") {
                                            e.target.disabled = true
                                            //bisogna creare l'intervento prima di risolverlo
                                            const risp = dispatch(newIntervention({
                                                idAsset: datiIntervento.idMachinery,
                                                data: datiIntervento.date,
                                                idFault: datiIntervento.title,
                                                period: "",
                                                //da verificare l'ora come viene salvata
                                                ora: datiIntervento.hourEvent ? datiIntervento.hourEvent : "",
                                                idPrevIntervention: datiIntervento.idPrevIntervention

                                            }))
                                            Promise.all([risp])
                                                .then(ris => {

                                                    // una volta creato l'intervento con il suo id creiamo l'istanza di dati di intervento
                                                    dispatch(createDataIntervention({
                                                        idAsset: ris[0].payload.idMachinery,
                                                        id: ris[0].payload.id,
                                                        activity: JSON.stringify(datiIntervento.attivita[0].id ?
                                                            datiIntervento.attivita.map((el) => {
                                                                return {
                                                                    nomeAttivita: el.nomeAttivita,
                                                                    id: el.id,
                                                                    tempoStimato: el.tempoStimato
                                                                }
                                                            })
                                                            :
                                                            []),
                                                        idPlant: datiIntervento.idPlant,
                                                        durata: datiIntervento.durata,

                                                        operatore: datiIntervento.operatore,
                                                        /**
                                                         * qui poi andranno i dati dell'account alla quale è stato assegnato il compito e chi ha creato l'evento
                                                         */
                                                        creatore: utente[0].email, //al momento mettiamo il nome ma in futuro va messo l'id utente
                                                        note
                                                    })).then(
                                                        //el => closeScheda(closingNumber)
                                                        //ora carichiamo il report dell'intervento
                                                        (response) => {
                                                            const risp = dispatch(newReport({
                                                                idAsset: datiIntervento.idMachinery,
                                                                idIntervention: response.payload.id,
                                                                data: dataOperazione,
                                                                operatore: utente[0].name,
                                                                activity: JSON.stringify(datiIntervento.attivita[0].id ?
                                                                    attivitaRisolte.map(el => {
                                                                        return {
                                                                            id: el.id,
                                                                            //per avere la sola definizione delle attività svolte verifico se la attività era una di quelle non completate precedentemente e se era già stata eseguita in questo report la pogo a falsa altrimenti riporto il valore di complated
                                                                            complated: el.mod ? el.complated : false,
                                                                            timeEff: el.timeEff
                                                                        }
                                                                    })
                                                                    :
                                                                    []
                                                                ),
                                                                valoriLettura: valoriLettura,
                                                                costo: costo,
                                                                operation: operazione,
                                                                durata: tempo,
                                                                note: note
                                                            }))

                                                            Promise.all([risp])
                                                                .then(el => {
                                                                    if (el[0].meta.requestStatus !== "rejected") {
                                                                        e.target.disabled = false
                                                                        closeScheda()
                                                                    } else {
                                                                        e.target.disabled = false
                                                                        alert("errore dati report non caricati, ripetere l'operazione")
                                                                        console.log("errore nella creazione report")
                                                                    }
                                                                })

                                                        }
                                                    ).catch(err => {
                                                        e.target.disabled = false
                                                        alert("errore dati intervento non caricati, eliminare l'intervento", err)
                                                        console.log("errore nella creazione intervento", err)
                                                    })

                                                }).catch(err => {
                                                    e.target.disabled = false
                                                    alert("errore di connessione, ripetere l'operazione", err)
                                                    console.log("errore nella creazione intervento", err)
                                                })
                                        } else {
                                            alert("Inserire:\ndata riprogrammazione")
                                            e.target.disabled = false
                                        }

                                    } else {
                                        if (dataOperazione !== "") {
                                            e.target.disabled = true
                                            const risp = dispatch(newReport({
                                                idAsset: datiIntervento.idMachinery,
                                                idIntervention: datiIntervento.id,
                                                data: dataOperazione,
                                                operatore: utente[0].name,
                                                /**
                                                 * in questa seizone devo implementare il caricamento delle sole attività eseguite in questo report e non mi devo portare un rapporto incrementale
                                                 */
                                                activity: JSON.stringify(datiIntervento.attivita[0].id ?
                                                    attivitaRisolte.map(el => {
                                                        return {
                                                            id: el.id,
                                                            //per avere la sola definizione delle attività svolte verifico se la attività era una di quelle non completate precedentemente e se era già stata eseguita in questo report la pogo a falsa altrimenti riporto il valore di complated
                                                            complated: el.mod ? el.complated : false,
                                                            timeEff: el.timeEff
                                                        }
                                                    })
                                                    :
                                                    []
                                                ),
                                                valoriLettura: valoriLettura,
                                                costo: costo,
                                                operation: operazione,
                                                durata: tempo,
                                                note: note
                                            }))
                                            console.log("risposta creazione report", risp)
                                            Promise.all([risp])
                                                .then(el => {
                                                    if (el[0].meta.requestStatus !== "rejected") {
                                                        e.target.disabled = false
                                                        closeScheda()
                                                    } else {
                                                        e.target.disabled = false
                                                        alert("errore dati report non caricati, ripetere l'operazione")
                                                        console.log("errore nella creazione report")
                                                    }

                                                })
                                        } else {
                                            alert("Inserire:\ndata operazione")
                                            e.target.disabled = false
                                        }
                                    }

                                }}
                            >
                                Invia report
                            </button>
                            <button
                                className="elementButtonSubmitFormNewEvent"
                                onClick={() => setOpenRiprogramma(e => !e)}
                            >Riprogramma</button>
                            <button
                                className="elementButtonSubmitFormNewEvent"
                                onClick={() => setOpenStorico(el => !el)}
                            >Storico</button>
                        </div>
                }


            </div>
        </Modal>
    }



}

export default FormCreateMaintenance


