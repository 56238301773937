/**
budget relativo ad interventi ordinari (sostituzione pezzi) e manutenzione straordinaria (capitolo 3 del documento sulle metriche)
tempo totale di manutenzione (paragrafo 4.2.2 del documento sulle metriche)
rapporto tra il tempo effettivo degli interventi programmati rispetto al tempo totale speso per gli interventi (paragrafo 4.1.7 del documento sulle metriche)
rapporto tra il numero di interventi programmati completati rispetto il totale degli interventi programmati (paragrafo 4.1.8 del documento sulle metriche)

        percentuale degli interventi eseguiti in tempo (metrica da approfondire) on time delivery
        
        rapporto tra tempo stimato rispetto all'effettivo degli interventi, proposto per macchina, linea, reparto e stabilimento (paragrafo 4.2.1 del documento sulle metriche)
        
        
        
*/



import { useSelector } from "react-redux"
import { selectAllIntervention } from "../root/slices/interventionSlice"
import { selectAllBudget } from "../root/slices/budgetSlice"
import { selectAllDepartment } from "../root/slices/listDepartmentSlice"
import { selectAllLine } from "../root/slices/listLineSlice"
import { selectAllMachinery } from "../root/slices/listMachinerySlice"
import { selectPlant } from "../root/slices/plantSlice"
import { selectAllReport } from "../root/slices/reportSlice"

import './mainMetriche.css'
import { useEffect, useState } from "react"
import { costoPerAsset } from "../../functions/metriche/budget"
import { giorniInizioAnno } from "../../functions/dateTime"
import { numbreOfPeriodicInterventionAndPercentTime, tempoManutenzioneAsset } from "../../functions/metriche/tempiMan-Number"
import { getDerivatesInterForAPeriod, getFirstLevelActuative, getPeriodicInterventions } from "../../functions/metriche/filterList"
import { RTSTEAttivitaIntervento } from "../../functions/metriche/RTSTE"
import { OTDForPeriodicIntervention } from "../../functions/metriche/OTD"
import { pointToeconomicalValue } from "../../functions/generiche"
import { AssetMetriche, AttivitaMetriche, InterventionMetriche } from "./subElements/elementMetriche"


const Metriche = () => {
    //lista interventi per tutte le macchine esistenti
    const listIntervention = useSelector(selectAllIntervention)
    const listBudget = useSelector(selectAllBudget)
    const listDepartment = useSelector(selectAllDepartment)
    const listLine = useSelector(selectAllLine)
    const listAsset = useSelector(selectAllMachinery)
    const listPlant = useSelector(selectPlant)
    const listReport = useSelector(selectAllReport)

    const [listPeriodicIntervention, setPeriodicInter] = useState([])
    const [listinterventionPeriodicANDderivates, setListInterPandDeriv] = useState([])
    //raccolgo gli interventi che sono collegati ad un intervento periodico non eliminato
    const [listInterventioAttuative, setListAttuative] = useState([])

    //VARIABILI NUMERO DI INTERVENTI RISPETTO AL TOTALE PERIODICI

    const dataOdierna = new Date()
    const [giorniDaInizioAnno, setGiorniInizioAnno] = useState()

    //VARIABILI ONE TIME DELIVERY ok
    const [otdManIp, setotdManIp] = useState([])
    const [otdManAss, setotdManAss] = useState([])
    const [otdManLin, setotdManLin] = useState([])
    const [otdManDep, setotdManDep] = useState([])
    const [otdManPlt, setotdManPlt] = useState([])


    //VARIABILI PER RAPPORTO TEMPO STIMATO TEMPO EFFETTIVO ok
    const [tseManIp, settseManIp] = useState([])
    const [tseManAss, settseManAss] = useState([])
    const [tseManLin, settseManLin] = useState([])
    const [tseManDep, settseManDep] = useState([])
    const [tseManPlt, settseManPlt] = useState([])

    //VARIABILI PER NUEMRO DI INTERVENTI PERIODICI ESEGUITI RISPETTO AL TOTALE ok
    const [ieManAss, setIeManAss] = useState([])
    const [ieManLin, setIeManLin] = useState([])
    const [ieManDep, setIeManDep] = useState([])
    const [ieManPlt, setIeManPlt] = useState([])
    //VARIABILI TEMPI TOT MANUTENZIONE OK
    const [tManAss, setTManAss] = useState([])
    const [tManLin, setTManLin] = useState([])
    const [tManDep, setTManDep] = useState([])
    const [tManPlt, setTManPlt] = useState([])


    // TEMPO DEI SOLI INTERVENTI PERIODICI EFFETTIVO (PRESO DAI REPORT) ok
    const [tpManAss, setTpManAss] = useState([])
    const [tpManLin, setTpManLin] = useState([])
    const [tpManDep, setTpManDep] = useState([])
    const [tpManPlt, setTpManPlt] = useState([])
    // VARIABILI PER IL BUDGET    OK
    const [costPlant, setCostPlant] = useState([])
    const [costoDipart, setCostDip] = useState([])
    const [costiLinea, setCostiLinea] = useState([])
    const [costiPerAsset, setCostiAsset] = useState([])
    /**
     * [
     * {
     *  idAsset:"",
     *  costiInterventi:""
     * }
     * ]
     */


    //PREPARAZIONE INIZIALE
    useEffect(() => {
        setGiorniInizioAnno(Math.round(giorniInizioAnno()))
    }, [])

    useEffect(() => {
        setPeriodicInter(getPeriodicInterventions(listIntervention))
    }, [listIntervention])

    useEffect(() => {
        setListAttuative(getFirstLevelActuative(listIntervention, listPeriodicIntervention))
    }, [listPeriodicIntervention])

    useEffect(() => {
        setListInterPandDeriv(getDerivatesInterForAPeriod(listPeriodicIntervention, listInterventioAttuative, listIntervention))
    }, [listInterventioAttuative])


    //RAPPORTO TEMPO STIMATO INTERVENTO ED EFFETTIVO
    useEffect(() => {
        if (listInterventioAttuative.length !== 0) {
            settseManIp(RTSTEAttivitaIntervento(listIntervention, listIntervention, listInterventioAttuative, listReport))
        }
    }, [listInterventioAttuative])

    useEffect(() => {
        settseManAss(listAsset.map(ass => {
            const listInterventionForMachine = listPeriodicIntervention.filter(i => i.idMachinery === ass.id)
            const DataForInterventionMachine = listInterventionForMachine.map(i => tseManIp.find(e => e?.id === i.id))

            const value = DataForInterventionMachine.reduce((a, i) => {

                if (a === undefined) {
                    return {
                        id: ass.id,
                        sRa: i?.RTSTEa
                            ?
                            i.RTSTEa >= 1
                                ?
                                1
                                :
                                i.RTSTEa
                            :
                            0,

                        nRa: i?.RTSTEa ? 1 : 0,
                        sR: i?.RTSTE
                            ?
                            i.RTSTE >= 1
                                ?
                                1
                                :
                                i.RTSTE
                            :
                            0,
                        nR: i?.RTSTE ? 1 : 0
                    }
                } else {
                    return {
                        id: ass.id,
                        sRa: i?.RTSTEa
                            ?
                            i.RTSTEa >= 1
                                ?
                                a.sRa + 1
                                :
                                a.sRa + i.RTSTEa
                            :
                            a.sRa,

                        nRa: i?.RTSTEa ? a.nRa + 1 : a.nRa,
                        sR: i?.RTSTE
                            ?
                            i.RTSTE >= 1
                                ?
                                a.sR + 1
                                :
                                a.sR + i.RTSTE
                            :
                            a.sRa,
                        nR: i?.RTSTE ? a.nR + 1 : a.nR
                    }
                }
            }, undefined)



            return value
        }))

    }, [tseManIp])


    useEffect(() => {
        settseManLin(listLine.map(lin => {
            const listassLinea = listAsset.filter(a => a.idLinea === lin.id)
            const data = listassLinea.map(i => tseManAss.find(e => e?.id === i.id))

            const value = data.reduce((a, i) => {
                if (a === undefined) {
                    return {
                        id: lin.id,
                        sRa: i?.sRa
                            ?
                            i.sRa / i.nRa
                            :
                            0,

                        nRa: i?.sRa ? 1 : 0,
                        sR: i?.sR
                            ?
                            i.sR / i.nR
                            :
                            0,
                        nR: i?.sR ? 1 : 0
                    }
                } else {

                    if (i === undefined) {
                        return a
                    } else {
                        return {
                            name: lin.name,
                            id: lin.id,
                            sRa: i?.sRa
                                ?

                                a.sRa + (i.sRa / i.nRa)
                                :
                                a.sRa,

                            nRa: i?.sRa ? a.nRa + 1 : a.nRa,
                            sR: i?.sR
                                ?
                                a.sR + (i.sR / i.nR)
                                :
                                a.sR,
                            nR: i?.sR ? a.nR + 1 : a.nR
                        }
                    }

                }
            }, undefined)



            return value
        }))
    }, [tseManAss])




    useEffect(() => {
        settseManDep(listDepartment.map(dep => {
            const listaLin = listLine.filter(a => a.idDepartment === dep.id)
            const data = listaLin.map(i => tseManLin.find(e => e?.id === i.id))

            const value = data.reduce((a, i) => {

                if (a === undefined) {
                    return {
                        id: dep.id,
                        sRa: i?.sRa
                            ?
                            i.sRa / i.nRa
                            :
                            0,

                        nRa: i?.sRa ? 1 : 0,
                        sR: i?.sR
                            ?
                            i.sR / i.nR
                            :
                            0,
                        nR: i?.sR ? 1 : 0
                    }
                } else {
                    return {

                        id: dep.id,
                        sRa: i?.sRa
                            ?

                            a.sRa + (i.sRa / i.nRa)
                            :
                            a.sRa,

                        nRa: i?.sRa ? a.nRa + 1 : a.nRa,
                        sR: i?.sR
                            ?
                            a.sR + (i.sR / i.nR)
                            :
                            a.sR,
                        nR: i?.sR ? a.nR + 1 : a.nR
                    }
                }
            }, undefined)



            return value
        }))
    }, [tseManLin])



    useEffect(() => {
        settseManPlt(listPlant.map(plt => {
            const listaDep = listDepartment.filter(a => a.idPlant === plt.id)
            const data = listaDep.map(i => tseManDep.find(e => e?.id === i.id))
            const value = data.reduce((a, i) => {

                if (a === undefined) {

                    return {
                        id: plt.id,
                        sRa: i?.sRa
                            ?
                            i.sRa / i.nRa
                            :
                            0,

                        nRa: i?.sRa ? i.sRa !== 0 ? 1 : 0 : 0,
                        sR: i?.sR
                            ?
                            i.sR / i.nR
                            :
                            0,
                        nR: i?.sR ? i.sR !== 0 ? 1 : 0 : 0
                    }
                } else {
                    if (i === undefined) {
                        return a
                    } else {

                        return {

                            id: plt.id,
                            sRa: i?.sRa
                                ?
                                a.sRa + (i.sRa / i.nRa)
                                :
                                a.sRa,

                            nRa: i?.sRa ? i.sRa !== 0 ? a.nRa + 1 : a.nRa : a.nRa,
                            sR: i?.sR
                                ?
                                a.sR + (i.sR / i.nR)
                                :
                                a.sR,
                            nR: i?.sR ? i.sR !== 0 ? a.nR + 1 : a.nR : a.nR
                        }
                    }

                }
            }, undefined)



            return value
        }))
    }, [tseManDep])

    //ON TIME DELIVERY
    useEffect(() => {
        setotdManIp(OTDForPeriodicIntervention(listPeriodicIntervention, listIntervention, listInterventioAttuative, listReport))
    }, [listInterventioAttuative])

    useEffect(() => {
        setotdManAss(

            listAsset.map(ass => {
                let odtAssetTemp = 0
                let count = 0
                listPeriodicIntervention
                    .filter(ip => ip.idMachinery === ass.id)
                    .forEach(el => {
                        if (!isNaN(otdManIp.find(e => e.id === el.id)?.rapporto)) {
                            odtAssetTemp = odtAssetTemp + otdManIp.find(e => e.id === el.id).rapporto
                            count = count + 1
                        }

                    })
                return {
                    id: ass.id,
                    rapporto: Math.round(odtAssetTemp / count)
                }
            })
        )
    }, [otdManIp])

    useEffect(() => {
        setotdManLin(

            listLine.map(lin => {
                let odtAssetTemp = 0
                let count = 0
                listAsset
                    .filter(ass => ass.idLinea === lin.id)
                    .forEach(el => {
                        if (!isNaN(otdManAss.find(e => e.id === el.id)?.rapporto)) {
                            odtAssetTemp = odtAssetTemp + otdManAss.find(e => e.id === el.id).rapporto
                            count = count + 1
                        }

                    })
                return {
                    id: lin.id,
                    rapporto: Math.round(odtAssetTemp / count)
                }
            })
        )
    }, [otdManAss])

    useEffect(() => {
        setotdManDep(

            listDepartment.map(dep => {
                let odtAssetTemp = 0
                let count = 0
                listLine
                    .filter(lin => lin.idDepartment === dep.id)
                    .forEach(el => {
                        if (!isNaN(otdManLin.find(e => e.id === el.id)?.rapporto)) {
                            odtAssetTemp = odtAssetTemp + otdManLin.find(e => e.id === el.id).rapporto
                            count = count + 1
                        }

                    })
                return {
                    id: dep.id,
                    rapporto: Math.round(odtAssetTemp / count)
                }
            })
        )
    }, [otdManLin])

    useEffect(() => {
        setotdManPlt(

            listPlant.map(plt => {
                let odtAssetTemp = 0
                let count = 0
                listDepartment
                    .filter(dep => dep.idPlant === plt.id)
                    .forEach(el => {
                        if (!isNaN(otdManDep.find(e => e.id === el.id)?.rapporto)) {
                            odtAssetTemp = odtAssetTemp + otdManDep.find(e => e.id === el.id).rapporto
                            count = count + 1
                        }

                    })
                return {
                    id: plt.id,
                    rapporto: Math.round(odtAssetTemp / count)
                }
            })
        )
    }, [otdManDep])


    // TEMPI EFFETTIVI DELLA MANUTENZIONE PROGRAMMATA RISPETTO ALLA TOTALE EL IL LORO NUMERO

    useEffect(() => {

        const [listEventPeridoComplete, listAssTempP] = numbreOfPeriodicInterventionAndPercentTime(
            listAsset,
            listReport,
            listIntervention,
            listPeriodicIntervention,
            listInterventioAttuative,
            listinterventionPeriodicANDderivates, "2024-01-01", dataOdierna.toISOString().slice(0, 10))

        setIeManAss(listEventPeridoComplete)
        setTpManAss(listAssTempP)

    }, [listinterventionPeriodicANDderivates])

    useEffect(() => {
        setIeManLin(
            listLine
                .map(lin => {
                    //lista asset per linea
                    const vettoreAssetLinee = listAsset.filter(ass => ass.idLinea === lin.id)

                    let sommaItot = 0
                    let sommaIcom = 0
                    //calcolo della spesa per linea
                    vettoreAssetLinee.forEach(vass => {
                        sommaItot = ieManAss.find(cass => cass.id === vass.id)?.allIntervention + sommaItot
                        sommaIcom = ieManAss.find(cass => cass.id === vass.id)?.completeIntervention + sommaIcom
                    })
                    //ritorna la somma
                    return {
                        id: lin.id,
                        allIntervention: sommaItot,
                        completeIntervention: sommaIcom
                    }
                }))
    }, [ieManAss])



    useEffect(() => {
        setIeManDep(
            listDepartment
                .map(dep => {
                    //lista asset per linea
                    const vettoreAssetLinee = listLine.filter(lin => lin.idDepartment === dep.id)

                    let sommaItot = 0
                    let sommaIcom = 0
                    //calcolo della spesa per linea
                    vettoreAssetLinee.forEach(vass => {
                        sommaItot = ieManLin.find(cass => cass.id === vass.id)?.allIntervention + sommaItot
                        sommaIcom = ieManLin.find(cass => cass.id === vass.id)?.completeIntervention + sommaIcom
                    })
                    //ritorna la somma
                    return {
                        id: dep.id,
                        allIntervention: sommaItot,
                        completeIntervention: sommaIcom
                    }
                }))
    }, [ieManLin])


    useEffect(() => {
        setIeManPlt(
            listPlant
                .map(plt => {

                    const vettoreAssetLinee = listDepartment.filter(dep => dep.idPlant === plt.id)

                    let sommaItot = 0
                    let sommaIcom = 0
                    //calcolo della spesa per linea
                    vettoreAssetLinee.forEach(vass => {
                        sommaItot = ieManDep.find(cass => cass.id === vass.id)?.allIntervention + sommaItot
                        sommaIcom = ieManDep.find(cass => cass.id === vass.id)?.completeIntervention + sommaIcom
                    })
                    //ritorna la somma
                    return {
                        id: plt.id,
                        allIntervention: sommaItot,
                        completeIntervention: sommaIcom
                    }
                }))
    }, [ieManDep])




    useEffect(() => {
        setTpManLin(
            listLine
                .map(lin => {
                    //lista asset per linea
                    const vettoreAssetLinee = listAsset.filter(ass => ass.idLinea === lin.id)

                    let sommaLinea = 0
                    //calcolo della spesa per linea
                    vettoreAssetLinee.forEach(vass => sommaLinea = tpManAss.find(cass => cass.id === vass.id)?.tempEff + sommaLinea)
                    //ritorna la somma
                    return {
                        id: lin.id,
                        tempEff: sommaLinea
                    }
                }))
    }, [tpManAss])

    useEffect(() => {
        setTpManDep(
            listDepartment
                .map(dep => {
                    //lista asset per linea
                    const vettorelineeDep = listLine.filter(lin => lin.idDepartment === dep.id)

                    let sommadep = 0
                    //calcolo della spesa per linea
                    vettorelineeDep.forEach(vlin => sommadep = tpManLin.find(clin => clin.id === vlin.id)?.tempEff + sommadep)
                    //ritorna la somma
                    return {
                        id: dep.id,
                        tempEff: sommadep
                    }
                }))
    }, [tpManLin])

    useEffect(() => {
        setTpManPlt(
            listPlant.map(plt => {
                const vettorerepplant = listDepartment.filter(dep => dep.idPlant === plt.id)
                let sommaPlt = 0
                vettorerepplant.forEach(vdep => sommaPlt = tpManDep.find(cdep => cdep.id === vdep.id)?.tempEff + sommaPlt)
                return {
                    id: plt.id,
                    tempEff: sommaPlt
                }
            })
        )
    }, [tpManDep])

    //CALCOLO DELLE METRICHE PER IL BUDGET
    //budget per macchina
    useEffect(() => {
        //per ogni asset
        setCostiAsset(
            costoPerAsset({ listAsset, listReport })
        )
    }, [listReport])

    //valore interventi per layer
    useEffect(() => {
        //calcoliamo i costi delle linee
        setCostiLinea(
            listLine
                .map(lin => {
                    //lista asset per linea
                    const vettoreAssetLinee = listAsset.filter(ass => ass.idLinea === lin.id)

                    let sommaLinea = 0
                    //calcolo della spesa per linea
                    vettoreAssetLinee.forEach(vass => sommaLinea = costiPerAsset.find(cass => cass.id === vass.id)?.costiInterventi + sommaLinea)
                    //ritorna la somma
                    return {
                        id: lin.id,
                        costiInterventi: sommaLinea
                    }
                }))

    }, [costiPerAsset])

    useEffect(() => {
        setCostDip(
            listDepartment
                .map(dep => {
                    //lista asset per linea
                    const vettorelineeDep = listLine.filter(lin => lin.idDepartment === dep.id)

                    let sommadep = 0
                    //calcolo della spesa per linea
                    vettorelineeDep.forEach(vlin => sommadep = costiLinea.find(clin => clin.id === vlin.id)?.costiInterventi + sommadep)
                    //ritorna la somma
                    return {
                        id: dep.id,
                        costiInterventi: sommadep
                    }
                }))
    }, [costiLinea])


    useEffect(() => {
        setCostPlant(
            listPlant.map(plt => {
                const vettorerepplant = listDepartment.filter(dep => dep.idPlant === plt.id)
                let sommaPlt = 0
                vettorerepplant.forEach(vdep => sommaPlt = costoDipart.find(cdep => cdep.id === vdep.id)?.costiInterventi + sommaPlt)
                return {
                    id: plt.id,
                    costiInterventi: sommaPlt
                }
            })
        )
    }, [costoDipart])

    // CALCOLO DEI TEMPI TOTALI DI MANUTENZIONE

    useEffect(() => {
        //per ogni asset
        setTManAss(tempoManutenzioneAsset({ listAsset: listAsset, listReport: listReport, listIntervention: listIntervention }))
    }, [listReport])

    useEffect(() => {
        //calcoliamo i costi delle linee
        setTManLin(
            listLine
                .map(lin => {
                    //lista asset per linea
                    const vettoreAssetLinee = listAsset.filter(ass => ass.idLinea === lin.id)

                    let sommaLinea = 0
                    //calcolo della spesa per linea
                    vettoreAssetLinee.forEach(vass => sommaLinea = tManAss.find(cass => cass.id === vass.id)?.tempi + sommaLinea)
                    //ritorna la somma
                    return {
                        id: lin.id,
                        tempi: sommaLinea
                    }
                }))

    }, [tManAss])

    useEffect(() => {
        setTManDep(
            listDepartment
                .map(dep => {
                    //lista asset per linea
                    const vettorelineeDep = listLine.filter(lin => lin.idDepartment === dep.id)

                    let sommadep = 0
                    //calcolo della spesa per linea
                    vettorelineeDep.forEach(vlin => sommadep = tManLin.find(clin => clin.id === vlin.id)?.tempi + sommadep)
                    //ritorna la somma
                    return {
                        id: dep.id,
                        tempi: sommadep
                    }
                }))
    }, [tManLin])

    useEffect(() => {
        setTManPlt(
            listPlant.map(plt => {
                const vettorerepplant = listDepartment.filter(dep => dep.idPlant === plt.id)
                let sommaPlt = 0
                vettorerepplant.forEach(vdep => sommaPlt = tManDep.find(cdep => cdep.id === vdep.id)?.tempi + sommaPlt)
                return {
                    id: plt.id,
                    tempi: sommaPlt
                }
            })
        )
    }, [tManDep])


    return (
        <div className="bodyMetriche">

            <div style={{ display: "flex", marginBottom: "2vw" }}>
                <h3>Legenda :</h3>
                <span
                    style={{
                        margin: "1vw 2vw",
                        color: "green"
                    }}
                >Stabilimento</span>
                <span
                    style={{
                        margin: "1vw 2vw",
                        color: "blue"
                    }}
                >Reparto</span>
                <span
                    style={{
                        margin: "1vw 2vw",
                        color: "violet"
                    }}
                >Linea</span>
                <span
                    style={{
                        margin: "1vw 2vw",
                        color: "grey"
                    }}
                >Macchinario</span>
                <span
                    style={{
                        margin: "1vw 2vw",
                        color: "purple"
                    }}
                >intervento Periodico</span>
            </div>
            <ul>
                {
                    listPlant.map(plt => {
                        return <li
                            key={plt.id}
                            style={{ color: "green", margin: " 0vw 0vw 3vw 0vw" }}
                        >
                            <div style={{ display: "flex" }}>
                                <span >{plt.nome}</span>
                                <div >
                                    <span
                                        style={{
                                            margin: "0vw 1vw",
                                            color: "black"
                                        }}>
                                        costo-budget:


                                        {pointToeconomicalValue(costPlant.find(el => el.id === plt.id)?.costiInterventi)}
                                        /
                                        {pointToeconomicalValue(listBudget
                                            .sort((a, b) => a.updatedAt.localeCompare(b.updatedAt))
                                            .find(e => e.idStructure === plt.id)?.value)} €
                                    </span>
                                    <span style={{ margin: "0vw 1vw", color: "black" }}>

                                        tempo totale manutenzione: {tManPlt.find(el => el.id === plt.id)?.tempi} minuti
                                    </span>
                                    <span style={{ margin: "0vw 1vw", color: "black" }}>
                                        PMP: {
                                            isNaN(tpManPlt.find(el => el.id === plt.id)?.tempEff
                                                /
                                                tManPlt.find(el => el.id === plt.id)?.tempi)
                                                ?
                                                0
                                                :
                                                Math.round((tpManPlt.find(el => el.id === plt.id)?.tempEff
                                                    /
                                                    tManPlt.find(el => el.id === plt.id)?.tempi) * 100)

                                        } %
                                    </span>
                                    <span style={{ margin: "0vw 1vw", color: "black" }}>
                                        PMC: {ieManPlt.find(el => el.id === plt.id)?.completeIntervention} / {ieManPlt.find(el => el.id === plt.id)?.allIntervention}
                                    </span>
                                    <span style={{ margin: "0vw 1vw", color: "black" }}>
                                        On Time Delivery: {isNaN(otdManPlt.find(el => el.id === plt.id)?.rapporto)
                                            ?
                                            "no data"
                                            :
                                            otdManPlt.find(el => el.id === plt.id)?.rapporto
                                        } %
                                    </span>
                                    <span style={{ margin: "0vw 1vw", color: "black" }}>
                                        RTSTE: {isNaN(tseManPlt
                                            .find(tseL => plt.id === tseL?.id)?.sR
                                            /
                                            tseManPlt
                                                .find(tseL => plt.id === tseL?.id)?.nR)
                                            ?
                                            " no data"
                                            :
                                            Math.round((tseManPlt
                                                .find(e => plt.id === e?.id)?.sR
                                                /
                                                tseManPlt
                                                    .find(e => plt.id === e?.id)?.nR) * 100) + " %"
                                        }
                                    </span>
                                    <span style={{ margin: "0vw 1vw", color: "black" }}>
                                        RTSTE-a: {isNaN(tseManPlt.find(e => plt.id === e?.id)?.sRa / tseManPlt.find(e => plt.id === e?.id)?.nRa) ?
                                            " no data"
                                            :
                                            Math.round((tseManPlt.find(e => plt.id === e?.id)?.sRa / tseManPlt.find(e => plt.id === e?.id)?.nRa) * 100) + " %"}
                                    </span>




                                </div>

                            </div>


                            <ul>
                                {listDepartment.filter(depF => plt.id === depF.idPlant).map(dep => {
                                    return <li key={dep.id} style={{ color: "blue", margin: "1vw 1vw" }}>
                                        <div style={{ display: "flex" }}>
                                            <span >{dep.name}</span>
                                            <div >
                                                <span style={{
                                                    margin: "0vw 1vw",
                                                    color: "black"
                                                }}>
                                                    costo-budget:{pointToeconomicalValue(costoDipart.find(el => el.id === dep.id)?.costiInterventi)}
                                                    /
                                                    {pointToeconomicalValue(listBudget
                                                        .sort((a, b) => a.updatedAt.localeCompare(b.updatedAt))
                                                        .find(e => e.idStructure === dep.id)?.value)} €
                                                </span>
                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                    tempo totale manutenzione: {tManDep.find(el => el.id === dep.id)?.tempi} minuti
                                                </span>
                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                    PMP: {
                                                        isNaN(tpManDep.find(el => el.id === dep.id)?.tempEff
                                                            /
                                                            tManDep.find(el => el.id === dep.id)?.tempi)
                                                            ?
                                                            0
                                                            :
                                                            Math.round((tpManDep.find(el => el.id === dep.id)?.tempEff
                                                                /
                                                                tManDep.find(el => el.id === dep.id)?.tempi) * 100)

                                                    } %
                                                </span>
                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                    PMC: {ieManDep.find(el => el.id === dep.id)?.completeIntervention} / {ieManDep.find(el => el.id === dep.id)?.allIntervention}
                                                </span>
                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                    On Time Delivery: {isNaN(otdManDep.find(el => el.id === dep.id)?.rapporto)
                                                        ?
                                                        "no data"
                                                        :
                                                        otdManDep.find(el => el.id === dep.id)?.rapporto
                                                    } %
                                                </span>
                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                    RTSTE: {isNaN(tseManDep
                                                        .find(tseL => dep.id === tseL?.id)?.sR
                                                        /
                                                        tseManDep
                                                            .find(tseL => dep.id === tseL?.id)?.nR)
                                                        ?
                                                        " no data"
                                                        :
                                                        Math.round((
                                                            tseManDep
                                                                .find(e => dep.id === e?.id)?.sR
                                                            /
                                                            tseManDep
                                                                .find(e => dep.id === e?.id)?.nR) * 100) + " %"
                                                    }
                                                </span>
                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                    RTSTE-a: {isNaN(tseManDep.find(e => dep.id === e?.id)?.sRa / tseManDep.find(e => dep.id === e?.id)?.nRa) ?
                                                        " no data"
                                                        :
                                                        Math.round((tseManDep.find(e => dep.id === e?.id)?.sRa / tseManDep.find(e => dep.id === e?.id)?.nRa) * 100) + " %"}
                                                </span>


                                            </div>

                                        </div>


                                        <ul>
                                            {listLine.filter(linF => dep.id === linF.idDepartment)
                                                .map(lin => {
                                                    return <li
                                                        key={lin.id}
                                                        style={{ margin: "1vw 1vw", color: "violet" }}
                                                    >
                                                        <div style={{ display: "flex" }}>
                                                            <span >{lin.name}</span>
                                                            <div
                                                            >
                                                                <span style={{
                                                                    margin: "0vw 1vw",
                                                                    color: "black"
                                                                }}>

                                                                    costo-budget:{pointToeconomicalValue(costiLinea.find(el => el.id === lin.id)?.costiInterventi) === 0 ? "0" : pointToeconomicalValue(costiLinea.find(el => el.id === lin.id)?.costiInterventi)}
                                                                    /
                                                                    {pointToeconomicalValue(listBudget
                                                                        .sort((a, b) => a.updatedAt.localeCompare(b.updatedAt))
                                                                        .find(e => e.idStructure === lin.id)?.value)} €
                                                                </span>
                                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                                    tempo totale manutenzione: {tManLin.find(el => el.id === lin.id)?.tempi} minuti
                                                                </span>
                                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                                    PMP: {
                                                                        isNaN(tpManLin.find(el => el.id === lin.id)?.tempEff
                                                                            /
                                                                            tManLin.find(el => el.id === lin.id)?.tempi)
                                                                            ?
                                                                            0
                                                                            :
                                                                            Math.round((tpManLin.find(el => el.id === lin.id)?.tempEff
                                                                                /
                                                                                tManLin.find(el => el.id === lin.id)?.tempi) * 100)

                                                                    } %
                                                                </span>
                                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                                    PMC: {ieManLin.find(el => el.id === lin.id)?.completeIntervention} / {ieManLin.find(el => el.id === lin.id)?.allIntervention}
                                                                </span>
                                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                                    On Time Delivery: {isNaN(otdManLin.find(el => el.id === lin.id)?.rapporto)
                                                                        ?
                                                                        "no data"
                                                                        :
                                                                        otdManLin.find(el => el.id === lin.id)?.rapporto
                                                                    } %
                                                                </span>
                                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                                    RTSTE: {isNaN(tseManLin
                                                                        .find(tseL => lin.id === tseL?.id)?.sR
                                                                        /
                                                                        tseManLin
                                                                            .find(tseL => lin.id === tseL?.id)?.nR)
                                                                        ?
                                                                        " no data"
                                                                        :
                                                                        Math.round((tseManLin
                                                                            .find(e => lin.id === e?.id)?.sR
                                                                            /
                                                                            tseManLin
                                                                                .find(e => lin.id === e?.id)?.nR) * 100) + " %"
                                                                    }
                                                                </span>
                                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                                    RTSTE-a: {isNaN(tseManLin.find(e => lin.id === e?.id)?.sRa / tseManLin.find(e => lin.id === e?.id)?.nRa) ?
                                                                        " no data"
                                                                        :
                                                                        Math.round((tseManLin.find(e => lin.id === e?.id)?.sRa / tseManLin.find(e => lin.id === e?.id)?.nRa) * 100) + " %"}
                                                                </span>

                                                            </div>
                                                        </div>

                                                        <ul style={{ flex: "10" }}>
                                                            {listAsset
                                                                .filter(assF => lin.id === assF.idLinea)
                                                                .map(ass => {
                                                                    return <AssetMetriche
                                                                        ass={ass}
                                                                        costiPerAsset={costiPerAsset}
                                                                        tManAss={tManAss}
                                                                        tpManAss={tpManAss}
                                                                        ieManAss={ieManAss}
                                                                        otdManAss={otdManAss}
                                                                        tseManAss={tseManAss}
                                                                        listPeriodicIntervention={listPeriodicIntervention}
                                                                        otdManIp={otdManIp}
                                                                        tseManIp={tseManIp}

                                                                    />
                                                                })}
                                                        </ul>
                                                    </li>
                                                })}
                                        </ul>
                                    </li>
                                })}
                            </ul>
                        </li>
                    })
                }
            </ul >
        </div >
    )

}


export default Metriche