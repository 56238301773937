import React, { useState } from "react";
import LogoImpostazioni from '../../../icon/settings.svg';
import LogoLogout from '../../../icon/logout.svg'
import { titleColor } from '../../../configurations/generalStye'
import { Auth } from "aws-amplify";
const PersIconaUtente = ({ logo, nomeUtente, ruolo }) => {



    const [isOpenIconaUtente, setIsOpenIconaUtente] = useState(false)

    const taggleOpenIconaUtente = () => {
        setIsOpenIconaUtente(!isOpenIconaUtente)
    }


    return (
        <div
            className="boxIconUser"

        >
            <input
                type="image"
                className="logoUtenteElement "
                src={logo}
                onClick={() => taggleOpenIconaUtente()}
                onBlur={() => setTimeout(()=>setIsOpenIconaUtente(false), 300)}
            />


            <div
            >
                <div
                    className="testoNomeUtente"
                >
                    {nomeUtente}
                </div>
                <div
                    className="testoRuoloUtente"
                >
                    {ruolo}
                </div>
            </div>
            {
                isOpenIconaUtente ?
                    <div
                        className="bodyMenuIconUser"
                    >
                        <div
                            className="menuIconUserLogout"

                        >
                            <img
                                //width={"0.8vw"}
                                src={LogoImpostazioni}
                                style={{ "color": `${titleColor}`,width:'1vw' }}
                            />
                            <h3
                                className="testoMenuUser"
                                style={{ "color": `${titleColor}` }}
                            >Impostazioni utente </h3>
                        </div>

                        <div
                            className="menuIconUserLogout"
                            onClick={
                                async() => {
                                    await Auth.signOut()
                                    window.location.reload(false)
                                }
                            }
                        >
                            <img
                                //width={"0.8vw"}
                                src={LogoLogout}
                                style={{ "color": `${titleColor}`,width:'1vw' }}
                            />
                            <h3
                                className="testoMenuUser"
                                style={{ "color": `${titleColor}` }}
                            >Logout</h3>
                        </div>

                    </div>
                    :
                    <span></span>
            }

        </div>
    )



}

export default PersIconaUtente