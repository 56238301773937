
import { useDispatch, useSelector } from "react-redux"
import { selectPlant } from "../../../structures/root/slices/plantSlice"
import { selectAllMachinery } from "../../../structures/root/slices/listMachinerySlice"
import { useEffect, useState } from "react"
import { selectUser } from "../../../structures/root/slices/datiUtenteSlice"
import { newReport, selectAllReport, updateReport } from "../../../structures/root/slices/reportSlice"
import { newIntervention, selectAllIntervention, createDataIntervention } from "../../../structures/root/slices/interventionSlice"
import Modal from "../../DialogModal/mainDialogModal"
import MainMainteneanceStory from "../../mainteneanceStory/mainManteneanceStory"
import { dataFormatoGMA } from "../../../functions/dateTime"
import { selectAllDepartment } from "../../../structures/root/slices/listDepartmentSlice"
import { selectAllLine } from "../../../structures/root/slices/listLineSlice"
import { DataObjectRounded } from "@mui/icons-material"
import { pointToeconomicalValue } from "../../../functions/generiche"


const FormUpdateMaintenance = ({ closeScheda, isOpen, reportToModify }) => {


    const listaImpianti = useSelector(selectPlant)
    const listaMacchine = useSelector(selectAllMachinery)
    const utente = useSelector(selectUser)
    const dispatch = useDispatch()

    const dataOdierna = new Date()

    const interventoToReport = useSelector(selectAllIntervention).find(el => el.id === reportToModify.idIntervention)
    const [dataOperazione, setDataOperazione] = useState(reportToModify.dataIntervento)
    //operazione al momento non viene utilizzata come parametro
    const [operazione, setOperazione] = useState(reportToModify.operazione)
    const [tempo, setTempo] = useState(reportToModify.durata)
    const [valoriLettura, setValoriLettura] = useState(reportToModify.valoriLettura)
    const [costo, setCosto] = useState(reportToModify.costo)
    const [note, setNote] = useState(reportToModify.note)

    //DA VEDERE CON IL NUOVO AGGIORNAMENTO DOUTO ALLA SEPARAZIONE DEI REPORT
    const [attivitaRisolte, setAttivitaRisolte] = useState(reportToModify.attivita)
    const [tutteAttivitaComplete, setTutteAttivitaComplete] = useState(false)

    const macchina = listaMacchine.find(el => el.id === reportToModify?.idMachinery)
    const impianto = listaImpianti.find(el => el.id === macchina?.idPlant)

    const [depart, setDepart] = useState({})
    const [linea, setLinea] = useState()
    const dataDepartment = useSelector(selectAllDepartment)
    const dataLinee = useSelector(selectAllLine)



    useEffect(() => {
        setLinea(dataLinee.find(el => el.id === macchina?.idLinea))

    }, [reportToModify])


    useEffect(() => {
        if (linea) { setDepart(dataDepartment.find(el => el.id === linea.idDepartment)) }
    }, [linea])


    useEffect(() => {

        if (interventoToReport) {

            if (interventoToReport.attivita) {

                if (interventoToReport.attivita[0]?.id) {

                    setAttivitaRisolte(interventoToReport.attivita.map(el => {

                        return {
                            ...el,
                            complated: reportToModify.attivita.find(elem => elem.id === el.id).complated,
                            timeEff: reportToModify.attivita.find(elem => elem.id === el.id).timeEff
                        }
                    }))


                }

            }

        }

    }, [reportToModify])


    useEffect(() => {
        if (attivitaRisolte.find(el => {

            return el.complated === false
        })) {
            setTutteAttivitaComplete(false)
        } else {
            setTutteAttivitaComplete(true)

        }

        if (attivitaRisolte.length !== 0) {
            let timeAll = 0

            attivitaRisolte.forEach(el => {

                if (el.timeEff && el.complated === true) {
                    timeAll = timeAll + el.timeEff
                }
            })
            if (timeAll !== 0) {
                setTempo(timeAll)
            } else {
                setTempo("")
            }

        }


    }, [attivitaRisolte])

    useEffect(() => {


    }, [tutteAttivitaComplete])

    //REFRESH AUTOMATICO QUANDO SI TROVANO SULLA STESSA MODIFICA
    /*
    useEffect(() => {
        if (reportToModify) {

            setDataOperazione(mode === "add" ? "" : reportToModify.dataIntervento)
            setOperazione(mode === "add" ? "" : reportToModify.operazione)
            setTempo(mode === "add" ? "" : reportToModify.durata)
            setValoriLettura(mode === "add" ? "" : reportToModify.valoriLettura)
            setCosto(mode === "add" ? "" : reportToModify.costo)
            setNote(mode === "add" ? "" : reportToModify.note)
            setAttivitaRisolte(mode === "add" ? [] : reportToModify.attivita)
        }
    }, [reportToModify])

*/
    

    return <Modal
        show={!isOpen}
    >
        <div className="bodyMainReportMainteneanceForm">
            <div
                className="boxTitleReportMainteneanceForm"
            >
                <h4
                    className="elementTitleReportMainteneanceFrom"
                >Modifica report</h4>
                <h3
                    className="elementClosignSchedaReportMainteneanceForm"
                    onClick={() => closeScheda()}
                >x</h3>
            </div>
            <div
                className="boxTitleReportMainteneanceForm"
            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Intervento</h5>
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"

                >{interventoToReport?.title}{/*datiIntervento.title.length>100?"...":""*/}</h5>
            </div>

            <div
                className="boxTitleReportMainteneanceForm"
            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Impianto</h5>
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"

                >{impianto ? impianto.nome : "non trovato"}</h5>
            </div>
            <div
                className="boxTitleReportMainteneanceForm"
            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Reparto</h5>
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"

                >{depart ? depart.name : "non trovato"}</h5>
            </div>
            <div
                className="boxTitleReportMainteneanceForm"
            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Linea</h5>
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"

                >{linea ? linea.name : "non trovato"}</h5>
            </div>
            <div
                className="boxTitleReportMainteneanceForm"
            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Tipo macchina</h5>
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"

                >{macchina ? macchina.familyId : "non trovato"}</h5>
            </div>
            <div
                className="boxTitleReportMainteneanceForm"
            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Nome macchina</h5>
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"

                >{macchina ? macchina.nome : "non trovato"}</h5>
            </div>
            <div
                className="boxTitleReportMainteneanceForm"
            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Id macchina</h5>
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"

                >{macchina ? macchina.identityCode : "non trovato"}</h5>
            </div>
            <div
                className="boxTitleReportMainteneanceForm"
            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Data intervento</h5>
                {


                    interventoToReport ?
                        <h5
                            className="elementSecondaryTitleReportMainteneanceForm"
                        >
                            {dataFormatoGMA(interventoToReport.date)}
                        </h5>
                        :
                        ""

                }
            </div>
            <div
                className="boxTitleReportMainteneanceForm elementNoteReportMaintenanceForm"

            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Note</h5>
                <span
                    className="elementNoteTextReportMainteneanceForm"
                >
                    {interventoToReport?.note}
                </span>
            </div>

            <div
                className="boxTitleReportMainteneanceForm"
            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Data operazione</h5>
                {

                    <input
                        className="elementInputFormReport"
                        type="date"
                        value={dataOperazione}
                        required
                        max={`${dataOdierna.getFullYear()}-${dataOdierna.getMonth() + 1 < 10 ?`0${dataOdierna.getMonth() + 1}`:`${dataOdierna.getMonth() + 1}`}-${dataOdierna.getDate() < 10 ?`0${dataOdierna.getDate()}`:`${dataOdierna.getDate()}`}`}
                        onInput={(e) => setDataOperazione(e.target.value)}
                    />
                }

            </div>
            {
                /* 
                <div
                className="boxTitleReportMainteneanceForm"
            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Operazione effettuata</h5>
                <input
                    className="elementInputFormReport"
                    type="text"
                    placeholder=""
                    onInput={(e) => setOperazione(e.target.value)}
                />
            </div>
                */
            }


            <div
                className="boxTitleReportMainteneanceForm"
            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Valori di lettura</h5>
                {

                    <input
                        className="elementInputFormReport"
                        type="text"
                        value={valoriLettura}
                        placeholder=""
                        onInput={(e) => setValoriLettura(e.target.value)}
                    />
                }
            </div>
            <div
                className="boxTitleReportMainteneanceForm"
            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Costi pezzi ricambio (EUR)</h5>
                {
                    <input
                        className="elementInputFormReport"
                        type="text"
                        placeholder=""
                        value={pointToeconomicalValue(costo)}
                        onInput={(e) => setCosto(e.target.value
                            //rimuove i punti quando presenti
                            .replace(/[.]/g, "")
                            //rimuove qualsiasi cosa non sia un numero
                            .replace(/[^0-9]/g, ""))}
                    />
                }
            </div>
            {
                attivitaRisolte.length !== 0 ?

                    <div>
                        <div
                            className="boxActivityList"
                        >
                            <div
                                className="boxActivityList"
                            >
                                <input type="checkbox"

                                    checked={tutteAttivitaComplete}
                                    onChange={() => setAttivitaRisolte(prev => prev.map(el => {
                                        return {
                                            ...el,
                                            complated: true
                                        }
                                    })
                                    )}
                                ></input>
                                <h5 className="elementSecondaryTitleReportMainteneanceForm">Attività</h5>
                            </div>
                            <h5 className="elementSecondaryTitleReportMainteneanceForm"></h5>

                            <h5 className="elementSecondaryTitleReportMainteneanceForm"> Tempo effettivo</h5>

                        </div>
                        <ul
                            className="elementListActivity"
                        >
                            {

                                attivitaRisolte.map((el, index) => {


                                    return (

                                        <li
                                            className="elementActivity"
                                            key={index}
                                        >
                                            <input type="checkbox"
                                                checked={el.complated}

                                                onChange={() => setAttivitaRisolte(prev => prev.map((el, i) =>
                                                    index === i ?
                                                        {
                                                            ...el,
                                                            complated: !el.complated
                                                        }
                                                        :
                                                        {
                                                            ...el
                                                        }
                                                ))}

                                            />
                                            <h5 className="elementSecondaryTitleReportMainteneanceForm elementFlexDimensionThree">
                                                {el.nomeAttivita + ` ${el.tempoStimato ? `(${el.tempoStimato} - min)` : ``} `}
                                            </h5>
                                            <input

                                                type="number"
                                                value={el.timeEff === 0 ? '' : el.timeEff}
                                                placeholder="min"
                                                className="elementInputFormReport elementInputMaxDimension "
                                                onChange={(e) => setAttivitaRisolte(prev => prev.map((el, i) =>
                                                    index === i ?
                                                        {
                                                            ...el,
                                                            timeEff: parseFloat(e.target.value)
                                                        }
                                                        :
                                                        {
                                                            ...el
                                                        }
                                                ))
                                                }
                                            />
                                        </li>
                                    )


                                })
                            }
                        </ul>
                    </div>
                    :
                    <span></span>
            }
            <div
                className="boxTitleReportMainteneanceForm"
            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Tempo effettivo</h5>
                {

                    <input
                        className="elementInputFormReport"
                        type="number"
                        placeholder="min"
                        onInput={(e) => setTempo(e.target.value)}
                        value={tempo}
                    />
                }
            </div>
            <div
                className="boxTitleReportMainteneanceForm"
                style={{ flexDirection: "column", alignItems: "inherit" }}
            >
                <h5
                    className="elementSecondaryTitleReportMainteneanceForm"
                >Note</h5>
                <textarea
                    value={note}
                    className="elementInputFormReport correctionNoteElementInputFormReport"
                    onInput={(e) => setNote(e.target.value)}

                />
            </div>
            {   /**
                 * attività da eseguire quando si riprogramma un intervento
                 */

                //caso in cui si sta compilando il report
                <div
                    className="boxPeriodicFormNewEvent"
                >
                    <button
                        className="elementButtonSubmitFormNewEvent"
                        onClick={() => closeScheda(e => !e)}
                    >Annulla
                    </button>

                    <button
                        className="elementButtonSubmitFormNewEvent"
                        onClick={(e) => {
                            e.target.disabled = true
                            const risp = dispatch(updateReport({
                                id: reportToModify.id,
                                idAsset: reportToModify.idMachinery,
                                idIntervention: reportToModify.idIntervention,
                                data: dataOperazione,
                                activity: JSON.stringify(attivitaRisolte.map(el => {
                                    return {
                                        id: el.id,
                                        complated: el.complated,
                                        timeEff: el.timeEff,
                                    }
                                })),
                                valoriLettura: valoriLettura,
                                costo: costo,
                                operation: operazione,
                                durata: tempo,
                                note: note
                            })).catch(err => {


                            }).then(el => {
                                //e.target.disabled = false
                                //closeScheda()
                                if (el.meta.requestStatus !== "rejected") {
                                    closeScheda()
                                } else {
                                    alert("errore dati report non aggiornati, ripetere l'operazione")
                                    console.log("errore nella creazione intervento")
                                    e.target.disabled = false
                                }

                            })




                        }}
                    >
                        Modifica report
                    </button>

                </div>
            }


        </div>
    </Modal>




}

export default FormUpdateMaintenance


