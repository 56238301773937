import {configureStore} from '@reduxjs/toolkit'
import plants, {plantAdded, plantMoreAdded} from './slices/plantSlice'
import dataPlant, {dataPlantAddMany} from './slices/dataPlantSlice'
import alertAlgoritm from './slices/alertAlgoritmSlice'
import intervention, {interventionAddOne, interventionLoad} from './slices/interventionSlice'
//import dati fittizzi
import {dataListPlant, plantList} from '../../configurations/strutturaDati/datiImpianti'
import {listaEventiManutentivi, listaReport, ticketList} from '../../configurations/strutturaDati/datiInterventi'
import {
    datiTarga,
    docDatiTargaData,
    familyData,
    faultDataMachinery,
    faultFamily,
    listaMacchinari
} from '../../configurations/strutturaDati/datiMacchine'

import notificationFilter from './slices/filterNotificationSlice'
import schedaMachineDetail from './slices/schedeMachineDetailSlice'
import listMachinery, {addMachinery, addOneMachinery} from './slices/listMachinerySlice'
import listLine, {addOneLine} from './slices/listLineSlice'
import listBudgets, {addOneBudget} from './slices/budgetSlice'
import listDepartment, { addOneDepartment } from './slices/listDepartmentSlice'
import fault, {addManyFault} from './slices/faultSlice'
import family, {translaterAddMany} from './slices/traslaterFamilyMachinery'
import datiTargaMachinery, {addOneDatiTargaMachinery} from './slices/datiTargaMachinerySlice'
import dataFaultMachinery, {dataFaultMachineryAddMany} from './slices/dataFaultMachinerySlice'
import docDatiTarga, {docDatiTargaAddMany} from './slices/docDatiTargaSlice'
import report, {reportAddMany, reportAddOne} from './slices/reportSlice'
import ticket, {ticketAddMany} from './slices/ticketSlice'
import datiUtente from './slices/datiUtenteSlice'
import dayCalendar from './slices/dayCalendarSlice'

import * as subscriptions from '../../graphql/subscriptions'

import {API} from 'aws-amplify'
import {GRAPHQL_AUTH_MODE} from '@aws-amplify/api'

import {PersistentMiddleware} from './persistentMiddleware'
import {preloadStateDefault} from '../../configurations/setting'


const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(PersistentMiddleware),
    reducer: {
        plants,
        dataPlant,
        alertAlgoritm,
        intervention,
        notificationFilter,
        schedaMachineDetail,
        listMachinery,
        listLine,
        listDepartment,
        listBudgets,
        fault,
        family,
        datiTargaMachinery,
        dataFaultMachinery,
        docDatiTarga,
        report,
        ticket,
        datiUtente,
        dayCalendar
    },

    //preloadedState: preloadStateDefault  //VA RIORGANIZZATA L'INIALIZZAZIONE PER NUOVE INSTALLAZIONI

})

/**
 * GESTIONE DELLE WEB SOCKET AL MOMENTO NON HO TROVATO ALCUNA SOLUZIONE PER EVITARE IL PROBLEMA O GESTIRLO AL MEGLIO IN QUESTO MODO FUNZIONA MA DEVO PREVEDERE DI INSERIRE LA WEBSOCKET IN UN LUOGO CHE SIA CONSONO
 */



/**
 * socket per la creazione di un nuovo intervento, solo l'intervento di base e non i dati intervento che vengono richiesti solo quando si fanno approfondimenti
 */


API.graphql({
    query: subscriptions.onCreateMainteneanceReports,
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
}).subscribe({
    next: ({provider, value}) => {
        store.dispatch(reportAddOne({
            idIntervention: value.data.onCreateMainteneanceReports.idIntervention,
            idMachinery: value.data.onCreateMainteneanceReports.idAsset,
            dataIntervento: value.data.onCreateMainteneanceReports.data,
            id: value.data.onCreateMainteneanceReports.id,
            operatore: value.data.onCreateMainteneanceReports.operatore,
            valoriLettura: value.data.onCreateMainteneanceReports.valoriLetture,
            costo: value.data.onCreateMainteneanceReports.costo,
            operazione: value.data.onCreateMainteneanceReports.operation,
            durata: value.data.onCreateMainteneanceReports.durata,
            note: value.data.onCreateMainteneanceReports.note,
            link: "/planning",
            active: true,
            attivita: JSON.parse(value.data.onCreateMainteneanceReports.activity),
            createdAt: value.data.onCreateMainteneanceReports.createdAt,
            updatedAt: value.data.onCreateMainteneanceReports.updatedAt
        }))

        //in questa parte va inserito la crezione di un nuovo intervento se questo è periodico


    },
    error: (error) => console.warn(error)
})


/**
 * inserimento dati provvisorio per gestione dallo store ed il funzionamento della piattaforma in demo
 */

/**
 * DA RIORGANIZZARE L'INIZIALIZZAZIONE PER UNA NUOVA INSTALLAZIONE
 */


/*
store.dispatch(alertAlgoritmLoad(listAlertProgram.map(el => {
  return {
    ...el,
    createdAt: "2023-06-20T14:39:25.925Z",
    updatedAt: "2023-06-20T14:39:25.925Z"
  }n
})))
*/



store.dispatch(addManyFault(faultFamily.map(el => {
    return {
        ...el,
        createdAt: "2023-06-20T14:39:25.925Z",
        updatedAt: "2023-06-20T14:39:25.925Z"
    }
})))

store.dispatch(translaterAddMany(familyData.map(el => {
    return {
        ...el,
        createdAt: "2023-06-20T14:39:25.925Z",
        updatedAt: "2023-06-20T14:39:25.925Z"
    }
})))





store.dispatch(dataFaultMachineryAddMany(faultDataMachinery.map(el => {
    return {
        ...el,
        createdAt: "2023-06-20T14:39:25.925Z",
        updatedAt: "2023-06-20T14:39:25.925Z"
    }
})))




export default store