const dataOdierna = new Date()
// ogni giorno sono 86400000
//new Date().toISOString().slice(0, 10)
const giorno = 86400000



/**
 * dati da visualizzare nell'Overview
 */
export const plantList = [
    /*{
        id: "ade31f4c-9195-4ba5",
        nome: "Acciaieria",
        posGeo: [40.723695, 14.702060],
        selected: true
    },*/
    /*
    {
        id: "abcdefgh-9195-4ba5",
        nome: "Via Cicogna",
        posGeo: [44.4716408,11.4294427],
        selected: false
    }
*/
    /*,
    {
        id: "80a86749-a940-434b",
        nome: "A2",
        posGeo: [42.1, 12.3],
        selected: true
    },
    {
        id: "f6a560ef-dfa0-46c2",
        nome: "A3",
        posGeo: [41.9, 12.6],
        selected: false
    }
    */
]


export const dataListPlant = [
    {
        id: "a41e-5c1d4302e6bf",
        period: 0,
        idImpianto: "ade31f4c-9195-4ba5",
        AverangeHealthIndicatorImpianto: 27,
        tassoDiDisponibilitaImpianto: 84,
        interventiEvitati: 60,
        timeStamp: "",
        selected: true
    },
    {
        id: "8f0c-a8720c82b0e2",
        period: 1,
        idImpianto: "ade31f4c-9195-4ba5",
        AverangeHealthIndicatorImpianto: 83,
        tassoDiDisponibilitaImpianto: 82,
        interventiEvitati: 55,
        dataCalculation: "",
        selected: false
    },
    {
        id: "8ef7-e68ad6bb6f40",
        period: 2,
        idImpianto: "ade31f4c-9195-4ba5",
        AverangeHealthIndicatorImpianto: 80,
        tassoDiDisponibilitaImpianto: 79,
        interventiEvitati: 62,
        dataCalculation: "",
        selected: false
    }
    /*
    ,
    {
        id: "a41e-5c2d4302e6bf",
        period: 0,
        idImpianto: "abcdefgh-9195-4ba5",
        AverangeHealthIndicatorImpianto: 38,
        tassoDiDisponibilitaImpianto: 86,
        interventiEvitati: 40,
        dataCalculation: "",
        selected: false
    },
    {
        id: "8f0c-a8820c82b0e2",
        period: 1,
        idImpianto: "abcdefgh-9195-4ba5",
        AverangeHealthIndicatorImpianto: 90,
        tassoDiDisponibilitaImpianto: 89,
        interventiEvitati: 48,
        dataCalculation: "",
        selected: false
    },
    {
        id: "8ef7-e69ad6bb6f40",
        period: 2,
        idImpianto: "abcdefgh-9195-4ba5",
        AverangeHealthIndicatorImpianto: 92,
        tassoDiDisponibilitaImpianto: 87,
        interventiEvitati: 60,
        dataCalculation: "",
        selected: false
    },
    */
    /*
    {
        id: "a41e-5c4d4302e6bf",
        period: 0,
        idImpianto: "f6a560ef-dfa0-46c2",
        AverangeHealthIndicatorImpianto: "--",
        tassoDiDisponibilitaImpianto: 85,
        interventiEvitati: 70,
        dataCalculation: "",
        selected: false
    },
    {
        id: "8f0c-a8920c82b0e2",
        period: 1,
        idImpianto: "f6a560ef-dfa0-46c2",
        AverangeHealthIndicatorImpianto: "--",
        tassoDiDisponibilitaImpianto: 74,
        interventiEvitati: 65,
        dataCalculation: "",
        selected: false
    },
    {
        id: "8ef7-e60ad6bb6f40",
        period: 2,
        idImpianto: "f6a560ef-dfa0-46c2",
        AverangeHealthIndicatorImpianto: "--",
        tassoDiDisponibilitaImpianto: 74,
        interventiEvitati: 40,
        dataCalculation: "",
        selected: false
    }
    */
]

export const listAlertProgram = [
    //posso sfruttare il passaggio di dati nell'apertura della pagina dove nel caso subito dopo aver cliccato sull'alert si avvia un ticket lo considero avviato tramite alert altrimenti se cambio pagina prima di inseirire un ticket lo condidero come avviato a partire dal fault. 
    {
        id: "a7a73bffd459",
        idImpianto: "ade31f4c-9195-4ba5",
        idPrevisionFault: "", //utilizzato dopo per tener traccia dell'avvio di un intervento da alert
        risk: 80, //calcolato dall'algoritmo che genera l'alert
        timeStamp: new Date(dataOdierna.getTime() + 10 * giorno).getTime(),
        /** il titolo può essere sostituito dall'id fault cosi da recuperare anche i dati dello specifico fault */
        title: "Guasto fase-fase",
        idMacchinario: "123abc",
        avviso: "Livello alto",
        active: true
    },
    {
        id: "a7a83bffd459",
        idImpianto: "ade31f4c-9195-4ba5",
        idPrevisionFault: "",
        avviso: "Livello alto",
        risk: 80,
        timeStamp: 1679670328710,
        title: "Guasto barra del rotore",
        idMacchinario: "123abc",
        active: true
    },
    {
        id: "a7a93bffd459",
        idImpianto: "ade31f4c-9195-4ba5",
        idPrevisionFault: "",
        avviso: "Livello alto",
        risk: 80,
        timeStamp: 1679670328710,
        title: "Guasto canalina interna del cuscinetto  ",
        idMacchinario: "123abc",
        active: true
    },
    {
        id: "a7a74bffd459",
        idImpianto: "ade31f4c-9195-4ba5",
        idPrevisionFault: "",
        avviso: "Livello alto",
        risk: 80,
        timeStamp: 1679670328710,
        title: "Guasto elemento rotante del cuscinetto",
        idMacchinario: "123abc",
        active: true
    },
    {
        id: "a7a73cffd459",
        idImpianto: "ade31f4c-9195-4ba5",
        idPrevisionFault: "",
        avviso: "Livello alto",
        risk: 80,
        timeStamp: 1679670328710,
        title: "Eccentricità del rotore",
        idMacchinario: "123abc",
        active: true
    },
    /*
    ,
    {
        id: "a7a93bf1d459",
        idImpianto: "80a86749-a940-434b",
        idPrevisionFault: "",
        avviso: "Livello alto",
        risk: 80,
        timeStamp: 1679670328710,
        title: "Guasto canalina interna del cuscinetto  ",
        idMacchinario: "85eu93",
        active:true
    }, 
    {
        id: "a7a74bf2d459",
        idImpianto: "80a86749-a940-434b",
        idPrevisionFault: "",
        avviso: "Livello alto",
        risk: 80,
        timeStamp: 1679670328710,
        title: "Guasto elemento rotante del cuscinetto",
        idMacchinario: "85eu93",
        active:true
    }, 
    {
        id: "a7a73cf3d459",
        idImpianto: "80a86749-a940-434b",
        idPrevisionFault: "",
        risk: 80,
        timeStamp: 1679670328710,
        title: "Eccentricità del rotore",
        idMacchinario: "85eu93",
        avviso: "Livello alto",
        active:true
    }


    */


]




/**
 * Impianti Additivi
Colata Continua 1
Area Danarc
Area EAF
Area Fossa
Area finiture raffreddamenti spedizioni CC3
Impianto LF1-VD1
Aree rifacimenti
Area Siviere
Global Blue
Luna
Marte
SSE
Strixus
VSA
Campata 1
Carriponti
Cond
CS3 / CS4
CTT
Fumi-Acque
FTT
Lam
MCC1
MMB
Nuovo Blooming
Qualisteel
Qualità
Vergella area di condizionamento
Rotoforgia
Spe
SST
Wire
Elti WTP Marte
Impianti Fumi
Impianti Prima Pioggia
Rilancio in Roggia
Stazione di compressione
Area VSA
WTP 1
Area Granigliatrice
Area Impaccatore
Area Kocks
Area Troncatrici
Bordioni
Area CND
Area DQS
Forno Ona Chamber
Area forno WB
Lavorazioni Fuori Linea
Area Placca 75
Presbozzo
Area Blooming 1000
Finitura Blooming
Area Forgia
LFC
Area Reversibile 800
Area Rotoforgia
Trattamenti Termici
Area BGV
Area TMB
EDC
Forno WB
Area PFB
Area Sund
Area TFS
FTT
Qualisteel
Area DQS

 */