/**
 * 
 * @param {*} listIntervention 
 * @param {*} listPeriodicIntervention 
 * @param {*} listInterventioAttuative  prende gia in ingresso gli attuativi riferiti al perido che di default sono quelli relativi all'anno 2024
 * @param {*} listReport 
 * @returns 
 */

export const RTSTEAttivitaIntervento = (listIntervention, listPeriodicIntervention, listInterventioAttuative, listReport) => {
    return listPeriodicIntervention.map(intp => {

        /**
         *  intervento peridico
         * |
         * | eventuali report collegato     eventuali report
         * |            |                      |
         * ------> attuativo   --------> riprogrammato
         * |
         * |                                        report
         * |                                            |
         * ------> attuativo  -> riprogrammato -> riprogrammato -> riprogrammato -> riprogrammato ...
         * |
        */
        //se nell'intervento periodico non è detinito un tempo totale allora non verranno generate metriche per quell'intervento
        //questo implica che la condizione rapporto uguale a "no data" sta ad indicare che la metrica è nulla
        // un ulteriore conseguenza è che non verranno inserite nella metricha tutte le attività aggiunte tramite la modifica di interventi attuativi e successivi
        //verranno inoltre escluse dalla metrica tutte le attività del periodico che non riportano un tempo stimato
        // non entreranno nella metrica nemmeno le attività svolte che però non hanno un tempo effettivo di esecuzione


        //verifichiamo che un intervento abbia una durata totale diversa da zero, per quegli interventi non calcoliamo la metrica
        if (intp?.durata && intp?.durata !== 0 && intp?.durata !== "0") {

            //verifica degli interventi con attività
            //il codice è nettamente più complesso poiché gli interventi senza attività possono avere solo un report anche se ci sono più interventi derivati per riprogrammazione
            if (intp?.attivita) {
                if (intp.attivita.length !== 0) {

                    // per ogni intervento periodico andiamo a raccogliere gli attuativi e tutti gli eventuali riprogrammati con i relativi report
                    let attuativi = listInterventioAttuative
                        .filter(el =>
                            el.idPrevIntervention === intp.id
                           
                        )

                    //per ogni intervento attuativo devo estrare la singola catena di eventuali altri interventi al fine di recuperare tutti i report correlati allo specifico attuativo. Una volta ottenuti tutti i report si passa all'analisi delle singole attività
                    const paramentriLocaliAttuativi = attuativi.map(att => {
                        let baseDiRicerca = att
                        let vettoreInter = [att]
                        while (baseDiRicerca) {
                            baseDiRicerca = listIntervention.find(el => el.idPrevIntervention === baseDiRicerca.id)
                            if (baseDiRicerca) {
                                vettoreInter.push(baseDiRicerca)
                            }
                        }
                        //ricerchiamo i report collegati alla catena di interventi collegata
                        const matriceReport = vettoreInter
                            .map(el => listReport
                                .filter(rep => rep.idIntervention === el.id && rep.isDeleted !== true))
                        let listaOrdinataReport = []
                        matriceReport.forEach(el => listaOrdinataReport.push(...el))
                        //ordinamento dei report in funzione della data di aggiornamento dal più aggiorna al più vecchio per ottimizzare la funzione nel prendere per buono il primo valore del tempo e scartare gli altri
                        listaOrdinataReport = listaOrdinataReport.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))

                        //estraiamo il tempo generale definito per l'intero intervento attuativo
                        //questo valore viene definito o dalla somma dei tempi delle singole attività o in alternativa inserito manualmente dagli operatori
                        //utilizziamo questa metrica come contrapposizione a quelle delle attività sia come verifica che come affidabilità della metrica
                        let timeAllRep = 0

                        listaOrdinataReport.forEach(repo => {
                            if (!isNaN(Number(repo.durata))) {
                                timeAllRep = Number(repo.durata) + timeAllRep
                            }

                        })

                        //stiamo ancora lavorando all'attuativo quindi tiriamo fuori i tempi effettivi rispetto alle attività definite nel periodico
                        let listAttivita = intp.attivita.map(attivi => {
                            if (attivi.tempoStimato !== 0 && attivi.tempoStimato !== "") {
                                return {
                                    idAtt: attivi.id,
                                    tStim: attivi?.tempoStimato,
                                    nome: attivi?.nomeAttivita,
                                    //lista ordianta dei report per lo specifico attuativo
                                    tEff: listaOrdinataReport.reduce((accumulator, currentValue) => {
                                        if (accumulator === undefined) {
                                            const repAtt = currentValue.attivita.find(el => attivi.id === el.id)
                                            //ritorna al'attivita dello specifico report
                                            if (repAtt) {
                                                if (repAtt.complated) {
                                                    if (repAtt.timeEff !== "" && repAtt.timeEff !== 0) {
                                                        return repAtt.timeEff
                                                    } else {
                                                        return undefined
                                                    }
                                                } else {
                                                    return undefined
                                                }
                                            } else {
                                                return undefined
                                            }

                                        } else {
                                            return accumulator
                                        }
                                    }, undefined)
                                }
                            } else {
                                return {
                                    idAtt: attivi.id,
                                    nome: attivi?.nomeAttivita,
                                    tStim: attivi?.tempoStimato,
                                    tEff: undefined
                                }
                            }
                        })




                        //devo ritornare il vettore di attività con id e tempo effettivo per il singolo ramo attuativo identificato da idAttuativo, idAttività, teff dell'attività eseguita sul ramo
                        return {
                            id: att.id,
                            durata: timeAllRep,
                            attivita: listAttivita
                        }
                    })
                    const value = paramentriLocaliAttuativi.reduce((accumul, item) => {
                        //condizione per la prima iniazializzaizone

                        if (accumul === undefined) {
                            return item.attivita.map(attt => {

                                return {
                                    idAtt: attt.idAtt,
                                    nome: attt.nome,
                                    tStim: attt.tStim,
                                    sEff: attt.tEff === undefined ? 0 : attt.tEff,
                                    nVUsefull: attt.tEff === undefined ? 0 : 1
                                }
                            })


                        } else {
                            //ci troviamo nel caso del secondo elemento del vettore dove accumul è già stato inizializzato
                            return item.attivita.map((attt, index) => {

                                return {
                                    idAtt: attt.idAtt,
                                    nome: attt.nome,
                                    tStim: attt.tStim,
                                    sEff: attt.tEff === undefined
                                        ?
                                        accumul.find(a => a.idAtt === attt.idAtt).sEff
                                        :
                                        attt.tEff + accumul.find(a => a.id === attt.id).sEff,


                                    nVUsefull: attt.tEff === undefined
                                        ?
                                        accumul.find(a => a.idAtt === attt.idAtt).nVUsefull
                                        :
                                        accumul.find(a => a.idAtt === attt.idAtt).nVUsefull + 1
                                }
                            })



                        }
                    }, undefined
                        //esempio di elemento da estrarre per il calcolo finale della metrica
                        //,[{idAtt: 0,nome:"",tStim: "",sEff:2,nVUsefull:2}]
                    )
                    if (value) {
                        const attivit = value.map(dFi => {
                            return {
                                idAtt: dFi.idAtt,
                                nome: dFi.nome,
                                RTSTE: dFi.tStim / (dFi.sEff / dFi.nVUsefull)
                            }
                        })
                        let RTSTEa = attivit.reduce((a, b) => {

                            if (a === undefined) {
                                return {
                                    somma: isNaN(b.RTSTE) ? 0 : b.RTSTE,
                                    count: isNaN(b.RTSTE) ? 0 : 1
                                }
                            } else {
                                return {
                                    somma: isNaN(b.RTSTE) ? a.somma : a.somma + b.RTSTE,
                                    count: isNaN(b.RTSTE) ? a.count : a.count + 1
                                }
                            }
                        }, undefined)

                        let durataMedia = 0
                        let conto = 0
                        paramentriLocaliAttuativi.forEach(eleme => {
                            if (eleme.durata !== 0) {
                                durataMedia = durataMedia + Number(eleme.durata)
                                conto = conto + 1
                            }
                        })


                        return {
                            id: intp.id,
                            RTSTE: intp.durata / (durataMedia / conto),
                            RTSTEa: RTSTEa.somma / RTSTEa.count,
                            attivita: attivit
                        }
                    }


                } else {
                    //caso in cui nell'intervento peridico viene definito un tempo stimato ma non sono presenti attività quindi abbiamo che per ogni ramo di un intervento periodico abbiamo un unico report che può essere eventualmente modificato ma solo uno.
                    // per ogni intervento periodico andiamo a raccogliere gli attuativi e tutti gli eventuali riprogrammati con i relativi report
                    let attuativi = listInterventioAttuative
                        .filter(el =>
                            el.idPrevIntervention === intp.id
                           
                        )


                    const paramentriLocaliAttuativi = attuativi.map(att => {
                        //ricerca di tutti gli interventi derivati dagli attuativi
                        let baseDiRicerca = att
                        let vettoreInter = [att]
                        while (baseDiRicerca) {
                            baseDiRicerca = listIntervention.find(el => el.idPrevIntervention === baseDiRicerca.id)
                            if (baseDiRicerca) {
                                vettoreInter.push(baseDiRicerca)
                            }
                        }

                        //ricerchiamo i report collegati alla catena di interventi collegata
                        const matriceReport = vettoreInter.map(el => listReport.filter(rep => rep.idIntervention === el.id))
                        let listaOrdinataReport = []
                        matriceReport.forEach(el => listaOrdinataReport.push(...el))
                        //ordinamento dei report in funzione della data di aggiornamento dal più aggiorna al più vecchio per ottimizzare la funzione nel prendere per buono il primo valore del tempo e scartare gli altri
                        listaOrdinataReport = listaOrdinataReport.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))

                        //estraiamo il tempo generale definito per l'intero intervento attuativo
                        //questo valore viene definito o dalla somma dei tempi delle singole attività o in alternativa inserito manualmente dagli operatori
                        //utilizziamo questa metrica come contrapposizione a quelle delle attività sia come verifica che come affidabilità della metrica
                        let timeAllRep = 0

                        listaOrdinataReport.forEach(repo => {
                            if (!isNaN(Number(repo.durata))) {
                                timeAllRep = Number(repo.durata) + timeAllRep
                            }

                        })







                        //devo ritornare il vettore di attività con id e tempo effettivo per il singolo ramo attuativo identificato da idAttuativo, idAttività, teff dell'attività eseguita sul ramo
                        return {
                            id: att.id,
                            durata: timeAllRep,

                        }
                    })
                    let durataMedia = 0
                    let conto = 0
                    paramentriLocaliAttuativi.forEach(eleme => {
                        if (eleme.durata !== 0) {
                            durataMedia = durataMedia + eleme.durata
                            conto = conto + 1
                        }
                    })

                    return {
                        nome: intp.title,
                        id: intp.id,
                        RTSTE: intp.durata / (durataMedia / conto)
                    }

                }


            }

        } else {
            return {
                nome: intp.title,
                id: intp.id
            }
        }
    })
}
