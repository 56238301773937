import './mainMap.css'
import React from 'react';
import LeafletMap from './subElements/leafletMap';
import { selectPlants, selectedPlant } from '../../structures/root/slices/plantSlice'
import { useSelector, useDispatch } from 'react-redux';

const Mappa = () => {
    const plants = useSelector(selectPlants)
    const dispatch= useDispatch()
    const selezioneImpianto = (plantid)=> dispatch(selectedPlant(plantid))

    return (
        

        <LeafletMap
            plants= {plants}
            selezioneImpianto={selezioneImpianto}
        />

    )
}

export default Mappa