/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPlants = /* GraphQL */ `
  mutation CreatePlants(
    $input: CreatePlantsInput!
    $condition: ModelPlantsConditionInput
  ) {
    createPlants(input: $input, condition: $condition) {
      id
      name
      posGeo
      via
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlants = /* GraphQL */ `
  mutation UpdatePlants(
    $input: UpdatePlantsInput!
    $condition: ModelPlantsConditionInput
  ) {
    updatePlants(input: $input, condition: $condition) {
      id
      name
      posGeo
      via
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlants = /* GraphQL */ `
  mutation DeletePlants(
    $input: DeletePlantsInput!
    $condition: ModelPlantsConditionInput
  ) {
    deletePlants(input: $input, condition: $condition) {
      id
      name
      posGeo
      via
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDataPlants = /* GraphQL */ `
  mutation CreateDataPlants(
    $input: CreateDataPlantsInput!
    $condition: ModelDataPlantsConditionInput
  ) {
    createDataPlants(input: $input, condition: $condition) {
      idPlant
      id
      params
      linkDataGraph
      period
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDataPlants = /* GraphQL */ `
  mutation UpdateDataPlants(
    $input: UpdateDataPlantsInput!
    $condition: ModelDataPlantsConditionInput
  ) {
    updateDataPlants(input: $input, condition: $condition) {
      idPlant
      id
      params
      linkDataGraph
      period
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDataPlants = /* GraphQL */ `
  mutation DeleteDataPlants(
    $input: DeleteDataPlantsInput!
    $condition: ModelDataPlantsConditionInput
  ) {
    deleteDataPlants(input: $input, condition: $condition) {
      idPlant
      id
      params
      linkDataGraph
      period
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDepartments = /* GraphQL */ `
  mutation CreateDepartments(
    $input: CreateDepartmentsInput!
    $condition: ModelDepartmentsConditionInput
  ) {
    createDepartments(input: $input, condition: $condition) {
      id
      name
      idPlant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDepartments = /* GraphQL */ `
  mutation UpdateDepartments(
    $input: UpdateDepartmentsInput!
    $condition: ModelDepartmentsConditionInput
  ) {
    updateDepartments(input: $input, condition: $condition) {
      id
      name
      idPlant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDepartments = /* GraphQL */ `
  mutation DeleteDepartments(
    $input: DeleteDepartmentsInput!
    $condition: ModelDepartmentsConditionInput
  ) {
    deleteDepartments(input: $input, condition: $condition) {
      id
      name
      idPlant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLineas = /* GraphQL */ `
  mutation CreateLineas(
    $input: CreateLineasInput!
    $condition: ModelLineasConditionInput
  ) {
    createLineas(input: $input, condition: $condition) {
      id
      name
      idDepartment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLineas = /* GraphQL */ `
  mutation UpdateLineas(
    $input: UpdateLineasInput!
    $condition: ModelLineasConditionInput
  ) {
    updateLineas(input: $input, condition: $condition) {
      id
      name
      idDepartment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLineas = /* GraphQL */ `
  mutation DeleteLineas(
    $input: DeleteLineasInput!
    $condition: ModelLineasConditionInput
  ) {
    deleteLineas(input: $input, condition: $condition) {
      id
      name
      idDepartment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBudgets = /* GraphQL */ `
  mutation CreateBudgets(
    $input: CreateBudgetsInput!
    $condition: ModelBudgetsConditionInput
  ) {
    createBudgets(input: $input, condition: $condition) {
      idStructure
      id
      year
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBudgets = /* GraphQL */ `
  mutation UpdateBudgets(
    $input: UpdateBudgetsInput!
    $condition: ModelBudgetsConditionInput
  ) {
    updateBudgets(input: $input, condition: $condition) {
      idStructure
      id
      year
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBudgets = /* GraphQL */ `
  mutation DeleteBudgets(
    $input: DeleteBudgetsInput!
    $condition: ModelBudgetsConditionInput
  ) {
    deleteBudgets(input: $input, condition: $condition) {
      idStructure
      id
      year
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssets = /* GraphQL */ `
  mutation CreateAssets(
    $input: CreateAssetsInput!
    $condition: ModelAssetsConditionInput
  ) {
    createAssets(input: $input, condition: $condition) {
      id
      idCode
      idFamily
      idPlant
      idLinea
      idDepartment
      name
      reparto
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssets = /* GraphQL */ `
  mutation UpdateAssets(
    $input: UpdateAssetsInput!
    $condition: ModelAssetsConditionInput
  ) {
    updateAssets(input: $input, condition: $condition) {
      id
      idCode
      idFamily
      idPlant
      idLinea
      idDepartment
      name
      reparto
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssets = /* GraphQL */ `
  mutation DeleteAssets(
    $input: DeleteAssetsInput!
    $condition: ModelAssetsConditionInput
  ) {
    deleteAssets(input: $input, condition: $condition) {
      id
      idCode
      idFamily
      idPlant
      idLinea
      idDepartment
      name
      reparto
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDataTargaAssets = /* GraphQL */ `
  mutation CreateDataTargaAssets(
    $input: CreateDataTargaAssetsInput!
    $condition: ModelDataTargaAssetsConditionInput
  ) {
    createDataTargaAssets(input: $input, condition: $condition) {
      idAsset
      imageURL
      yearProduction
      yearInstallation
      producer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDataTargaAssets = /* GraphQL */ `
  mutation UpdateDataTargaAssets(
    $input: UpdateDataTargaAssetsInput!
    $condition: ModelDataTargaAssetsConditionInput
  ) {
    updateDataTargaAssets(input: $input, condition: $condition) {
      idAsset
      imageURL
      yearProduction
      yearInstallation
      producer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDataTargaAssets = /* GraphQL */ `
  mutation DeleteDataTargaAssets(
    $input: DeleteDataTargaAssetsInput!
    $condition: ModelDataTargaAssetsConditionInput
  ) {
    deleteDataTargaAssets(input: $input, condition: $condition) {
      idAsset
      imageURL
      yearProduction
      yearInstallation
      producer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDocAssets = /* GraphQL */ `
  mutation CreateDocAssets(
    $input: CreateDocAssetsInput!
    $condition: ModelDocAssetsConditionInput
  ) {
    createDocAssets(input: $input, condition: $condition) {
      idAsset
      id
      name
      desc
      link
      note
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDocAssets = /* GraphQL */ `
  mutation UpdateDocAssets(
    $input: UpdateDocAssetsInput!
    $condition: ModelDocAssetsConditionInput
  ) {
    updateDocAssets(input: $input, condition: $condition) {
      idAsset
      id
      name
      desc
      link
      note
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDocAssets = /* GraphQL */ `
  mutation DeleteDocAssets(
    $input: DeleteDocAssetsInput!
    $condition: ModelDocAssetsConditionInput
  ) {
    deleteDocAssets(input: $input, condition: $condition) {
      idAsset
      id
      name
      desc
      link
      note
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssetsHI = /* GraphQL */ `
  mutation CreateAssetsHI(
    $input: CreateAssetsHIInput!
    $condition: ModelAssetsHIConditionInput
  ) {
    createAssetsHI(input: $input, condition: $condition) {
      idAsset
      hi
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssetsHI = /* GraphQL */ `
  mutation UpdateAssetsHI(
    $input: UpdateAssetsHIInput!
    $condition: ModelAssetsHIConditionInput
  ) {
    updateAssetsHI(input: $input, condition: $condition) {
      idAsset
      hi
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssetsHI = /* GraphQL */ `
  mutation DeleteAssetsHI(
    $input: DeleteAssetsHIInput!
    $condition: ModelAssetsHIConditionInput
  ) {
    deleteAssetsHI(input: $input, condition: $condition) {
      idAsset
      hi
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFamily = /* GraphQL */ `
  mutation CreateFamily(
    $input: CreateFamilyInput!
    $condition: ModelFamilyConditionInput
  ) {
    createFamily(input: $input, condition: $condition) {
      id
      familyName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFamily = /* GraphQL */ `
  mutation UpdateFamily(
    $input: UpdateFamilyInput!
    $condition: ModelFamilyConditionInput
  ) {
    updateFamily(input: $input, condition: $condition) {
      id
      familyName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFamily = /* GraphQL */ `
  mutation DeleteFamily(
    $input: DeleteFamilyInput!
    $condition: ModelFamilyConditionInput
  ) {
    deleteFamily(input: $input, condition: $condition) {
      id
      familyName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFaults = /* GraphQL */ `
  mutation CreateFaults(
    $input: CreateFaultsInput!
    $condition: ModelFaultsConditionInput
  ) {
    createFaults(input: $input, condition: $condition) {
      idFamily
      id
      name
      desc
      component
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFaults = /* GraphQL */ `
  mutation UpdateFaults(
    $input: UpdateFaultsInput!
    $condition: ModelFaultsConditionInput
  ) {
    updateFaults(input: $input, condition: $condition) {
      idFamily
      id
      name
      desc
      component
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFaults = /* GraphQL */ `
  mutation DeleteFaults(
    $input: DeleteFaultsInput!
    $condition: ModelFaultsConditionInput
  ) {
    deleteFaults(input: $input, condition: $condition) {
      idFamily
      id
      name
      desc
      component
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDataFaults = /* GraphQL */ `
  mutation CreateDataFaults(
    $input: CreateDataFaultsInput!
    $condition: ModelDataFaultsConditionInput
  ) {
    createDataFaults(input: $input, condition: $condition) {
      idAsset
      id
      idFault
      hi
      RUL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDataFaults = /* GraphQL */ `
  mutation UpdateDataFaults(
    $input: UpdateDataFaultsInput!
    $condition: ModelDataFaultsConditionInput
  ) {
    updateDataFaults(input: $input, condition: $condition) {
      idAsset
      id
      idFault
      hi
      RUL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDataFaults = /* GraphQL */ `
  mutation DeleteDataFaults(
    $input: DeleteDataFaultsInput!
    $condition: ModelDataFaultsConditionInput
  ) {
    deleteDataFaults(input: $input, condition: $condition) {
      idAsset
      id
      idFault
      hi
      RUL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlgoritmsAlerts = /* GraphQL */ `
  mutation CreateAlgoritmsAlerts(
    $input: CreateAlgoritmsAlertsInput!
    $condition: ModelAlgoritmsAlertsConditionInput
  ) {
    createAlgoritmsAlerts(input: $input, condition: $condition) {
      id
      idPlant
      idDataFault
      idAsset
      risk
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlgoritmsAlerts = /* GraphQL */ `
  mutation UpdateAlgoritmsAlerts(
    $input: UpdateAlgoritmsAlertsInput!
    $condition: ModelAlgoritmsAlertsConditionInput
  ) {
    updateAlgoritmsAlerts(input: $input, condition: $condition) {
      id
      idPlant
      idDataFault
      idAsset
      risk
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlgoritmsAlerts = /* GraphQL */ `
  mutation DeleteAlgoritmsAlerts(
    $input: DeleteAlgoritmsAlertsInput!
    $condition: ModelAlgoritmsAlertsConditionInput
  ) {
    deleteAlgoritmsAlerts(input: $input, condition: $condition) {
      id
      idPlant
      idDataFault
      idAsset
      risk
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTickets = /* GraphQL */ `
  mutation CreateTickets(
    $input: CreateTicketsInput!
    $condition: ModelTicketsConditionInput
  ) {
    createTickets(input: $input, condition: $condition) {
      idAsset
      id
      operatore
      fault
      title
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTickets = /* GraphQL */ `
  mutation UpdateTickets(
    $input: UpdateTicketsInput!
    $condition: ModelTicketsConditionInput
  ) {
    updateTickets(input: $input, condition: $condition) {
      idAsset
      id
      operatore
      fault
      title
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTickets = /* GraphQL */ `
  mutation DeleteTickets(
    $input: DeleteTicketsInput!
    $condition: ModelTicketsConditionInput
  ) {
    deleteTickets(input: $input, condition: $condition) {
      idAsset
      id
      operatore
      fault
      title
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInterventions = /* GraphQL */ `
  mutation CreateInterventions(
    $input: CreateInterventionsInput!
    $condition: ModelInterventionsConditionInput
  ) {
    createInterventions(input: $input, condition: $condition) {
      idAsset
      idPrevIntervention
      isDeleted
      id
      data
      ora
      idFault
      period
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInterventions = /* GraphQL */ `
  mutation UpdateInterventions(
    $input: UpdateInterventionsInput!
    $condition: ModelInterventionsConditionInput
  ) {
    updateInterventions(input: $input, condition: $condition) {
      idAsset
      idPrevIntervention
      isDeleted
      id
      data
      ora
      idFault
      period
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInterventions = /* GraphQL */ `
  mutation DeleteInterventions(
    $input: DeleteInterventionsInput!
    $condition: ModelInterventionsConditionInput
  ) {
    deleteInterventions(input: $input, condition: $condition) {
      idAsset
      idPrevIntervention
      isDeleted
      id
      data
      ora
      idFault
      period
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDataInterventions = /* GraphQL */ `
  mutation CreateDataInterventions(
    $input: CreateDataInterventionsInput!
    $condition: ModelDataInterventionsConditionInput
  ) {
    createDataInterventions(input: $input, condition: $condition) {
      idAsset
      id
      idPlant
      durata
      reparto
      activity
      operatore
      creatore
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDataInterventions = /* GraphQL */ `
  mutation UpdateDataInterventions(
    $input: UpdateDataInterventionsInput!
    $condition: ModelDataInterventionsConditionInput
  ) {
    updateDataInterventions(input: $input, condition: $condition) {
      idAsset
      id
      idPlant
      durata
      reparto
      activity
      operatore
      creatore
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDataInterventions = /* GraphQL */ `
  mutation DeleteDataInterventions(
    $input: DeleteDataInterventionsInput!
    $condition: ModelDataInterventionsConditionInput
  ) {
    deleteDataInterventions(input: $input, condition: $condition) {
      idAsset
      id
      idPlant
      durata
      reparto
      activity
      operatore
      creatore
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMainteneanceReports = /* GraphQL */ `
  mutation CreateMainteneanceReports(
    $input: CreateMainteneanceReportsInput!
    $condition: ModelMainteneanceReportsConditionInput
  ) {
    createMainteneanceReports(input: $input, condition: $condition) {
      idAsset
      id
      idIntervention
      data
      operatore
      isDeleted
      valoriLettura
      activity
      costo
      operation
      durata
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMainteneanceReports = /* GraphQL */ `
  mutation UpdateMainteneanceReports(
    $input: UpdateMainteneanceReportsInput!
    $condition: ModelMainteneanceReportsConditionInput
  ) {
    updateMainteneanceReports(input: $input, condition: $condition) {
      idAsset
      id
      idIntervention
      data
      operatore
      isDeleted
      valoriLettura
      activity
      costo
      operation
      durata
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMainteneanceReports = /* GraphQL */ `
  mutation DeleteMainteneanceReports(
    $input: DeleteMainteneanceReportsInput!
    $condition: ModelMainteneanceReportsConditionInput
  ) {
    deleteMainteneanceReports(input: $input, condition: $condition) {
      idAsset
      id
      idIntervention
      data
      operatore
      isDeleted
      valoriLettura
      activity
      costo
      operation
      durata
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
