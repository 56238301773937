import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { API } from "aws-amplify";
import { addPlant, updatePlant } from "../../../structures/root/slices/plantSlice";
import { newBudget, updateBudget } from "../../../structures/root/slices/budgetSlice";
import { pointToeconomicalValue } from "../../../functions/generiche";


const PersFormAddNewStabilimento = ({ close, mode, objectToModify, budgetToModify }) => {

    const dispatch = useDispatch()
    const apiName = 'geo';
    const path = `/geocodification`;
    const [risposte, setRisposte] = useState([])

    //REFRESH AUTOMATICO QUANDO SI TROVANO SULLA STESSA MODIFICA
    useEffect(() => {

        setName((mode === "add" ? null : objectToModify.nome))
        setBudget(mode === "add" ? null : budgetToModify.value)
        setVia(mode === "add" ? null : objectToModify.via)
    }, [objectToModify, budgetToModify])
    const funzione = (testo) => {

        API.post(
            apiName,
            path,
            {
                body: {
                    key: testo
                }
            }
        ).then((ris) => setRisposte(ris.Results)).catch(err => console.log("err: ", err))

    }

    const [name, setName] = useState(mode === "add" ? null : objectToModify.nome);
    const [budget, setBudget] = useState(mode === "add" ? null : budgetToModify.value);
    const [via, setVia] = useState(mode === "add" ? null : objectToModify.via);
    console.log("valore da coricare in piattaforma",budget)
    const validField = (fieldValue) => {
        return fieldValue.toString() != null && fieldValue.toString().length > 0
    }

    const validPosGeo = (posGeoValue) => {
        /* funzione che prende in ingresso il valore della via inserita nel form
        e controlla che la via matcha col valore del campo Place.Label dell'API
         */
        const posGeoFound = risposte.find(item => item.Place.Label === posGeoValue);

        if (posGeoFound) {
            return posGeoFound;
        } else {
            alert("Nessuna posizione geografica trovata")
        }
    }


    const handleChangeBudget = (e) => {
       
        setBudget(e.target.value.length === 0 ? null : e.target.value
            //rimuove i punti quando presenti
            .replace(/[.]/g,"")
            //rimuove qualsiasi cosa non sia un numero
            .replace(/[^0-9]/g,""))
    }

    const handleCreateStabilimento = () => {

        if (validField(budget) && validField(name)) {

            let indirizzoValue = document.getElementById("posGeoNewStabilimentoInput").value

            let posGeoValue = validPosGeo(indirizzoValue)
            if (posGeoValue) {
                let latLongValue = posGeoValue.Place.Geometry.Point;

                const response = dispatch(addPlant(
                    {
                        name: name,
                        posGeo: latLongValue.reverse(),
                        via: indirizzoValue

                    }
                ))

                Promise.all([response]).then(ris => {
                    console.log("risposta creazione", ris)
                    if (ris[0].meta.requestStatus !== "rejected") {
                        let idStructure = ris[0].payload.id

                        dispatch(newBudget(
                            {
                                idStructure: idStructure,
                                value: budget,
                                year: new Date().getFullYear()
                            }
                        ))

                        close()
                    } else {
                        alert("errore di rete, riprovare")
                    }

                }
                )
            }

        } else {
            alert("tutti i campi sono obbligatori")
        }
    };
    const handleModifyStabilimento = () => {



        if (validField(budget) && validField(name)) {

            let indirizzoValue = document.getElementById("posGeoNewStabilimentoInput").value
            let posIsValid = false
            let posGeoValue = null

            //SI VERIFICA SE LA POSIZIONE E' CAMBIATA
            const posIsChanged = !(indirizzoValue === objectToModify.via)

            //SI AGGIORNA SE NON E' CAMBIATA LA POSIZIONE O SE E' CAMBIATA MA LA POSIZIONE E' VALIDA
            if (posIsChanged) {
                //SE LA POSIZIONE E' CAMBIATA SI VERIFICA SE E' UNA POSIZIONE VALIDA
                posGeoValue = validPosGeo(indirizzoValue)
                if (posGeoValue !== undefined) {
                    posIsValid = true
                }
            }

            //SI AGGIORNA SE NON E' CAMBIATA LA POSIZIONE O SE E' CAMBIATA MA LA POSIZIONE E' VALIDA
            if (!posIsChanged || (posIsChanged && posIsValid)) {
                const response = dispatch(updatePlant(
                    {
                        id: objectToModify.id,
                        name: name,
                        posGeo: posIsChanged ? posGeoValue.Place.Geometry.Point.reverse() : objectToModify.posGeo,
                        via: posIsChanged ? indirizzoValue : objectToModify.via

                    }
                ))

                Promise.all([response]).then(ris => {
                    if (ris[0].meta.requestStatus !== "rejected") {
                        dispatch(updateBudget(
                            {
                                idStructure: budgetToModify.idStructure,
                                id: budgetToModify.id,
                                value: budget,
                                year: new Date().getFullYear()
                            }
                        ))

                        close()
                    } else {
                        alert("errore di rete, riprovare")
                    }
                }
                )

            }


        } else {
            alert("tutti i campi sono obbligatori")
        }

    }

    return (
        <div className="boxAllAddNewStabilimento">
            <div className="boxTitleAddNewStabilimento">
                <h1 className="elementTitleAddNewStabilimento">{mode === "add" ? `Nuovo stabilimento` : `Modifica stabilimento`}</h1>

                <h1
                    className="elementCloseSchedaAddNewStabilimento"
                    onClick={() => close()}
                >x</h1>
            </div>
            <div className="boxFormAddNewStabilimento">

                <div className="boxColumnFromAddNewStabilimento boxBlockFormAddNewStabilimento">
                    <span className="elementoLableForm">Nome stabiliento</span>
                    <input
                        className="elementInputAddNewStabilimento"
                        required
                        placeholder="Nome Stabilitimento"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <span className="elementoLableForm">Via</span>
                    <input
                        required
                        id="posGeoNewStabilimentoInput"
                        className="elementPosGeoAddNewStabilimento"
                        list="posGeoNewStabilimento"
                        placeholder="Posizione geografica"
                        value={via}
                        onChange={(e) => setVia(e.target.value)}
                        onInput={(e) => e.target.value !== "" ? funzione(e.target.value) : null}
                    />

                    <datalist
                        id="posGeoNewStabilimento"
                    >{
                            risposte.map((el, index) => {
                                return <option
                                    key={index}
                                    value={el.Place.Label}
                                >{el.Place.label}</option>
                            })

                        }
                    </datalist>
                    <span className="elementoLableForm">Budget</span>
                    <div className="boxBudgetStabilimento">
                        <input
                            className="elementInputAddNewStabilimentoBudget"
                            placeholder="Budget di manutenzione"
                            type="text"
                            //step="0.01" min="0" max="100"
                            required
                            
                            value={pointToeconomicalValue(budget)}
                            onChange={(e) => handleChangeBudget(e)}
                        />
                    </div>
                </div>
            </div>

            <div className="elementSpaceBetweenAddStabilimento"></div>
            <div className="boxColumnFromAddNewStabilimento">
                {mode === "add" ?
                    <button
                        className="elementButtonSubmitFormAddNewStabilimento"
                        onClick={handleCreateStabilimento}
                    >
                        Aggiungi Stabilimento
                    </button>
                    :
                    <button
                        className="elementButtonSubmitFormAddNewStabilimento"
                        onClick={handleModifyStabilimento}

                    >
                        Modifica Stabilimento
                    </button>
                }
            </div>
        </div>
    )
}

export default PersFormAddNewStabilimento