import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit';
import {API} from 'aws-amplify'
import {GRAPHQL_AUTH_MODE} from '@aws-amplify/api'
import {createDataTargaAssets, deleteDataTargaAssets, updateDataTargaAssets} from '../../../graphql/mutations'
import {getDataTargaAssets, listDataTargaAssets} from '../../../graphql/queries';
/**
 * mi riporto lo slice del macchinario selezionato da schede machine detail
 */
import {getMachineSelected} from './schedeMachineDetailSlice';
import * as subscriptions from "../../../graphql/subscriptions";



const datiTargaMachineryAdapter = createEntityAdapter({
    selectId: (datiTargaMachinery) => datiTargaMachinery.id
})
export const subscribeCreateDataTargaAssets = createAsyncThunk(
    'datiTargaMachinery/subscriptionCreateDataTargaAsset',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onCreateDataTargaAssets,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({ provider, value }) => {
                    dispatch(addOneDataTargaAssets({
                        id: value.data.onCreateDataTargaAssets.idAsset,
                        imageURL: value.data.onCreateDataTargaAssets.imageURL,
                        annoProd: value.data.onCreateDataTargaAssets.yearProduction,
                        annoInst: value.data.onCreateDataTargaAssets.yearInstallation,
                        produttore: value.data.onCreateDataTargaAssets.producer,
                        createdAt: value.data.onCreateDataTargaAssets.createdAt,
                        updatedAt: value.data.onCreateDataTargaAssets.updatedAt
                    }))
                },
                error: (error) => console.warn(error)
            })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
       // return subscription;
    }
);
export const subscribeUpdateDataTargaAssets = createAsyncThunk(
    'datiTargaMachinery/subscriptionDataTargaAssets',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onUpdateDataTargaAssets,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({ provider, value }) => {
                    dispatch(
                        updateOneDataTargaAssets({
                            id:value.data.onUpdateDataTargaAssets.idAsset,
                            imageURL: value.data.onUpdateDataTargaAssets.imageURL,
                            annoProd: value.data.onUpdateDataTargaAssets.yearProduction,
                            annoInst: value.data.onUpdateDataTargaAssets.yearInstallation,
                            produttore: value.data.onUpdateDataTargaAssets.producer,
                            createdAt: value.data.onUpdateDataTargaAssets.createdAt,
                            updatedAt: value.data.onUpdateDataTargaAssets.updatedAt
                        })
                    )
                },
                error: (error) => console.warn(error)
            })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        //return subscription;
    }
);
export const subscribeDeleteDataTargaAssets = createAsyncThunk(
    'datiTargaMachinery/subscriptionDataTargaAssets',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onDeleteDataTargaAssets,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({ provider, value }) => {

                    dispatch(deleteOneDataTargaAssets(value.data.onDeleteDataTargaAssets.idAsset))
                },
                error: (error) => console.warn(error)
            })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        //return subscription;
    }
);
export const newDataTargaAssets = createAsyncThunk('datiTargaMachinery/putDataTargaAsset', async ({ idAsset, imageURL, yearProduction, yearInstallation, producer }) => {
    const response = await API.graphql({
            query: createDataTargaAssets,
            variables: {
                input: {
                    idAsset, imageURL, yearProduction, yearInstallation, producer
                }
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS

        }
    )
    ////console.log("ris dati targa new", response.data.createDataTargaAssets)

    //salvataggio dei dati di targa in locale per l'utente che lo ha creato, togliamo un download

    return {
        id: response.data.createDataTargaAssets.idAsset,
        imageURL: response.data.createDataTargaAssets.imageURL,
        annoProd: response.data.createDataTargaAssets.yearProduction,
        annoInst: response.data.createDataTargaAssets.yearInstallation,
        produttore: response.data.createDataTargaAssets.producer,
        createdAt: response.data.createDataTargaAssets.createdAt,
        updatedAt: response.data.createDataTargaAssets.updatedAt
    }

})
export const updateDataTarga = createAsyncThunk('datiTargaMachinery/updateDataTargaAsset', async ({ idAsset, imageURL, yearProduction, yearInstallation, producer}) => {
        const response = await API.graphql({
            query: updateDataTargaAssets,
            variables: {
                input: {
                    idAsset,
                    imageURL,
                    yearProduction,
                    yearInstallation,
                    producer
                }
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        })
        //return response
        return{
            id: response.data.updateDataTargaAssets.idAsset,
            imageURL: response.data.updateDataTargaAssets.imageURL,
            annoProd: response.data.updateDataTargaAssets.yearProduction,
            annoInst: response.data.updateDataTargaAssets.yearInstallation,
            produttore: response.data.updateDataTargaAssets.producer,
            createdAt: response.data.updateDataTargaAssets.createdAt,
            updatedAt: response.data.updateDataTargaAssets.updatedAt
        }
    }
)
export const deleteDataTarga = createAsyncThunk("datiTargaMachinery/deleteDataTargaAsset", async ({id}) => {

    const response = await API.graphql({
        query: deleteDataTargaAssets,
        variables: {
            input: {idAsset: id}
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })
    return response.data.deleteDataTargaAssets.id
})
export const getDatiTargaAsset = createAsyncThunk("datiTargaMachinery/getDatiTargaAssetSelected", async ({ idAsset }) => {
    const response = await API.graphql({
        query: getDataTargaAssets,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        variables: {
            idAsset
        }
    })
    return {


        id: response.data.getDataTargaAssets.idAsset,
        imageURL: response.data.getDataTargaAssets.imageURL,
        annoProd: response.data.getDataTargaAssets.yearProduction,
        annoInst: response.data.getDataTargaAssets.yearInstallation,
        produttore: response.data.getDataTargaAssets.producer,
        createdAt: response.data.getDataTargaAssets.createdAt,
        updatedAt: response.data.getDataTargaAssets.updatedAt

    }
})



export const getlistDatiTargaAssets = createAsyncThunk("datiTargaMachinery/listDatiTargaAsset", async (a, {getState}) => {
    const response = await API.graphql({
        query: listDataTargaAssets,

        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })

    return response.data.listDataTargaAssets.items.map((el, index) => {

        return {
            id: el.idAsset,
            imageURL: el.imageURL,
            annoProd: el.yearProduction,
            annoInst: el.yearInstallation,
            produttore: el.producer,
            createdAt: el.createdAt,
            updatedAt: el.updatedAt
        }

    })
})


const datiTargaMachinerySlice = createSlice({
    name: "datiTargaMachinery",
    initialState: datiTargaMachineryAdapter.getInitialState(),
    reducers: {
        datiTargaMachineryAddMany: datiTargaMachineryAdapter.upsertMany,
        addOneDatiTargaMachinery: datiTargaMachineryAdapter.upsertOne,
        datiTargaUpdateMany: datiTargaMachineryAdapter.upsertMany,
        addOneDataTargaAssets:datiTargaMachineryAdapter.upsertOne,
        updateOneDataTargaAssets:datiTargaMachineryAdapter.upsertOne,
        deleteOneDataTargaAssets:datiTargaMachineryAdapter.removeOne
    },

    extraReducers: bulder =>
        bulder
            .addCase(newDataTargaAssets.fulfilled, datiTargaMachineryAdapter.upsertOne)
            .addCase(newDataTargaAssets.rejected, (state, action) => console.log("dati rigettati da newDataTargaAssets", state, action))
            .addCase(updateDataTarga.fulfilled, datiTargaMachineryAdapter.upsertOne)
            .addCase(updateDataTarga.rejected, (state, action) => console.log(state, action))
            .addCase(deleteDataTarga.fulfilled, datiTargaMachineryAdapter.removeOne)
            .addCase(deleteDataTarga.rejected, (state, action) => console.log(state, action))
            .addCase(getDatiTargaAsset.fulfilled, datiTargaMachineryAdapter.upsertOne)
            .addCase(getDatiTargaAsset.rejected, (state, action) => console.log("dati rigettati da getDatiTargaAsset", state, action))
            .addCase(getlistDatiTargaAssets.fulfilled, datiTargaMachineryAdapter.upsertMany)
            .addCase(getlistDatiTargaAssets.rejected, (state, action) => console.log(state, action))
})


export default datiTargaMachinerySlice.reducer

export const {
    datiTargaMachineryAddMany,
    addOneDatiTargaMachinery,
    datiTargaUpdateMany,
    addOneDataTargaAssets,
    updateOneDataTargaAssets,
    deleteOneDataTargaAssets
} = datiTargaMachinerySlice.actions


export const { selectAll: selectAllDatiTarga } = datiTargaMachineryAdapter.getSelectors(state => state.datiTargaMachinery)

export const getDatiTargaForMachineSelected = createSelector([selectAllDatiTarga, getMachineSelected],
    (state, select) => {
        /**
         * inserito l'if poiché tentava di richiamare i dati di targa anche quando non selezionavo un
         * macchinario
         */
        if (select) {

            return state.filter(el => el.id === select.idMachinery)[0]
        }

    })