/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlants = /* GraphQL */ `
  query GetPlants($id: String!) {
    getPlants(id: $id) {
      id
      name
      posGeo
      via
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlants = /* GraphQL */ `
  query ListPlants(
    $id: String
    $filter: ModelPlantsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPlants(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        posGeo
        via
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataPlants = /* GraphQL */ `
  query GetDataPlants($idPlant: String!, $id: String!) {
    getDataPlants(idPlant: $idPlant, id: $id) {
      idPlant
      id
      params
      linkDataGraph
      period
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDataPlants = /* GraphQL */ `
  query ListDataPlants(
    $idPlant: String
    $id: ModelStringKeyConditionInput
    $filter: ModelDataPlantsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDataPlants(
      idPlant: $idPlant
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idPlant
        id
        params
        linkDataGraph
        period
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDepartments = /* GraphQL */ `
  query GetDepartments($id: String!) {
    getDepartments(id: $id) {
      id
      name
      idPlant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $id: String
    $filter: ModelDepartmentsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDepartments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        idPlant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLineas = /* GraphQL */ `
  query GetLineas($id: String!) {
    getLineas(id: $id) {
      id
      name
      idDepartment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLineas = /* GraphQL */ `
  query ListLineas(
    $id: String
    $filter: ModelLineasFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLineas(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        idDepartment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBudgets = /* GraphQL */ `
  query GetBudgets($idStructure: String!, $id: String!) {
    getBudgets(idStructure: $idStructure, id: $id) {
      idStructure
      id
      year
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBudgets = /* GraphQL */ `
  query ListBudgets(
    $idStructure: String
    $id: ModelStringKeyConditionInput
    $filter: ModelBudgetsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBudgets(
      idStructure: $idStructure
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idStructure
        id
        year
        value
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssets = /* GraphQL */ `
  query GetAssets($id: String!) {
    getAssets(id: $id) {
      id
      idCode
      idFamily
      idPlant
      idLinea
      idDepartment
      name
      reparto
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssets = /* GraphQL */ `
  query ListAssets(
    $id: String
    $filter: ModelAssetsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAssets(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        idCode
        idFamily
        idPlant
        idLinea
        idDepartment
        name
        reparto
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataTargaAssets = /* GraphQL */ `
  query GetDataTargaAssets($idAsset: String!) {
    getDataTargaAssets(idAsset: $idAsset) {
      idAsset
      imageURL
      yearProduction
      yearInstallation
      producer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDataTargaAssets = /* GraphQL */ `
  query ListDataTargaAssets(
    $idAsset: String
    $filter: ModelDataTargaAssetsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDataTargaAssets(
      idAsset: $idAsset
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idAsset
        imageURL
        yearProduction
        yearInstallation
        producer
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocAssets = /* GraphQL */ `
  query GetDocAssets($idAsset: String!, $id: String!) {
    getDocAssets(idAsset: $idAsset, id: $id) {
      idAsset
      id
      name
      desc
      link
      note
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDocAssets = /* GraphQL */ `
  query ListDocAssets(
    $idAsset: String
    $id: ModelStringKeyConditionInput
    $filter: ModelDocAssetsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocAssets(
      idAsset: $idAsset
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idAsset
        id
        name
        desc
        link
        note
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssetsHI = /* GraphQL */ `
  query GetAssetsHI($idAsset: String!) {
    getAssetsHI(idAsset: $idAsset) {
      idAsset
      hi
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssetsHIS = /* GraphQL */ `
  query ListAssetsHIS(
    $idAsset: String
    $filter: ModelAssetsHIFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAssetsHIS(
      idAsset: $idAsset
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idAsset
        hi
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFamily = /* GraphQL */ `
  query GetFamily($id: String!) {
    getFamily(id: $id) {
      id
      familyName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFamilies = /* GraphQL */ `
  query ListFamilies(
    $id: String
    $filter: ModelFamilyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFamilies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        familyName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFaults = /* GraphQL */ `
  query GetFaults($idFamily: String!, $id: String!) {
    getFaults(idFamily: $idFamily, id: $id) {
      idFamily
      id
      name
      desc
      component
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFaults = /* GraphQL */ `
  query ListFaults(
    $idFamily: String
    $id: ModelStringKeyConditionInput
    $filter: ModelFaultsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFaults(
      idFamily: $idFamily
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idFamily
        id
        name
        desc
        component
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataFaults = /* GraphQL */ `
  query GetDataFaults($idAsset: String!, $id: String!) {
    getDataFaults(idAsset: $idAsset, id: $id) {
      idAsset
      id
      idFault
      hi
      RUL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDataFaults = /* GraphQL */ `
  query ListDataFaults(
    $idAsset: String
    $id: ModelStringKeyConditionInput
    $filter: ModelDataFaultsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDataFaults(
      idAsset: $idAsset
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idAsset
        id
        idFault
        hi
        RUL
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlgoritmsAlerts = /* GraphQL */ `
  query GetAlgoritmsAlerts($id: String!) {
    getAlgoritmsAlerts(id: $id) {
      id
      idPlant
      idDataFault
      idAsset
      risk
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlgoritmsAlerts = /* GraphQL */ `
  query ListAlgoritmsAlerts(
    $id: String
    $filter: ModelAlgoritmsAlertsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAlgoritmsAlerts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        idPlant
        idDataFault
        idAsset
        risk
        title
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTickets = /* GraphQL */ `
  query GetTickets($idAsset: String!, $id: String!) {
    getTickets(idAsset: $idAsset, id: $id) {
      idAsset
      id
      operatore
      fault
      title
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $idAsset: String
    $id: ModelStringKeyConditionInput
    $filter: ModelTicketsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTickets(
      idAsset: $idAsset
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idAsset
        id
        operatore
        fault
        title
        note
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInterventions = /* GraphQL */ `
  query GetInterventions($idAsset: String!, $id: String!) {
    getInterventions(idAsset: $idAsset, id: $id) {
      idAsset
      idPrevIntervention
      isDeleted
      id
      data
      ora
      idFault
      period
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInterventions = /* GraphQL */ `
  query ListInterventions(
    $idAsset: String
    $id: ModelStringKeyConditionInput
    $filter: ModelInterventionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInterventions(
      idAsset: $idAsset
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idAsset
        idPrevIntervention
        isDeleted
        id
        data
        ora
        idFault
        period
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataInterventions = /* GraphQL */ `
  query GetDataInterventions($idAsset: String!, $id: String!) {
    getDataInterventions(idAsset: $idAsset, id: $id) {
      idAsset
      id
      idPlant
      durata
      reparto
      activity
      operatore
      creatore
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDataInterventions = /* GraphQL */ `
  query ListDataInterventions(
    $idAsset: String
    $id: ModelStringKeyConditionInput
    $filter: ModelDataInterventionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDataInterventions(
      idAsset: $idAsset
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idAsset
        id
        idPlant
        durata
        reparto
        activity
        operatore
        creatore
        note
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMainteneanceReports = /* GraphQL */ `
  query GetMainteneanceReports($idAsset: String!, $id: String!) {
    getMainteneanceReports(idAsset: $idAsset, id: $id) {
      idAsset
      id
      idIntervention
      data
      operatore
      isDeleted
      valoriLettura
      activity
      costo
      operation
      durata
      note
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMainteneanceReports = /* GraphQL */ `
  query ListMainteneanceReports(
    $idAsset: String
    $id: ModelStringKeyConditionInput
    $filter: ModelMainteneanceReportsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMainteneanceReports(
      idAsset: $idAsset
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idAsset
        id
        idIntervention
        data
        operatore
        isDeleted
        valoriLettura
        activity
        costo
        operation
        durata
        note
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
